import "./site-information.scss";
import React, { useEffect, useState } from "react";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid.jsx";
import { siteService } from "../../../../../services/sites.js";
import { useDispatch, useSelector, useStore } from "react-redux";
import {
  clearSiteData,
  persistSiteData,
  triggerNotification,
  updateDetails,
} from "../../../../../state-management/actions.js";
import { Loader } from "@progress/kendo-react-indicators";
import { Button } from "@progress/kendo-react-all";
import {
  getFormattedDate,
  getFormattedDateAndTime,
  serverError,
} from "../../../../../services/utils.js";
import { RK_SITE_ROLE_ID } from "../../../../constants.js";
import { colsToShow, columnsToExport } from "./site-information-utils.js";
import { SiteBasicDetails } from "./site-details-screen.jsx";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel.jsx";
import { reportsService } from "../../../../../services/reports.js";
import ConfirmPopup from "../../../../components/confirmation-popup/confirmation-popup.js";
import { SiteTankPopupDetails } from "./site-tank-popup-details-screen.jsx";
import { SiteInfoScreen } from "../out-of-tolerance-trend-analysis/out-of-tolerance-site-info.jsx";
import { DEFAULT_INITIAL_PAGE_SIZE, NUMBERS } from "../common-utils.js";
import SiteTankDetailsController from "./site-tank-details-controller.jsx";

const CONFIRMATION_OPTION = {
  TANK_DELETE: 0,
  PUMP_DELETE: 1,
  SAVE_PUMP_DATA: 2,
};

const BoxItem = ({ item }) => {
  return (
    <div className="boxContainer">
      <span className="highlighter"></span>
      <span className="box-title">{item?.title}</span>
      <span className="box-count">{item?.count}</span>
    </div>
  );
};

const SiteInformationScreen = () => {
  const store = useStore();
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user);
  const [showSiteDetails, setShowSiteDetails] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [siteStatuses, setSiteStatuses] = useState([]);
  const [regionList, setRegionList] = useState([]);
  useEffect(() => {
    const writeEnabled = user?.RoleId?.toString() === "3";
    setReadOnly(!writeEnabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const defaultCountry = useSelector((state) => state?.selectedCountry);
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);
  const [siteData, setSiteData] = useState([]);
  const [currentSiteItem, setCurrentSiteItem] = useState();
  const [currentTankItem, setCurrentTankItem] = useState();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [selectedTankItem, setSelectedTankItem] = useState();
  const [selectedTankPumpItem, setSelectedTankPumpItem] = useState();
  const [currentOffSet, setCurrentOffSet] = useState();
  const [showTankDetails, setShowTankDetails] = useState(false);
  const [addTankDetails, setAddTankDetails] = useState(false);
  const [currentOperation, setCurrentOperation] = useState();
  const [siteStatus, setSiteStatus] = useState({
    total: 0,
    active: 0,
    closed: 0,
    others: 0,
  });
  const [showSiteInfoModal, setShowSiteInfoModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState();

  store.subscribe(() => {
    const { selectedCountry } = store.getState();
    setCurrentCountryCode(selectedCountry);
  });

  useEffect(() => {
    setRegionList([]);
    dispatch(clearSiteData());
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryCode]);

  const fetchData = async () => {
    setLoading(true);
    await fetchSiteData();
    setLoading(false);
  };

  const fetchOtherData = async () => {
    setLoading(true);
    if (regionList?.length === 0) {
      await fetchRegionData();
    }

    if (siteStatuses?.length === 0) {
      await fetchSiteStatuses();
    }
    setLoading(false);
  };

  const cancelDetailsModal = () => {
    setShowSiteDetails(false);
  };

  const saveDetails = async (siteInfo) => {
    setCurrentSiteItem(siteInfo);
    setShowSiteDetails(false);
    let data = await fetchSiteData();
    data = data?.map((item) => {
      let updatedItem = item;
      if (item?.expanded) {
        updatedItem = {
          ...item,
          ...siteInfo,
          Country: siteInfo?.CountryCode,
          Plant: siteInfo?.PLANT,
          Sloc: siteInfo?.SLOC,
          expanded: true,
        };
      }
      return updatedItem;
    });

    setSiteData(data);
  };

  const cancelTankDetailsModal = () => {
    setShowTankDetails(false);
    setAddTankDetails(false);
  };

  const saveTankDetails = (siteInfo) => {
    setCurrentSiteItem(siteInfo);
    setShowTankDetails(false);
    setAddTankDetails(false);
    const updatedData = siteData?.map((item) =>
      item.GlobalSiteId === siteInfo.GlobalSiteId
        ? { ...item, ...siteInfo }
        : item
    );
    setSiteData(updatedData);
  };

  const updateItemData = async (item) => {
    const dataItem = item;
    setCurrentSiteItem(dataItem);
    if (
      !dataItem?.OperatingHours &&
      !dataItem?.TankInfo &&
      !dataItem?.NozzleInfo
    ) {
      setFetchingDetails(true);
      const response = await siteService.fetchSiteDetailsInfo(
        dataItem?.PartitionKey || dataItem?.Country,
        dataItem?.RowKey || dataItem?.GlobalSiteId
      );
      if (response) {
        const openingDate = getFormattedDate(
          new Date(response?.SiteOpeningDate)
        );
        const closingDate = getFormattedDate(
          new Date(response?.SiteClosingDate)
        );
        dataItem.City = response?.City ? response?.City : "";
        dataItem.OpeningDate = openingDate ? openingDate : "";
        dataItem.ClosingDate = closingDate ? closingDate : "";
        dataItem.NozzleInfo = response?.NozzleInfo;
        dataItem.OperatingHours = response?.OperatingHours;
        dataItem.TankInfo = response?.TankInfo?.map((tankInfo) => ({
          ...tankInfo,
          expanded: false,
        }));
        dataItem.Remarks = response?.Remarks ? response?.Remarks : "";
        dispatch(updateDetails(dataItem));

        const data = store.getState().siteData;
        const newData = data.map((itemInfo) => {
          if (itemInfo.id === dataItem.id) {
            if (dataItem?.expand) {
              dataItem.expanded = true;
            }
            return dataItem;
          }

          return itemInfo;
        });
        setSiteData(newData);
      }
      setFetchingDetails(false);
    }
    return dataItem;
  };

  const handleDetailsEdit = async (props) => {
    if (regionList?.length === 0 || siteStatuses?.length === 0) {
      await fetchOtherData();
    }
    let item = props;
    if (!props?.OperatingHours && !props?.TankInfo && !props?.NozzleInfo) {
      item = await updateItemData({ ...props });
    }
    setCurrentSiteItem(item);
    setShowSiteDetails(true);
  };

  const handleTankGroupEdit = (item) => {
    setCurrentTankItem(item);
    const updatedSiteData = siteData?.map((data) => {
      if (data?.GlobalSiteId === item?.GlobalSiteId) {
        data.expanded = true;
      }
      return data;
    });
    setSiteData(updatedSiteData);
    setShowTankDetails(true);
  };

  const tankPumpDeleteAction = async () => {
    setShowConfirmationPopup(false);
    const siteItem = siteData?.find(
      (data) => data?.GlobalSiteId === selectedTankItem?.GlobalSiteId
    );

    const tankDetails = siteItem?.TankInfo?.map((tank) => {
      const tankDetailsInfo = tank;
      if (
        tankDetailsInfo?.deviceID === selectedTankItem?.deviceID &&
        tankDetailsInfo?.tankName === selectedTankItem?.tankName
      ) {
        const { [selectedTankPumpItem?.pump]: _, ...pumpNozzle } =
          tankDetailsInfo.PumpNozzle;
        tankDetailsInfo.PumpNozzle = pumpNozzle;
      }
      return tankDetailsInfo;
    });
    siteItem.TankInfo = tankDetails;
    setSubmitting(true);
    const response = await siteService.updateSiteInfo(
      siteItem?.Country,
      siteItem?.GlobalSiteId,
      siteItem
    );
    if (response?.status >= NUMBERS.TWO_HUNDRED && response?.status < NUMBERS.THREE_HUNDRED) {
      dispatch(
        triggerNotification({
          type: {
            style: "success",
            icon: true,
          },
          message: response?.data?.message || response?.data,
        })
      );
    } else {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Failed to save site information!",
        })
      );
    }
    setSubmitting(false);
  };

  const tankDeleteAction = async (props) => {
    setShowConfirmationPopup(false);
    const siteItem = siteData?.find(
      (data) => data?.GlobalSiteId === props?.GlobalSiteId
    );

    const tankDetails = siteItem?.TankInfo?.filter((tank) => {
      const same1 =
        tank?.deviceID === props?.deviceID &&
        tank?.tankName === props?.tankName &&
        tank?.additionalProductInfo === props?.additionalProductInfo;

      const same2 =
        tank?.capacity === props?.capacity &&
        tank?.materialNo === props?.materialNo &&
        tank?.productCode === props?.productCode;

      const same3 =
        tank?.tankGroup === props?.tankGroup &&
        tank?.workCapacity === props?.workCapacity;

      const sameTank = same1 && same2 && same3;
      return !sameTank;
    });
    siteItem.TankInfo = tankDetails;
    setSubmitting(true);
    const response = await siteService.updateSiteInfo(
      siteItem?.Country,
      siteItem?.GlobalSiteId,
      siteItem
    );
    if (response?.status >= NUMBERS.TWO_HUNDRED && response?.status < NUMBERS.THREE_HUNDRED) {
      dispatch(
        triggerNotification({
          type: {
            style: "success",
            icon: true,
          },
          message: response?.data?.message || response?.data,
        })
      );
    } else {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Unable to delete.",
        })
      );
    }
    setSubmitting(false);
  };
  const handleTankGroupDelete = async (item, offset) => {
    const updatedSiteData = siteData?.map((data) => {
      if (data?.GlobalSiteId === item?.GlobalSiteId) {
        data.expanded = true;
      }
      return data;
    });
    setSiteData(updatedSiteData);
    setCurrentOffSet(offset);
    setConfirmationTitle("Please Confirm");
    setConfirmationMessage("Are you sure you want to delete this Tank Group?");
    setCurrentOperation(CONFIRMATION_OPTION.TANK_DELETE);
    setSelectedTankItem(item);
    setShowConfirmationPopup(true);
  };

  const handleTankPumpDelete = async (tankItem, item, offset) => {
    setCurrentOffSet(offset);
    setConfirmationTitle("Please Confirm");
    setConfirmationMessage("Are you sure you want to delete this Tank Pump?");
    setCurrentOperation(CONFIRMATION_OPTION.PUMP_DELETE);
    setSelectedTankItem(tankItem);
    setSelectedTankPumpItem(item);
    setShowConfirmationPopup(true);
    const updatedSiteData = siteData?.map((data) => {
      if (data?.GlobalSiteId === tankItem?.GlobalSiteId) {
        data.expanded = true;
        const tankDetails = data?.TankInfo?.map((tank) => {
          if (
            tank?.deviceID === tankItem?.deviceID &&
            tank?.tankName === tankItem?.tankName
          ) {
            tank.expanded = true;
          }
          return tank;
        });
        data.TankInfo = tankDetails;
      }
      return data;
    });
    setSiteData(updatedSiteData);
  };

  const handleTankGroupAdd = (props) => {
    setCurrentSiteItem(props?.siteItem);
    setCurrentTankItem(null);
    setShowTankDetails(true);
    setAddTankDetails(true);
  };

  const fetchSiteStatuses = async () => {
    const response = await siteService.fetchSiteStatuses();
    if (response) {
      setSiteStatuses(response);
    } else {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Unable to fetch the Site Statuses data.",
        })
      );
    }
  };

  const fetchRegionData = async () => {
    try {
      const response = await reportsService.fetchRegionsData({
        country: currentCountryCode,
      });
      if (response) {
        setRegionList(response);
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: "Unable to fetch the Region data.",
          })
        );
      }
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: serverError,
        })
      );
    }
  };

  const fetchSiteData = async (shouldExpand = true) => {
    try {
      setLoading(true);
      const response = await siteService.fetchSiteList(currentCountryCode);
      if (response) {
        const siteStatusObj = {
          total: 0,
          active: 0,
          closed: 0,
          others: 0,
        };
        const data = response?.reduce((info, item) => {
          const expanded = currentSiteItem?.RowKey === item?.RowKey;
          const status = item?.SiteStatus;
          siteStatusObj.total += 1;
          if (status?.toLowerCase() === "active") {
            siteStatusObj.active += 1;
          } else if (status?.toLowerCase()?.includes("closed")) {
            siteStatusObj.closed += 1;
          } else {
            siteStatusObj.others += 1;
          }
          return [
            ...info,
            {
              ...item,
              Country: item?.PartitionKey ? item?.PartitionKey : "",
              PartitionKey: item?.PartitionKey ? item?.PartitionKey : "",
              RowKey: item?.RowKey ? item?.RowKey : "",
              Region: item?.Region ? item?.Region : "",
              City: item?.City ? item?.City : "",
              IsRKSite: item?.IsRKSite ? item?.IsRKSite : "",
              Plant: item?.PLANT ? item?.PLANT : "",
              Sloc: item?.SLOC ? item?.SLOC : "",
              GlobalSiteId: item?.RowKey ? item?.RowKey : "",
              OBN: item?.OBN ? item?.OBN : "",
              SiteId: item?.SiteId ? item?.SiteId : "",
              Name: item?.Name ? item?.Name : "",
              Remarks: item?.Remarks ? item?.Remarks : "",
              SiteStatus: item?.SiteStatus ? item?.SiteStatus : "",
              TankInfo: item?.TankInfo,
              NozzleInfo: item?.NozzleInfo,
              OperatingHours: item?.OperatingHours,
              IsWSMASupportedSite:
                item?.IsWSMASupportedSite === false ? "N" : "Y",
              Timestamp: item?.ModifiedOn
                ? getFormattedDateAndTime(new Date(item?.ModifiedOn))
                : "",
              expanded: shouldExpand === false ? false : expanded,
            },
          ];
        }, []);

        data?.sort((a, b) => (a.Name > b.Name ? 1 : -1));
        setSiteStatus(siteStatusObj);
        setSiteData(data);
        dispatch(persistSiteData(data));
        setLoading(false);
        return data;
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: "Unable to fetch the site information.",
          })
        );
      }
      return [];
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: serverError,
        })
      );
      setError(serverError);
      setLoading(false);
      setError("");
    }
    return [];
  };

  const exportData = async () => {
    setExporting(true);
    const rkSiteRole = user?.RoleId === RK_SITE_ROLE_ID;
    await siteService.downloadSiteExcel(currentCountryCode, rkSiteRole);
    setExporting(false);
  };

  const size = submitting ? "large" : "small";
  const showHeaderBox = () => {
    return (
      <div className="header-box-container">
        <BoxItem
          item={{
            title: "Total Sites",
            count: siteStatus?.total || "0",
          }}
        />
        <BoxItem
          item={{
            title: "Total Active Sites",
            count: siteStatus?.active || "0",
          }}
        />
        <BoxItem
          item={{
            title: "Total Closed Sites",
            count: siteStatus?.closed || "0",
          }}
        />
      </div>
    );
  };

  const exportHeader = () => {
    return (
      <span className="exportContainer">
        <Button
          className="exportButton"
          onClick={exportData}
          disabled={exporting}
        >
          {exporting ? "Exporting..." : "Export"}
        </Button>
        <Button
          className="refreshButton"
          onClick={() => {
            fetchSiteData(false);
          }}
          disabled={loading}
        >
          Refresh
        </Button>
      </span>
    );
  };

  const handleShipToLinkAction = (siteId, country) => {
    setSelectedSite({ siteId: siteId, country: country });
    setShowSiteInfoModal(true);
  };

  const closeModal = () => {
    setShowSiteInfoModal(false);
  };

  return (
    <div className="container data-grid site-info-container">
      <div className="row ">
        {error && <span>{error}</span>}
        <div className="data-grid-container">
          <div className="header-container">
            {showHeaderBox()}
            {exportHeader()}
          </div>
          {showSiteInfoModal && (
            <SiteInfoScreen
              globalSiteId={selectedSite?.siteId}
              countryCode={selectedSite?.country || currentCountryCode}
              close={closeModal}
            />
          )}

          <div className="siteList-grid-container">
            <AppDataGrid
              style={{ height: "66vh" }}
              pageable={true}
              take={DEFAULT_INITIAL_PAGE_SIZE}
              filterable={true}
              fixedScroll={true}
              expandable={true}
              sortable={true}
              data={siteData}
              detailsContainer={(propValues) => (
                <SiteTankDetailsController
                  {...propValues}
                  currentSiteItem={currentSiteItem}
                  selectedTankItem={selectedTankItem}
                  selectedTankPumpItem={selectedTankPumpItem}
                  siteData={siteData}
                  setSubmitting={setSubmitting}
                  setSelectedTankPumpItem={setSelectedTankPumpItem}
                  setSiteData={setSiteData}
                  handleTankPumpDelete={handleTankPumpDelete}
                  readOnly={readOnly}
                  handleTankGroupEdit={handleTankGroupEdit}
                  handleTankGroupDelete={handleTankGroupDelete}
                  handleTankGroupAdd={handleTankGroupAdd}
                  currentTankItem={currentTankItem}
                />
              )}
              columnsToShow={colsToShow({
                onEdit: handleDetailsEdit,
                handleShipToLinkAction: handleShipToLinkAction,
              })}
              columnsToExport={columnsToExport}
              updateItemData={updateItemData}
              expandChange={(data) => {
                setSiteData(data);
              }}
            />
          </div>
          {showSiteDetails && (
            <SiteBasicDetails
              selectedItem={currentSiteItem}
              cancel={cancelDetailsModal}
              save={saveDetails}
              readOnly={readOnly}
              siteStatuses={siteStatuses}
              regionList={regionList}
            />
          )}
          {!showSiteDetails && showTankDetails && (
            <SiteTankPopupDetails
              currentSiteItem={currentSiteItem}
              selectedItem={addTankDetails ? null : currentTankItem}
              cancel={cancelTankDetailsModal}
              save={saveTankDetails}
              isAddTank={addTankDetails}
            />
          )}
          {loading && (
            <LoadingPanel message="Fetching the data.. please wait.." />
          )}
          {fetchingDetails && (
            <LoadingPanel message="Fetching the data.. please wait.." />
          )}
          {(submitting || exporting) && (
            <span className="remarks-loader-container">
              <Loader
                size={size}
                themeColor="warning"
                type="converging-spinner"
              />
              {exporting && (
                <p className="loader-description">Exporting data...</p>
              )}
            </span>
          )}
          {showConfirmationPopup && (
            <ConfirmPopup
              offset={currentOffSet}
              position={
                currentOperation === CONFIRMATION_OPTION.SAVE_PUMP_DATA
                  ? "top"
                  : "left"
              }
              isOpen={showConfirmationPopup}
              title={confirmationTitle}
              message={confirmationMessage}
              saveAction={() => {
                if (currentOperation === CONFIRMATION_OPTION.TANK_DELETE) {
                  tankDeleteAction(selectedTankItem);
                } else if (
                  currentOperation === CONFIRMATION_OPTION.PUMP_DELETE
                ) {
                  tankPumpDeleteAction();
                }
              }}
              cancelAction={() => {
                setShowConfirmationPopup(false);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { SiteInformationScreen };

