import "./duplicate-deliveries.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { triggerNotification } from "../../../../../state-management/actions";
import { Loader } from "@progress/kendo-react-indicators";
import { reportsService } from "../../../../../services/reports";
import { Checkbox } from "@progress/kendo-react-inputs";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { Button } from "@progress/kendo-react-all";
import { store } from "../../../../../state-management/store";
import { DECIMAL, DEFAULT_PAGE_SIZE, NUMBERS, ShipToLinkCell } from "../common-utils";
import { SiteInfoScreen } from "../out-of-tolerance-trend-analysis/out-of-tolerance-site-info";
import { unableToFetch } from "../../../../../services/utils";

const DuplicateDeliveriesScreen = () => {
  const dispatch = useDispatch();
  const [showSiteInfoModal, setShowSiteInfoModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState();
  const [duplicateDeliveries, setDuplicateDeliveries] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const defaultCountry = useSelector((state) => state?.selectedCountry);
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);

  store.subscribe(() => {
    const { selectedCountry } = store.getState();
    setCurrentCountryCode(selectedCountry);
  });

  useEffect(() => {
    fetchDuplicateDeliveries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryCode]);

  const tankIdSort = (a, b) => {
    if (a?.TankId < b?.TankId) {
      return -1;
    }
    if (a?.TankId > b?.TankId) {
      return 1;
    }
    return 0;
  };

  const deliveryDateSort = (a, b) => {
    const date1 = a?.DeliveryDate;
    const date2 = b?.DeliveryDate;
    if (date1 < date2) {
      return 1;
    }
    if (date1 > date2) {
      return -1;
    }
    return 0;
  };

  const fetchDuplicateDeliveries = async () => {
    setDuplicateDeliveries([]);
    try {
      setLoading(true);
      const response = await reportsService.fetchDuplicateDeliveries(currentCountryCode);
      setLoading(false);
      if (response?.status >= NUMBERS.TWO_HUNDRED && response?.status < NUMBERS.THREE_HUNDRED) {
        const sortedDeliveries = response?.data?.map((item) => {
          item.Deliveries.sort(tankIdSort);
          return item;
        });
        sortedDeliveries?.sort(deliveryDateSort);
        setDuplicateDeliveries(sortedDeliveries);
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: unableToFetch,
          })
        );
      }
    } catch (error1) {
      setLoading(false);
      setError("");
    }
  };

  const submitRequest = async (deliveries) => {
    setSubmitLoading(true);
    try {
      const response = await reportsService.submitDuplicateDeliveries(
        deliveries
      );
      setSubmitLoading(false);
      if (response?.status >= NUMBERS.TWO_HUNDRED && response?.status < NUMBERS.THREE_HUNDRED) {
        dispatch(
          triggerNotification({
            type: {
              style: "success",
              icon: true,
            },
            message: "Removed successfully",
          })
        );
        fetchDuplicateDeliveries();
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: "Unable to submit the request.",
          })
        );
      }
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Server error. Unable to submit the request",
        })
      );
      setSubmitLoading(false);
      setError("Server error. submit the request.");
      setError("");
    }
  };

  const ignoreRequest = async (deliveries) => {
    setSubmitLoading(true);
    try {
      const response = await reportsService.ignoreDuplicateDeliveries(
        deliveries
      );
      setSubmitLoading(false);
      if (response?.status >= NUMBERS.TWO_HUNDRED && response?.status < NUMBERS.THREE_HUNDRED) {
        dispatch(
          triggerNotification({
            type: {
              style: "success",
              icon: true,
            },
            message: "Processed successfully",
          })
        );
        fetchDuplicateDeliveries();
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: "Unable to submit the request.",
          })
        );
      }
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Server error. Unable to submit the request",
        })
      );
      setSubmitLoading(false);
      setError("Server error. submit the request.");
      setError("");
    }
  };

  const handleDeliverySelection = (selected, delivery, dataItem) => {
    const updatedData = duplicateDeliveries?.map((item) => {
      if (
        item?.ShipTo === dataItem?.ShipTo &&
        item?.ShipmentNumber === dataItem?.ShipmentNumber
      ) {
        const updatedDeliveries = item?.Deliveries?.map((deliveryItem) => {
          if (
            deliveryItem?.TankId === delivery?.TankId &&
            deliveryItem?.Volume === delivery?.Volume
          ) {
            deliveryItem.selected = selected;
          }
          return deliveryItem;
        });
        item.Deliveries = updatedDeliveries;
      }
      return item;
    });
    setDuplicateDeliveries(updatedData);
  };

  const deliveryCell = (props) => {
    const dataItem = props.dataItem;

    return (
      <td>
        {dataItem?.Deliveries?.map((delivery) => {
          const label = `${delivery?.TankId}(${delivery?.Material}) : ${delivery?.Volume} L`;
          return (
            <div className="delivery-cell-container" key={label}>
              <Checkbox
                label={label}
                checked={delivery?.selected}
                onChange={(event) => {
                  handleDeliverySelection(
                    event?.target?.value,
                    delivery,
                    dataItem
                  );
                }}
              />
            </div>
          );
        })}
      </td>
    );
  };

  const removeAction = (dataItem) => {
    const selectedItem = duplicateDeliveries?.find(
      (item) =>
        item?.ShipTo === dataItem?.ShipTo &&
        item?.ShipmentNumber === dataItem?.ShipmentNumber
    );
    const deliveries = selectedItem?.Deliveries?.filter(
      (delivery) => delivery?.selected
    );
    if (selectedItem && deliveries) {
      submitRequest([
        {
          ...selectedItem,
          Deliveries: deliveries,
        },
      ]);
    }
  };

  const ignoreAction = (dataItem) => {
    const selectedItem = duplicateDeliveries?.find(
      (item) =>
        item?.ShipTo === dataItem?.ShipTo &&
        item?.ShipmentNumber === dataItem?.ShipmentNumber
    );
    const deliveries = selectedItem?.Deliveries?.filter(
      (delivery) => delivery?.selected
    );
    if (selectedItem && deliveries) {
      ignoreRequest([
        {
          ...selectedItem,
          Deliveries: deliveries,
        },
      ]);
    }
  };

  const removeCell = (props) => {
    const dataItem = props.dataItem;
    const enabled = dataItem?.Deliveries?.find(
      (delivery) => delivery?.selected
    );
    return (
      <td>
        <Button
          className="update-btn"
          onClick={() => removeAction(dataItem)}
          disabled={!enabled}
        >
          Remove
        </Button>
        <Button className="ignore-btn" onClick={() => ignoreAction(dataItem)}>
          No Action Needed
        </Button>
      </td>
    );
  };

  const handleShipToLinkAction = (siteId, country) => {
    setSelectedSite({ siteId: siteId, country: country });
    setShowSiteInfoModal(true);
  };

  const closeModal = () => {
    setShowSiteInfoModal(false);
  };
  

  const getDeliveryColumns = (linkAction) => {
    return [
      {
        title: "Delivery Date",
        field: "DeliveryDate",
        filterable: true,
        width: window.innerWidth * DECIMAL.POINT_ONE,
      },
      {
        title: "Ship To",
        field: "ShipTo",
        filterable: false,
        cell: (props) => {
          return (
            <ShipToLinkCell {...props} linkAction={linkAction} />
          );
        },
        width: window.innerWidth * DECIMAL.POINT_ONE,
      },
      {
        title: "Outbound Delivery No",
        field: "ShipmentNumber",
        filterable: true,
        width: window.innerWidth * DECIMAL.POINT_ONE,
      },
      {
        title: "Duplicate Deliveries",
        filterable: false,
        cell: deliveryCell,
        width: window.innerWidth * DECIMAL.POINT_TWO,
      },
      {
        filterable: false,
        cell: removeCell,
      },
    ];
  };

  const showDuplicateDeliveries = () => {
    if (duplicateDeliveries && duplicateDeliveries.length > 0) {
      return (
        <div className="duplicate-deliveries-grid-container">
          <AppDataGrid
            style={{ height: "75.5vh" }}
            pageable={true}
            take={DEFAULT_PAGE_SIZE}
            filterable={true}
            expandable={false}
            sortable={true}
            data={duplicateDeliveries}
            columnsToShow={getDeliveryColumns(handleShipToLinkAction)}
          />
        </div>
      );
    }
    return (
      <div className="no-report-container">
        <h3>No duplicate deliveries found</h3>
      </div>
    );
  };

  return (
    <div className="container data-grid site-info-container">
      <div className="row ">
        {/* <h4 className="title">Duplicate Deliveries Removal</h4> */}
        {error && <span>{error}</span>}
        {loading ? (
          <>
            <div className="centralized-loader-container">
              <Loader
                size="medium"
                themeColor="warning"
                type="converging-spinner"
              />
              <br />
              <h5 className="loader-text">
                Fetching the deliveries please wait...
              </h5>
            </div>
          </>
        ) : (
          <div className="data-grid-container">
            {showSiteInfoModal && (
              <SiteInfoScreen
                globalSiteId={selectedSite?.siteId}
                countryCode={selectedSite?.country || currentCountryCode}
                close={closeModal}
              />
            )}
            {showDuplicateDeliveries()}
            {submitLoading && (
              <Loader
                style={{ position: "absolute", left: "50%", top: "30%" }}
                size="large"
                themeColor="warning"
                type="converging-spinner"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { DuplicateDeliveriesScreen };
