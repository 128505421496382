import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Tooltip } from "@progress/kendo-react-tooltip";
import {
  CustomHeaderCell,
  defaultOption,
} from "../account-for-deliveries/account-for-deliveries-utils";
import { getFormattedDateAndTime } from "../../../../../services/utils";
import {
  DECIMAL,
  gain_loss_mismatch_message,
  isBFXCluster,
  isDachCluster,
  ShipToLinkCell,
} from "../common-utils";

const DropDownCell = (props) => {
  const {
    dataItem,
    field,
    onChange,
    commentsOptions,
    selectedMode,
    disabled = false,
  } = props;

  const handleDropDownChange = (event) => {
    onChange({
      dataItem: { ...dataItem, [field]: event.target.value },
      field: field,
      syntheticEvent: event,
    });
  };
  const value = dataItem?.[field];
  const commentBy = dataItem?.commentBy;
  const commentOn = getFormattedDateAndTime(new Date(dataItem?.commentOn));
  const title = `Commented by "${commentBy}" \n on ${commentOn}`;
  const userIcon =
    commentBy?.toLowerCase() === "wsma system"
      ? require("../../../../../assets/user-yellow.png")
      : require("../../../../../assets/user-green.png");

  const userIconClassName =
    commentBy?.toLowerCase() === "wsma system" ? "userWarningIcon" : "userIcon";
  return (
    <td style={{ paddingTop: "0.8vh" }}>
      {selectedMode === TrendViewMode.MATERIAL ? (
        <span className="commentsIconContainer">
          <DropDownList
            data={commentsOptions}
            value={value || defaultOption}
            onChange={handleDropDownChange}
            disabled={disabled}
          />
          {value?.length > 0 && value !== defaultOption ? (
            <Tooltip openDelay={100} position="left" anchorElement="target">
              <span className="userIconContainer">
                <img
                  className={userIconClassName}
                  src={userIcon}
                  alt="User"
                  title={title}
                />
              </span>
            </Tooltip>
          ) : (
            <span className="emptyItem"></span>
          )}
        </span>
      ) : (
        <div className="commentsContainer">{dataItem?.[field]}</div>
      )}
    </td>
  );
};

const DateHeaderCell = (props) => {
  const columnTitle = props?.columnMenuWrapperProps?.column?.title;
  const field = props?.field;
  const blankCount = props?.blankCount?.[field];
  const blankValue = props?.index === 0 && blankCount;
  const className = props?.index === 0 && blankCount > 0 && "blankValue";

  return (
    <th {...props}>
      <div className="dateHeader">
        {columnTitle}
        {props.children}
        <Tooltip openDelay={100} position="left" anchorElement="target">
          <span title={"Missing Data"} className={className}>
            {blankValue}
          </span>
        </Tooltip>
      </div>
    </th>
  );
};

const CommentHeaderCell = (props) => {
  const columnTitle = props?.columnMenuWrapperProps?.column?.title;
  const missingOutOfToleranceCount = props?.missingOutOfTolerance?.["day0"];
  const missingCommentsCount = props?.missingCommentsCount;
  const className = missingOutOfToleranceCount > 0 && "missingValue";
  const missingCommentClass = missingCommentsCount > 0 && "missingValue";
  return (
    <th {...props}>
      <div className="dateHeader">
        {columnTitle}
        {props.children}
        <span className="comments-icon-container">
          {missingOutOfToleranceCount > 0 && (
            <Tooltip openDelay={100} position="left" anchorElement="target">
              <span title={gain_loss_mismatch_message} className={className}>
                {missingOutOfToleranceCount}
              </span>
            </Tooltip>
          )}
          {missingCommentsCount > 0 && (
            <Tooltip openDelay={100} position="left" anchorElement="target">
              <span
                title={"Total uncommented items"}
                className={missingCommentClass}
              >
                {missingCommentsCount}
              </span>
            </Tooltip>
          )}
        </span>
      </div>
    </th>
  );
};

const IconCell = (props) => {
  const { dataItem, field } = props;
  const value = Math.round(dataItem?.[field]);
  const dataVal = dataItem?.[field];
  const missingOutOfTolerance =
    dataItem?.dates?.[props?.index]?.missingOutOfTolerance;
  const iconClass = missingOutOfTolerance ? "errorIcon" : "warningIcon";
  const title = missingOutOfTolerance
    ? gain_loss_mismatch_message
    : "Missing Data";
  const icon = missingOutOfTolerance
    ? require("../../../../../assets/error.png")
    : require("../../../../../assets/warning.png");
  return (
    <td style={{ paddingTop: "0.8vh" }}>
      {!dataVal && dataVal !== 0 ? (
        <Tooltip openDelay={100} position="left" anchorElement="target">
          <img className={iconClass} title={title} src={icon} alt="Warning" />
        </Tooltip>
      ) : (
        value
      )}
    </td>
  );
};

const MissingToleranceIconCell = (props) => {
  const { dataItem, field } = props;
  const dataVal = dataItem?.[field];
  return (
    <td style={{ paddingTop: "0.8vh" }}>
      {!dataVal ? (
        <Tooltip openDelay={100} position="left" anchorElement="target">
          <img
            className="errorIcon"
            title={gain_loss_mismatch_message}
            src={require("../../../../../assets/error.png")}
            alt="Warning"
          />
        </Tooltip>
      ) : (
        dataVal
      )}
    </td>
  );
};

const CommentsCell = (props) => {
  const { dataItem, field } = props;
  const value = dataItem?.[field];
  const historicalViewEnabled = props?.historicalViewEnabled;
  if (historicalViewEnabled) {
    return <td>{value}</td>;
  }
  return (
    <DropDownCell
      {...props}
      commentsOptions={props?.commentsOptions}
      selectedMode={props?.selectedMode}
      requestDate={props?.requestDate}
      // disabled={props.dataItem.disabled}
    />
  );
};

export const TrendViewMode = {
  MATERIAL: 0,
  TANK: 1,
};

export const tankColsToShow = () => {
  const columns = [
    {
      title: "Material",
      field: "materialNo",
    },
    {
      title: "Product Name",
      field: "additionalProductInfo",
    },
    {
      title: "Product",
      field: "productCode",
    },
    {
      title: "TG",
      field: "tankGroup",
    },
    {
      title: "Tank",
      field: "tankName",
    },
  ];
  return columns;
};

export const colsToShow = (
  trendAnalysisData,
  commentsOptions,
  selectedMode,
  requestDate,
  blankCount,
  missingOutOfToleranceCount,
  handleShipToLinkAction,
  historicalViewEnabled,
  missingCommentsCount
) => {
  let columns = [
    {
      title: "Ship To",
      field: "shipTo",
      filterable: true,
      sortable: true,
      cell: (props) => {
        return (
          <ShipToLinkCell {...props} linkAction={handleShipToLinkAction} />
        );
      },
      className: selectedMode === TrendViewMode.TANK ? "commentsContainer" : "",
      width:
        selectedMode === TrendViewMode.MATERIAL
          ? window.innerWidth * DECIMAL.POINT_ZERO_FOUR
          : window.innerWidth * DECIMAL.POINT_ZERO_FIVE,
    },
    {
      title: isBFXCluster() ? "Country" : "Region",
      field: "region",
      headerCell: CustomHeaderCell,
      width: isBFXCluster()
        ? window.innerWidth * DECIMAL.POINT_ZERO_THREE
        : window.innerWidth * DECIMAL.POINT_ZERO_FOUR,
      filterable: true,
      sortable: true,
    },
    {
      title: "SLOC",
      field: "sloc",
      filterable: true,
      sortable: true,
    },
    {
      title: "OBN",
      field: "obn",
      filterable: true,
      sortable: true,
    },
  ];

  columns =
    selectedMode === TrendViewMode.TANK
      ? [
          ...columns,
          {
            title: "Tank Group",
            field: "tankGroup",
            sortable: true,
          },
        ]
      : columns;

  columns = [
    ...columns,
    {
      title: "Material No",
      field: "materialNo",
      filterable: true,
      sortable: true,
      width:
        selectedMode === TrendViewMode.MATERIAL
          ? window.innerWidth * DECIMAL.POINT_ZERO_FOUR
          : window.innerWidth * DECIMAL.POINT_ZERO_FIVE,
    },
  ];

  const data = trendAnalysisData?.[0];
  for (let index = 9; index >= 0; index--) {
    const item = data?.dates?.[index];
    const dateColumn = {
      title: item?.title || "||||||||",
      field: `day${index}`,
      sortable: false,
      headerCell: (props) => {
        return (
          <DateHeaderCell {...props} blankCount={blankCount} index={index} />
        );
      },
      cell: (props) => {
        return <IconCell {...props} index={index} />;
      },
    };
    columns.push(dateColumn);
  }

  columns = [
    ...columns,
    {
      title: "5 Days Total",
      field: "fiveDaysTotal",
      className: "totalClass",
      sortable: true,
    },
    {
      title: "10 Days Total",
      field: "tenDaysTotal",
      className: "totalClass",
      sortable: true,
    },
  ];
  if (isBFXCluster()) {
    columns = [
      ...columns,
      {
        title: "30 Days Total",
        field: "thirtyDaysTotal",
        className: "totalClass",
      },
    ];
  }
  if (selectedMode === TrendViewMode.MATERIAL) {
    columns = [
      ...columns,
      {
        title: "Comments",
        field: "comment",
        filterable: true,
        sortable: true,
        headerCell: (props) => {
          return (
            <CommentHeaderCell
              {...props}
              missingOutOfTolerance={missingOutOfToleranceCount}
              missingCommentsCount={missingCommentsCount}
            />
          );
        },
        cell: (props) => {
          return (
            <CommentsCell
              {...props}
              commentsOptions={commentsOptions}
              selectedMode={selectedMode}
              requestDate={requestDate}
              historicalViewEnabled={historicalViewEnabled}
              // disabled={props.dataItem.disabled}
            />
          );
        },
        width: window.innerWidth * 0.1,
      },
      {
        title: isDachCluster() ? "1 day +250" : "1 day +150",
        field: "oneDayGain",
        cell: MissingToleranceIconCell,
        filterable: true,
        sortable: true,
      },
      {
        title: isDachCluster() ? "1 day -250" : "1 day -150",
        field: "oneDayLoss",
        cell: MissingToleranceIconCell,
        filterable: true,
        sortable: true,
      },
      {
        title: "5D Cumul +250/-250",
        field: "isFiveDaysCumulative",
        headerCell: CustomHeaderCell,
        cell: MissingToleranceIconCell,
        filterable: true,
        sortable: true,
      },
      {
        title: "5D Cumul%",
        field: "isFiveDaysCumulativePercent",
        headerCell: CustomHeaderCell,
        cell: MissingToleranceIconCell,
        filterable: true,
        sortable: true,
      },
    ];
    if (isBFXCluster()) {
      columns = [
        ...columns,
        {
          title: "30D Cumul +750/-750",
          field: "isThirtyDaysCumulative",
          headerCell: CustomHeaderCell,
          cell: MissingToleranceIconCell,
          filterable: true,
          sortable: true,
        },
        {
          title: "30D Cumul%",
          field: "isThirtyDaysCumulativePercent",
          headerCell: CustomHeaderCell,
          cell: MissingToleranceIconCell,
          filterable: true,
          sortable: true,
        },
      ];
    }
  }

  return columns;
};
