import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { tankGroupColsToShow } from "./site-information-utils";
import TankPumpDetailsContainer from "./site-tank-pump-details";

const SiteTankDetailsController = props => {
    const dataItem = props?.dataItem;
    let NozzleInfo = dataItem?.TankInfo;
    NozzleInfo?.sort((a, b) => a.deviceID - b.deviceID);
    NozzleInfo?.sort((a, b) => {
      const tankGroup1 = a?.tankGroup?.replace(/\D/g, "");
      const tankGroup2 = b?.tankGroup?.replace(/\D/g, "");
      return tankGroup1 - tankGroup2;
    });
    NozzleInfo = NozzleInfo?.map((item) => ({
      ...item,
      TankNo: item?.deviceID || item?.tankName,
      tankGroup: item?.tankGroup || `TG${item?.deviceID}`,
      GlobalSiteId: dataItem?.GlobalSiteId,
    }));

    return (
      <>
        <div className="container-fluid">
          <div className="openingHoursContainer">
            <AppDataGrid
              style={{ maxHeight: "50vh", borderColor: "#b8e2ea" }}
              take={10000000}
              pageable={false}
              filterable={true}
              expandable={true}
              sortable={true}
              data={NozzleInfo}
              detailsContainer={(detail) => (
                <TankPumpDetailsContainer
                  {...detail}
                  currentSiteItem={props?.currentSiteItem}
                  selectedTankItem={props?.selectedTankItem}
                  selectedTankPumpItem={props?.selectedTankPumpItem}
                  siteData={props?.siteData}
                  setSubmitting={props?.setSubmitting}
                  setSelectedTankPumpItem={props?.setSelectedTankPumpItem}
                  setSiteData={props?.setSiteData}
                  handleTankPumpDelete={props?.handleTankPumpDelete}
                  readOnly={props?.readOnly}
                />
              )}
              numericSortFields={["tankGroup"]}
              columnsToShow={tankGroupColsToShow({
                onEdit: props?.handleTankGroupEdit,
                onDelete: props?.handleTankGroupDelete,
                onAdd: props?.handleTankGroupAdd,
                selectedItem: props?.currentTankItem,
                siteItem: props?.dataItem,
                readOnly: props?.readOnly,
              })}
              expandChange={(data) => {
                const siteId = data?.[0]?.GlobalSiteId;
                const updatedData = props?.siteData?.map((item) => {
                  if (item?.GlobalSiteId === siteId) {
                    return {
                      ...item,
                      TankInfo: data,
                    };
                  }
                  return item;
                });
                props?.setSiteData(updatedData);
              }}
            />
          </div>
        </div>
      </>
    );
  };

  export default SiteTankDetailsController;