import "./issue-add.scss";
import { Button, DatePicker, Input } from "@progress/kendo-react-all";
import { SearchableDropdown } from "../../../../../components/searchable-dropdown/searchable-dropdown";
import { TextArea } from "@progress/kendo-react-inputs";
import { useState, useEffect } from "react";

import { issueService } from "../../../../../../services/issue";
import { store } from "../../../../../../state-management/store";
import { triggerNotification } from "../../../../../../state-management/actions";
import { handleErrors } from "../IssueUtils";
import { useDispatch, useSelector } from "react-redux";
import { getFormattedDate, serverError } from "../../../../../../services/utils";
import { LoadingPanel } from "../../../../../components/loading-panel/loading-panel";
import { IssueRootCause, NUMBERS } from "../../common-utils";

const AddIssueScreen = (props) => {
  const [payload, setPayload] = useState(null);
  const [addingIssue, setAddingIssue] = useState(false);
  const dispatch = useDispatch();
  const defaultCountry = useSelector((state) => state?.selectedCountry);
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);

  store.subscribe(() => {
    const { selectedCountry } = store.getState();
    setCurrentCountryCode(selectedCountry);
  });

  const updatePayload = (e) => {
    setPayload(e);
  };

  const addNewIssue = async () => {
    const {
      action,
      actionParty,
      status,
      loggedDate,
      raisedDate,
      closedDate,
      shipTo,
      plant,
      sloc,
      selectedSiteId,
      selectedSiteName,
      material,
      materialName,
      batch,
      variance,
      natureOfScope,
      description,
      plantCode,
      siteDate,
      gsap,
    } = payload;
    let newIssue = {
      PartitionKey: "DE",
      actionParty: actionParty,
      action: action,
      status: status,
      transactionDate: loggedDate?.toISOString(),
      raisedDate: raisedDate?.toISOString(),
      shipTo: shipTo,
      plant: plant,
      sloc: sloc,
      globalSiteId: selectedSiteId,
      siteName: selectedSiteName,
      materialCode: material,
      materialName: materialName,
      batch: batch,
      variance: parseFloat(variance),
      natureOfOpenIssue: natureOfScope,
      issueDescription: description,
      plantCode: plantCode,
      siteDate: siteDate,
      gsap: gsap,
    };

    if (status === "Closed") {
      newIssue = {
        ...newIssue,
        closedDate: closedDate?.toISOString(),
      };
    }

    if (newIssue?.globalSiteId?.length <= 0) {
      store.dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: `Ship To is mandatory field`,
        })
      );
      return;
    }

    if (newIssue?.materialCode?.length <= 0) {
      store.dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: `MaterialCode is mandatory field`,
        })
      );
      return;
    }
    if (variance?.length <= 0) {
      store.dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: `Variance is mandatory field`,
        })
      );
      return;
    }

    if (!newIssue?.transactionDate || newIssue?.transactionDate?.length <= 0) {
      store.dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: `Transaction Date is mandatory field`,
        })
      );
      return;
    }
    setAddingIssue(true);
    try {
      const response = await issueService.createOrUpdateIssue(
        newIssue,
        currentCountryCode
      );
      if (response?.data || (response?.status >= NUMBERS.TWO_HUNDRED && response?.status < NUMBERS.THREE_HUNDRED)) {
        store.dispatch(
          triggerNotification({
            type: {
              style: "success",
              icon: true,
            },
            message: `Issue added successfully!`,
          })
        );
        props?.onSave();
      } else {
        handleErrors(response);
      }
    }
    catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: serverError,
        })
      );
    }
    setAddingIssue(false);
  };

  const showHeader = () => {
    return (
      <div className="deliveryHeader">
        <div className="title">Add New Issue</div>
      </div>
    );
  };

  const showSubmitButton = () => {
    return (
      <div className="submit-btn-container">
        <Button className="submit-btn" onClick={addNewIssue}>
          Save
        </Button>
        <Button className="btn-cancel" onClick={props.onClose}>
          Cancel
        </Button>
      </div>
    );
  };

  return (
    <div className="new-issue-screen">
      <div className="issueContainer">
        {showHeader()}
        <NewIssueTable
          onChange={updatePayload}
          selectedCountry={currentCountryCode}
        />
        {showSubmitButton()}
      </div>

      {addingIssue && (
        <span className="loader-container">
          <LoadingPanel message="Adding the issue. please wait..." />
        </span>
      )}
    </div>
  );
};

const NewIssueTable = (props) => {
  const newIssueSchema = {
    actionParty: "CWR",
    action: "",
    status: "Open",
    loggedDate: new Date(),
    raisedDate: new Date(),
    closedDate: new Date(),
    shipTo: "",
    plant: "",
    sloc: "",
    siteInfo: [],
    selectedSiteId: "",
    selectedSiteName: "",
    material: "",
    materialName: "",
    batch: "",
    plantCode: "",
    siteDate: "",
    gsap: "",
    variance: "",
    natureOfScope: "Unknown",
    description: "",
  };
  const [loading, setLoading] = useState(false);
  const [newIssue, setNewIssue] = useState(newIssueSchema);

  const getSiteInfo = async () => {
    const siteInfo = await issueService.getAllSiteIds(props?.selectedCountry);
    setNewIssue((prev) => {
      return { ...prev, siteInfo };
    });
  };

  const getData = async () => {
    setLoading(true);
    await getSiteInfo();
    setLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.onChange(newIssue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newIssue]);

  return (
    <>
      {loading && (
        <span className="loader-container">
          <LoadingPanel message="Loading the form please wait..." />
        </span>
      )}
      <div className="issueContent">
        <div className="row">
          <div className="labelContainer">Raised Date</div>
          <div className="valueContainer">
            {getFormattedDate(newIssue?.raisedDate)}
          </div>
        </div>
        <div className="row">
          <div className="labelContainer">Action Party</div>
          <div className="valueContainer">
            <SearchableDropdown
              value={{ text: newIssue.actionParty }}
              onSelect={(e) =>
                setNewIssue((prev) => {
                  return { ...prev, actionParty: e.value.text };
                })
              }
              data={["RSAA/WSFP", "CWR", "OTD", "BTC", "WS"]}
            />
          </div>
        </div>
        <div className="row">
          <div className="labelContainer">Action</div>
          <div className="valueContainer">
            <Input
              maxLength={100}
              onChange={(e) =>
                setNewIssue((prev) => {
                  return { ...prev, action: e.value };
                })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="labelContainer">CWR Status</div>
          <div className="valueContainer">
            <SearchableDropdown
              value={{ text: newIssue.status }}
              onSelect={(e) =>
                setNewIssue((prev) => {
                  return { ...prev, status: e.value.text };
                })
              }
              data={["Open", "Monitoring", "Closed"]}
            />
          </div>
        </div>
        <div className="row">
          <div className="labelContainer required">Transaction Date</div>
          <div className="valueContainer">
            <DatePicker
              value={newIssue.loggedDate}
              onChange={(e) =>
                setNewIssue((prev) => {
                  return { ...prev, loggedDate: e.value };
                })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="labelContainer required">Ship To</div>
          <div className="valueContainer">
            <SearchableDropdown
              onSelect={(e) =>
                setNewIssue((prev) => {
                  const selectedSiteName = newIssue.siteInfo.filter(
                    (siteId) => siteId === e.value.text
                  )[0]?.name;
                  return {
                    ...prev,
                    selectedSiteId: e.value.text,
                    selectedSiteName,
                  };
                })
              }
              data={newIssue.siteInfo.map((data) => data.globalSiteId)}
            />
          </div>
        </div>
        <div className="row">
          <div className="labelContainer required">Material</div>
          <div className="valueContainer">
            <Input
              maxLength={10}
              onChange={(e) =>
                setNewIssue((prev) => {
                  return { ...prev, material: e.value };
                })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="labelContainer required">Variance</div>
          <div className="valueContainer">
            <Input
              onChange={(e) =>
                setNewIssue((prev) => {
                  return { ...prev, variance: e.value };
                })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="labelContainer">Batch</div>
          <div className="valueContainer">
            <Input
              maxLength={30}
              onChange={(e) =>
                setNewIssue((prev) => {
                  return { ...prev, batch: e.value };
                })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="labelContainer">GSAP</div>
          <div className="valueContainer">
            <Input
              onChange={(e) =>
                setNewIssue((prev) => {
                  return { ...prev, gsap: e.value };
                })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="labelContainer">Nature of scope</div>
          <div className="valueContainer">
            <SearchableDropdown
              onSelect={(e) =>
                setNewIssue((prev) => {
                  return { ...prev, natureOfScope: e.value.text };
                })
              }
              value={{ text: newIssue.natureOfScope }}
              data={[
                IssueRootCause.Blocked_Gauges_Probe_Issue,
                IssueRootCause.Confirmed_PLIP,
                IssueRootCause.Confirmed_ATG_Defect,
                IssueRootCause.WSMA_system_Issue,
                IssueRootCause.Invalid_Overstated_Delivery,
                IssueRootCause.Missing_Confirmed_Deliveries,
                IssueRootCause.Missing_Understated_Delivery,
                IssueRootCause.Missing_Understated_Sales,
                IssueRootCause.Possible_ATG_Defect,
                IssueRootCause.No_End_Stock_Data,
                IssueRootCause.Possible_PLIP,
                IssueRootCause.Possible_Test_Pump,
                IssueRootCause.Site_system_Issue,
                IssueRootCause.Temporarily_Closed_Site,
                IssueRootCause.Timing_difference_on_delivery,
                IssueRootCause.Waiting_for_confirmed_delivery,
                IssueRootCause.Unknown,
              ]}
            />
          </div>
        </div>

        <div className="row">
          <div className="labelContainer">
            Issue Description or Updates (250 Chars Max)
          </div>
          <div className="valueContainer">
            <TextArea
              onChange={(e) =>
                setNewIssue((prev) => {
                  return { ...prev, description: e.value };
                })
              }
              maxLength={250}
              placeholder="Write an issue description here...."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { AddIssueScreen };
