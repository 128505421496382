import "./report-list.scss";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { triggerNotification } from "../../../../../state-management/actions";
import { Loader } from "@progress/kendo-react-indicators";
import { reportsService } from "../../../../../services/reports";
import { DatePicker, Button } from "@progress/kendo-react-all";
import { readOnlyUser, serverError, unableToFetch } from "../../../../../services/utils";
import { NUMBERS } from "../common-utils";

const IN_PROCESS_STATUS = "In Process";

const ReportRequestScreen = () => {
  const dispatch = useDispatch();

  const [reportsData, setReportsData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [reportLoading, setReportLoading] = useState(false);

  const [readOnly, setReadOnly] = useState(false);
  useEffect(() => {
    setReadOnly(readOnlyUser());
  }, []);

  const fetchReportData = async () => {
    try {
      const response = await reportsService.getRestoreData();
      setLoading(false);
      if (response) {
        setReportsData(response);
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: unableToFetch,
          })
        );
      }
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: serverError,
        })
      );
      setError(serverError);
      setLoading(false);
      setError("");
    }
  };

  useEffect(() => {
    fetchReportData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitRequest = async (report) => {
    setReportLoading(true);
    try {
      const status = await reportsService.saveRestoreData(report);
      setReportLoading(false);
      if (status >= NUMBERS.TWO_HUNDRED && status < NUMBERS.THREE_HUNDRED) {
        const data = reportsData?.map((item) => {
          if (item?.reportCode === report?.reportCode) {
            return {
              ...item,
              status: IN_PROCESS_STATUS,
            };
          }
          return item;
        });
        setReportsData(data);
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: "Unable to submit the request.",
          })
        );
      }
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Server error. Unable to submit the request",
        })
      );
      setReportLoading(false);
      setError("Server error. submit the request.");
      setError("");
    }
  };

  const handleSubmitAction = (report) => {
    const diffInMs =
      new Date(report?.restoreEndDate) - new Date(report?.restoreStartDate);
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    let message = "";
    if (diffInDays < 0) {
      message = "End Date should be  greater than Start Date";
    } else if (diffInDays === 0) {
      message = "End Date & Start Date should not be same";
    } else if (diffInDays > report?.maxHistoryDataLimitInDays) {
      message =
        "Duration should not be greater than allowed duration. Please adjust Start/End date";
    }

    if (message?.length > 0) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: message,
        })
      );
      return;
    }
    submitRequest(report);
  };

  const showReport = () => {
    if (reportsData && reportsData.length > 0) {
      const max = new Date();
      return (
        <table className="report-table">
          <thead className="table-header">
            <tr className="header-row">
              <td style={{ minWidth: "20vw", width: '30vw' }}>Report Name</td>
              <td style={{ minWidth: "6vw", width: '30vw' }}>Allowed Maximum History Data Duration (Days)</td>
              <td style={{ minWidth: "6vw" }}>Allowed Duration (Days)</td>
              <td style={{ minWidth: "11vw" }}>Start Date</td>
              <td style={{ minWidth: "11vw" }}>End Date</td>
              <td style={{ minWidth: "10vw" }}>Historical Data Status</td>
              {!readOnly && <td style={{ minWidth: "6vw" }}></td>}
            </tr>
          </thead>
          <tbody className="table-body">
            {reportsData.map((report) => {
              return (
                <tr className="report-row" key={`${report?.reporName}-${report?.reportCode}`}>
                  <td>{report?.reporName}</td>
                  <td>{report?.maxHistoryDataLimitInDays}</td>
                  <td>{report?.thresholdDays}</td>
                  <td>
                    <DatePicker
                      disabled={
                        report?.status === IN_PROCESS_STATUS || reportLoading
                      }
                      placeholder="dd/MMM/yyyy"
                      format={"dd-MMM-yyyy"}
                      value={new Date(report?.restoreStartDate)}
                      max={max}
                      onChange={(e) => {
                        const updateDate = new Date(e?.value);

                        const updateReports = reportsData?.map((item) => {
                          if (item?.reportCode === report?.reportCode) {
                            return {
                              ...item,
                              restoreStartDate: updateDate,
                            };
                          }
                          return item;
                        });

                        setReportsData(updateReports);
                      }}
                    />
                  </td>
                  <td>
                    <DatePicker
                      disabled={
                        report?.status === IN_PROCESS_STATUS || reportLoading
                      }
                      placeholder="dd/MMM/yyyy"
                      format={"dd-MMM-yyyy"}
                      max={max}
                      value={new Date(report?.restoreEndDate)}
                      onChange={(e) => {
                        const updateDate = new Date(e?.value);

                        const updateReports = reportsData?.map((item) => {
                          if (item?.reportCode === report?.reportCode) {
                            return {
                              ...item,
                              restoreEndDate: updateDate,
                            };
                          }
                          return item;
                        });

                        setReportsData(updateReports);
                      }}
                    />
                  </td>
                  <td>{report?.status}</td>
                  {!readOnly && (
                    <td>
                      <Button
                        className="update-btn"
                        disabled={
                          report?.status === IN_PROCESS_STATUS || reportLoading
                        }
                        onClick={() => handleSubmitAction(report)}
                      >
                        Submit
                      </Button>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
    return (
      <div className="no-report-container">
        <h3>No report request found</h3>
      </div>
    );
  };

  return (
    <div className="container data-grid site-info-container">
      <div className="row ">
        {/* <h4 className="title">Historic Data Retrieval</h4> */}
        {error && <span>{error}</span>}
        {loading ? (
          <>
            <div className="centralized-loader-container">
              <Loader
                size="medium"
                themeColor="warning"
                type="converging-spinner"
              />
              <br />
              <h5 className="loader-text">Fetching the data please wait...</h5>
              <p className="loader-description">
                This might take a minute or two, due to large amounts of data
              </p>
            </div>
          </>
        ) : (
          <div className="data-grid-container">
            {showReport()}
            {reportLoading && (
              <Loader
                style={{ position: "absolute", left: "50%", top: "30%" }}
                size="large"
                themeColor="warning"
                type="converging-spinner"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { ReportRequestScreen };
