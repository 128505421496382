// Native packaged imports
import React from "react";
import {
  Routes,
  BrowserRouter as Router,
  Route,
  Navigate,
} from "react-router-dom";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";

// Local imports
import { ProtectedRoutes } from "./ui/components/protected-routes/protectedRoutes";
import { HomePage } from "./ui/pages/home/home";
import { LoginPage } from "./ui/pages/login/login";
import { ReportsPage } from "./ui/pages/reports/reports";
import { ProfilePage } from "./ui/pages/profile/profile.jsx";
import { PageNotFound } from "./ui/pages/page-not-found/page-not-found";
import { loggerService } from "./services/logger";
import { CustomNotificationsContainer } from "./ui/components/custom-notifications-container/custom-notifications-container";
import { ShellThemeProvider } from "@sede-x/shell-ds-react-framework";
// Stylesheet imports
import "./ui/styles/shell.scss";
import "./ui/styles/global.scss";
import "react-notifications/lib/notifications.css";
import { persistor, store } from "./state-management/store";
import { AboutPage } from "./ui/pages/about/about";
import { TourPage } from "./ui/pages/tour/tour";
import { ContactPage } from "./ui/pages/contact/contact";
import { UserPreferencePage } from "./ui/pages/user-preference/UserPreference";
import ExporToExcel from "./ui/pages/export-to-excel/ExporToExcel";
import { Helmet } from "react-helmet";
import { AuthenticationProvider } from "@sede-x/auth";
import { appConfig } from "./configs/app-config.js";
import { PersistGate } from "redux-persist/integration/react";

const container = document.querySelector("#root");
const root = createRoot(container);

const initialize = () => {
  loggerService.environment();

  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ShellThemeProvider theme="light">
          <Helmet>
            <title>WSM Analytics</title>
            <script async />
          </Helmet>
          <Router>
            <AuthenticationProvider
              {...appConfig.auth.ping}
              scopes={["openid", "profile"]}
            >
              <Routes>
                <Route path="/exporttoexcel" element={<ExporToExcel />} />
                <Route element={<ProtectedRoutes />}>
                  <Route path="/reports" element={<ReportsPage />} />
                  <Route path="/home" element={<HomePage />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/preference" element={<UserPreferencePage />} />
                  <Route path="/about" element={<AboutPage />} />
                  <Route path="/tour" element={<TourPage />} />
                  <Route path="/contact" element={<ContactPage />} />
                </Route>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/" element={<Navigate to={"/login"} />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </AuthenticationProvider>
          </Router>
          <CustomNotificationsContainer />
        </ShellThemeProvider>
      </PersistGate>
    </Provider>
  );
};

/**
 * This is the entry point to the application. The entire processing start from here.
 */
initialize();
