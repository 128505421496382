import "./issue-update.scss";
import {
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { SearchableDropdown } from "../../../../../components/searchable-dropdown/searchable-dropdown";
import { Button, DatePicker, Input } from "@progress/kendo-react-all";
import { useRef, useState } from "react";
import { issueService } from "../../../../../../services/issue";
import { triggerNotification } from "../../../../../../state-management/actions";
import { store } from "../../../../../../state-management/store";
import { ContentWindow } from "../../../../../components/content-window/content-window";
import { AppDataGrid } from "../../../../../components/data-grid/dataGrid";
import { getFormattedDate, serverError } from "../../../../../../services/utils";
import { handleErrors } from "../IssueUtils";
import { useDispatch, useSelector } from "react-redux";
import { AreaOfIssue, IssueRootCause, notAvailable, NUMBERS } from "../../common-utils";

const UpdateIssueItems = {
  CENTRAL_WET_STOCK: "CENTRAL WET STOCK",
  RSAA_WSFP: "RSAA/WSFP",
  DFT: "DFT",
  BTC: "BTC",
};

const updateConfig = [
  {
    id: UpdateIssueItems.CENTRAL_WET_STOCK,
    category: "CENTRAL WETSTOCK",
  },
  {
    id: UpdateIssueItems.RSAA_WSFP,
    category: "RSAA/WSFP",
  },
  {
    id: UpdateIssueItems.DFT,
    category: "DFT",
  },
  {
    id: UpdateIssueItems.BTC,
    category: "BTC",
  },
];

const UpdateIssueScreen = (props) => {
  const originalIssue = props?.issue;
  const [expandedItems, setExpandedItems] = useState([updateConfig?.[0]]);
  const defaultCountry = useSelector((state) => state?.selectedCountry);
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);
  const textAreaRef = useRef(null);
  const dispatch = useDispatch();
  store.subscribe(() => {
    const { selectedCountry } = store.getState();
    setCurrentCountryCode(selectedCountry);
  });

  const [issueBeingUpdated, setIssueBeingUpdated] = useState({
    ...originalIssue,
    closedDate: originalIssue?.closedDate
      ? originalIssue?.closedDate
      : new Date().toISOString(),
    dfT_DateClosed: originalIssue?.dfT_DateClosed
      ? originalIssue?.dfT_DateClosed
      : new Date().toISOString(),
    btC_DateClosed: originalIssue?.btC_DateClosed
      ? originalIssue?.btC_DateClosed
      : new Date().toISOString(),
    wsfP_DateClosed: originalIssue?.wsfP_DateClosed
      ? originalIssue?.wsfP_DateClosed
      : new Date().toISOString(),
  });
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisibility] = useState(false);
  const [history, setHistory] = useState([]);

  const saveUpdatedIssue = async () => {
    setLoading(true);
    issueBeingUpdated?.status !== "Closed" &&
      delete issueBeingUpdated.closedDate;
    issueBeingUpdated?.dfT_Status !== "Closed" &&
      delete issueBeingUpdated.dfT_DateClosed;
    issueBeingUpdated?.btC_Status !== "Closed" &&
      delete issueBeingUpdated.btC_DateClosed;
    issueBeingUpdated?.wsfP_Status !== "Closed" &&
      delete issueBeingUpdated.wsfP_DateClosed;

    try {
      const response = await issueService.createOrUpdateIssue(
        issueBeingUpdated,
        currentCountryCode
      );
      if (response?.data || (response?.status >= NUMBERS.TWO_HUNDRED && response?.status < NUMBERS.THREE_HUNDRED)) {
        store.dispatch(
          triggerNotification({
            type: {
              style: "success",
              icon: true,
            },
            message: `Issue updated successfully!`,
          })
        );
        props.onSave();
      } else {
        handleErrors(response);
      }
    }
    catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: serverError,
        })
      );
    }
    setLoading(false);
  };

  const getCentralWetStockItems = () => {
    const handleCommentHistoryClick = () => {
      const updatedHistory = issueBeingUpdated?.commentsHistory
        ? JSON.parse(issueBeingUpdated?.commentsHistory)
        : [];

      if (updatedHistory) {
        const sanitized = updatedHistory.map((h) => {
          return { ...h, DateAdded: new Date(h?.DateAdded).toDateString() };
        });
        setHistory(sanitized);
        setModalVisibility(true);
      }
    };
    return (
      <div className="contentContainer">
        <div className="leftContainer">
          <div className="row">
            <div className="labelContainer">Transaction Date</div>
            <div className="valueContainer">
              {getFormattedDate(new Date(issueBeingUpdated?.transactionDate))}
            </div>
          </div>
          <div className="row">
            <div className="labelContainer">Ship To</div>
            <div className="valueContainer">
              {issueBeingUpdated?.globalSiteId}
            </div>
          </div>
          <div className="row">
            <div className="labelContainer">Material</div>
            <div className="valueContainer">
              {issueBeingUpdated?.materialCode}
            </div>
          </div>
          <div className="row">
            <div className="labelContainer">Plant</div>
            <div className="valueContainer">{issueBeingUpdated?.plantCode}</div>
          </div>
          <div className="row">
            <div className="labelContainer">Action Party</div>
            <div className="valueContainer">
              <SearchableDropdown
                onSelect={(e) => {
                  setIssueBeingUpdated({
                    ...issueBeingUpdated,
                    actionParty: e.value.text,
                  });
                }}
                value={{ text: issueBeingUpdated.actionParty }}
                data={["RSAA/WSFP", "CWR", "OTD", "BTC", "WS"]}
              />
            </div>
          </div>
          <div className="row">
            <div className="labelContainer">Action</div>
            <div className="valueContainer">
              <Input
                value={issueBeingUpdated?.action}
                onChange={(e) => {
                  setIssueBeingUpdated({
                    ...issueBeingUpdated,
                    action: e.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="labelContainer">Issue Description or Updates</div>
            <div className="valueContainer">
              <textarea
                className="textarea"
                ref={textAreaRef}
                onChange={(e) => {
                  setIssueBeingUpdated({
                    ...issueBeingUpdated,
                    issueDescription: e.target.value,
                  });
                }}
                value={issueBeingUpdated?.issueDescription}
              />
            </div>
          </div>
        </div>
        <div className="middleContainer">
          <div className="row">
            <div className="labelContainer">Raised Date</div>
            <div className="valueContainer">
              {getFormattedDate(new Date(issueBeingUpdated?.raisedDate))}
            </div>
          </div>
          <div className="row">
            <div className="labelContainer">Site Name</div>
            <div className="valueContainer">{issueBeingUpdated?.siteName}</div>
          </div>
          <div className="row">
            <div className="labelContainer">Material Name</div>
            <div className="valueContainer">
              {issueBeingUpdated?.materialName}
            </div>
          </div>
          <div className="row">
            <div className="labelContainer">Site Data/CWR</div>
            <div className="valueContainer">
              {issueBeingUpdated?.siteVolume ? (
                <Input
                  value={issueBeingUpdated?.siteVolume}
                  onChange={(e) => {
                    setIssueBeingUpdated({
                      ...issueBeingUpdated,
                      siteVolume: e.value,
                    });
                  }}
                />
              ) : (
                notAvailable
              )}
            </div>
          </div>
          <div className="row">
            <div className="labelContainer">Variance</div>
            <div className="valueContainer">
              {issueBeingUpdated?.variance ? (
                <Input
                  value={issueBeingUpdated?.variance}
                  onChange={(e) => {
                    setIssueBeingUpdated({
                      ...issueBeingUpdated,
                      variance: e.value,
                    });
                  }}
                />
              ) : (
                notAvailable
              )}
            </div>
          </div>
          <div className="row">
            <div className="labelContainer">CWR Status</div>
            <div className="valueContainer">
              <SearchableDropdown
                value={{ text: issueBeingUpdated.status }}
                onSelect={(e) => {
                  setIssueBeingUpdated({
                    ...issueBeingUpdated,
                    status: e.value.text,
                  });
                }}
                data={["Open", "Monitoring", "Closed"]}
              />
            </div>
          </div>

          {issueBeingUpdated?.status === "Closed" && (
            <div className="row">
              <div className="labelContainer">Date Closed</div>
              <div className="valueContainer">
                <DatePicker
                  onChange={(e) => {
                    const newDate = new Date(e.value).toISOString();
                    setIssueBeingUpdated({
                      ...issueBeingUpdated,
                      closedDate: newDate,
                    });
                  }}
                  value={
                    issueBeingUpdated?.closedDate &&
                    new Date(issueBeingUpdated?.closedDate)
                  }
                />
              </div>
            </div>
          )}
          {issueBeingUpdated?.commentsHistory && (
            <div className="row">
              <div
                className="labelContainer view-history-link"
                role="button"
                tabIndex={"0"}
                onClick={handleCommentHistoryClick}
              >
                View History
              </div>
              <div className="valueContainer">
                <textarea
                  className="textarea"
                  ref={textAreaRef}
                  onChange={(e) => {
                    setIssueBeingUpdated({
                      ...issueBeingUpdated,
                      issueDescription: e.target.value,
                    });
                  }}
                  value={issueBeingUpdated?.issueDescription}
                />
              </div>
            </div>
          )}
        </div>
        <div className="rightContainer">
          <div className="row">
            <div className="labelContainer">Region</div>
            <div className="valueContainer">{issueBeingUpdated?.region}</div>
          </div>
          <div className="row">
            <div className="labelContainer">Site ID/OBN</div>
            <div className="valueContainer">{issueBeingUpdated?.siteId}</div>
          </div>
          <div className="row">
            <div className="labelContainer">SLOC</div>
            <div className="valueContainer">{issueBeingUpdated?.sloc}</div>
          </div>
          <div className="row">
            <div className="labelContainer">GSAP</div>
            <div className="valueContainer">
              {issueBeingUpdated?.gsapVolume ? (
                <Input
                  value={issueBeingUpdated?.gsapVolume}
                  onChange={(e) => {
                    setIssueBeingUpdated({
                      ...issueBeingUpdated,
                      gsapVolume: e.value,
                    });
                  }}
                />
              ) : (
                notAvailable
              )}
            </div>
          </div>
          <div className="row">
            <div className="labelContainer">Nature of scope</div>
            <div className="valueContainer">
              {issueBeingUpdated?.natureOfOpenIssue ? (
                <SearchableDropdown
                  value={{ text: issueBeingUpdated.natureOfOpenIssue }}
                  onSelect={(e) => {
                    setIssueBeingUpdated({
                      ...issueBeingUpdated,
                      natureOfOpenIssue: e.value.text,
                    });
                  }}
                  data={[
                    IssueRootCause.Blocked_Gauges_Probe_Issue,
                    IssueRootCause.Confirmed_PLIP,
                    IssueRootCause.Confirmed_ATG_Defect,
                    IssueRootCause.WSMA_system_Issue,
                    IssueRootCause.Invalid_Overstated_Delivery,
                    IssueRootCause.Missing_Confirmed_Deliveries,
                    IssueRootCause.Missing_Understated_Delivery,
                    IssueRootCause.Missing_Understated_Sales,
                    IssueRootCause.Possible_ATG_Defect,
                    IssueRootCause.No_End_Stock_Data,
                    IssueRootCause.Possible_PLIP,
                    IssueRootCause.Possible_Test_Pump,
                    IssueRootCause.Site_system_Issue,
                    IssueRootCause.Temporarily_Closed_Site,
                    IssueRootCause.Timing_difference_on_delivery,
                    IssueRootCause.Waiting_for_confirmed_delivery,
                    IssueRootCause.Unknown,
                  ]}
                />
              ) : (
                notAvailable
              )}
            </div>
          </div>
          <div className="row">
            <div className="labelContainer">Batch</div>
            <div className="valueContainer">
              <Input
                value={issueBeingUpdated?.batch}
                onChange={(e) => {
                  setIssueBeingUpdated({
                    ...issueBeingUpdated,
                    batch: e.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getRSAAItems = () => {
    return (
      <div className="contentContainer">
        <div className="leftContainer">
          <div className="row">
            <div className="labelContainer required">Area of Issue</div>
            <div className="valueContainer">
              <SearchableDropdown
                value={{ text: issueBeingUpdated?.wsfP_AreaOfIssue }}
                data={[
                  AreaOfIssue.Unknown,
                  AreaOfIssue.No_Issue,
                  AreaOfIssue.No_Data,
                  AreaOfIssue.Data_Connection,
                  AreaOfIssue.Data_Line,
                  AreaOfIssue.Delivery,
                  AreaOfIssue.Sales,
                  AreaOfIssue.Stocks,
                  AreaOfIssue.Tank_Change,
                ]}
                onSelect={(e) => {
                  setIssueBeingUpdated({
                    ...issueBeingUpdated,
                    wsfP_AreaOfIssue: e.value.text,
                  });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="labelContainer">Name</div>
            <div className="valueContainer">
              <Input
                value={issueBeingUpdated.wsfP_Name}
                maxLength={30}
                onChange={(e) => {
                  setIssueBeingUpdated({
                    ...issueBeingUpdated,
                    wsfP_Name: e.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="middleContainer">
          <div className="row">
            <div className="labelContainer">Updates</div>
            <div className="valueContainer">
              <Input
                maxLength={250}
                value={issueBeingUpdated.wsfP_Updates}
                onChange={(e) => {
                  setIssueBeingUpdated({
                    ...issueBeingUpdated,
                    wsfP_Updates: e.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="labelContainer">Status</div>
            <div className="valueContainer">
              <SearchableDropdown
                onSelect={(e) => {
                  setIssueBeingUpdated({
                    ...issueBeingUpdated,
                    wsfP_Status: e.value.text,
                  });
                }}
                value={{ text: issueBeingUpdated?.wsfP_Status }}
                data={["Open", "Monitoring", "Closed"]}
              />
            </div>
          </div>
        </div>
        <div className="rightContainer">
          <div className="row">
            <div className="labelContainer">Nature/Root Cause of Issue</div>
            <div className="valueContainer">
              <SearchableDropdown
                data={[
                  IssueRootCause.Unknown,
                  IssueRootCause.Blocked_Gauges_Probe_Issue,
                  IssueRootCause.Confirmed_PLIP,
                  IssueRootCause.Confirmed_ATG_Defect,
                  IssueRootCause.WSMA_system_Issue,
                  IssueRootCause.Invalid_Overstated_Delivery,
                  IssueRootCause.Missing_Confirmed_Deliveries,
                  IssueRootCause.Missing_Understated_Delivery,
                  IssueRootCause.Missing_Understated_Sales,
                  IssueRootCause.Possible_ATG_Defect,
                  IssueRootCause.No_End_Stock_Data,
                  IssueRootCause.Possible_PLIP,
                  IssueRootCause.Possible_Test_Pump,
                  IssueRootCause.Site_system_Issue,
                  IssueRootCause.Temporarily_Closed_Site,
                  IssueRootCause.Timing_difference_on_delivery,
                  IssueRootCause.Waiting_for_confirmed_delivery,
                ]}
                onSelect={(e) => {
                  setIssueBeingUpdated({
                    ...issueBeingUpdated,
                    wsfP_RootCauseOfIssue: e.value.text,
                  });
                }}
                value={{ text: issueBeingUpdated?.wsfP_RootCauseOfIssue }}
              />
            </div>
          </div>
          {issueBeingUpdated?.wsfP_Status === "Closed" && (
            <div className="row">
              <div className="labelContainer">Date Closed</div>
              <div className="valueContainer">
                <DatePicker
                  onChange={(e) => {
                    const newDate = new Date(e.value).toISOString();
                    setIssueBeingUpdated({
                      ...issueBeingUpdated,
                      wsfP_DateClosed: newDate,
                    });
                  }}
                  value={
                    issueBeingUpdated?.wsfP_DateClosed &&
                    new Date(issueBeingUpdated?.wsfP_DateClosed)
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const getDFTItems = () => {
    return (
      <div className="contentContainer">
        <div className="leftContainer">
          <div className="row">
            <div className="labelContainer required">DFT Area of Issue</div>
            <div className="valueContainer">
              <SearchableDropdown
                onSelect={(e) => {
                  setIssueBeingUpdated({
                    ...issueBeingUpdated,
                    dfT_AreaOfIssue: e.value.text,
                  });
                }}
                value={{ text: issueBeingUpdated?.dfT_AreaOfIssue }}
                data={[
                  AreaOfIssue.Unknown,
                  AreaOfIssue.No_Issue,
                  AreaOfIssue.No_Data,
                  AreaOfIssue.Data_Connection,
                  AreaOfIssue.Data_Line,
                  AreaOfIssue.Delivery,
                  AreaOfIssue.Sales,
                  AreaOfIssue.Stocks,
                  AreaOfIssue.Tank_Change,
                ]}
              />
            </div>
          </div>
          <div className="row">
            <div className="labelContainer">DFT Remarks</div>
            <div className="valueContainer">
              <Input
                maxLength={250}
                value={issueBeingUpdated.dfT_Remarks}
                onChange={(e) => {
                  setIssueBeingUpdated({
                    ...issueBeingUpdated,
                    dfT_Remarks: e.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="middleContainer">
          <div className="row">
            <div className="labelContainer">DFT Reference</div>
            <div className="valueContainer">
              <Input
                maxLength={100}
                value={issueBeingUpdated.dfT_Reference}
                onChange={(e) => {
                  setIssueBeingUpdated({
                    ...issueBeingUpdated,
                    dfT_Reference: e.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="labelContainer">DFT Status</div>
            <div className="valueContainer">
              <SearchableDropdown
                onSelect={(e) => {
                  setIssueBeingUpdated({
                    ...issueBeingUpdated,
                    dfT_Status: e.value.text,
                  });
                }}
                value={{ text: issueBeingUpdated?.dfT_Status }}
                data={["Open", "Monitoring", "Closed"]}
              />
            </div>
          </div>
        </div>
        <div className="rightContainer">
          <div className="row">
            <div className="labelContainer">DFT Nature/Root Cause of Issue</div>
            <div className="valueContainer">
              <SearchableDropdown
                onSelect={(e) => {
                  setIssueBeingUpdated({
                    ...issueBeingUpdated,
                    dfT_RootCauseOfIssue: e.value.text,
                  });
                }}
                value={{ text: issueBeingUpdated?.dfT_RootCauseOfIssue }}
                data={[
                  IssueRootCause.Blocked_Gauges_Probe_Issue,
                  IssueRootCause.Confirmed_PLIP,
                  IssueRootCause.Confirmed_ATG_Defect,
                  IssueRootCause.WSMA_system_Issue,
                  IssueRootCause.Invalid_Overstated_Delivery,
                  IssueRootCause.Missing_Confirmed_Deliveries,
                  IssueRootCause.Missing_Understated_Delivery,
                  IssueRootCause.Missing_Understated_Sales,
                  IssueRootCause.Possible_ATG_Defect,
                  IssueRootCause.No_End_Stock_Data,
                  IssueRootCause.Possible_PLIP,
                  IssueRootCause.Possible_Test_Pump,
                  IssueRootCause.Site_system_Issue,
                  IssueRootCause.Temporarily_Closed_Site,
                  IssueRootCause.Timing_difference_on_delivery,
                  IssueRootCause.Waiting_for_confirmed_delivery,
                  IssueRootCause.Unknown,
                ]}
              />
            </div>
          </div>
          {issueBeingUpdated?.dfT_Status === "Closed" && (
            <div className="row">
              <div className="labelContainer">DFT Status</div>
              <div className="valueContainer">
                <DatePicker
                  onChange={(e) => {
                    const newDate = new Date(e.value).toISOString();
                    setIssueBeingUpdated({
                      ...issueBeingUpdated,
                      dfT_DateClosed: newDate,
                    });
                  }}
                  value={
                    issueBeingUpdated?.dfT_DateClosed &&
                    new Date(issueBeingUpdated?.dfT_DateClosed)
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const getBTCItems = () => {
    return (
      <div className="contentContainer">
        <div className="leftContainer">
          <div className="row">
            <div className="labelContainer required">BTC Area of Issue</div>
            <div className="valueContainer">
              <SearchableDropdown
                onSelect={(e) => {
                  setIssueBeingUpdated({
                    ...issueBeingUpdated,
                    btC_AreaOfIssue: e.value.text,
                  });
                }}
                value={{ text: issueBeingUpdated?.btC_AreaOfIssue }}
                data={[
                  AreaOfIssue.Unknown,
                  AreaOfIssue.No_Issue,
                  AreaOfIssue.No_Data,
                  AreaOfIssue.Data_Connection,
                  AreaOfIssue.Data_Line,
                  AreaOfIssue.Delivery,
                  AreaOfIssue.Sales,
                  AreaOfIssue.Stocks,
                  AreaOfIssue.Tank_Change,
                ]}
              />
            </div>
          </div>
          <div className="row">
            <div className="labelContainer">BTC Remarks</div>
            <div className="valueContainer">
              <Input
                maxLength={250}
                value={issueBeingUpdated.btC_Remarks}
                onChange={(e) => {
                  setIssueBeingUpdated({
                    ...issueBeingUpdated,
                    btC_Remarks: e.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="middleContainer">
          <div className="row">
            <div className="labelContainer">BTC Reference</div>
            <div className="valueContainer">
              <Input
                maxLength={100}
                value={issueBeingUpdated.btC_Reference}
                onChange={(e) => {
                  setIssueBeingUpdated({
                    ...issueBeingUpdated,
                    btC_Reference: e.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="labelContainer">BTC Status</div>
            <div className="valueContainer">
              <SearchableDropdown
                onSelect={(e) => {
                  setIssueBeingUpdated({
                    ...issueBeingUpdated,
                    btC_Status: e.value.text,
                  });
                }}
                value={{ text: issueBeingUpdated?.btC_Status }}
                data={["Open", "Monitoring", "Closed"]}
              />
            </div>
          </div>
        </div>
        <div className="rightContainer">
          <div className="row">
            <div className="labelContainer">BTC Nature/Root Cause of Issue</div>
            <div className="valueContainer">
              <SearchableDropdown
                onSelect={(e) => {
                  setIssueBeingUpdated({
                    ...issueBeingUpdated,
                    btC_RootCauseOfIssue: e.value.text,
                  });
                }}
                value={{ text: issueBeingUpdated?.btC_RootCauseOfIssue }}
                data={[
                  IssueRootCause.Blocked_Gauges_Probe_Issue,
                  IssueRootCause.Confirmed_PLIP,
                  IssueRootCause.Confirmed_ATG_Defect,
                  IssueRootCause.WSMA_system_Issue,
                  IssueRootCause.Invalid_Overstated_Delivery,
                  IssueRootCause.Missing_Confirmed_Deliveries,
                  IssueRootCause.Missing_Understated_Delivery,
                  IssueRootCause.Missing_Understated_Sales,
                  IssueRootCause.Possible_ATG_Defect,
                  IssueRootCause.No_End_Stock_Data,
                  IssueRootCause.Possible_PLIP,
                  IssueRootCause.Possible_Test_Pump,
                  IssueRootCause.Site_system_Issue,
                  IssueRootCause.Temporarily_Closed_Site,
                  IssueRootCause.Timing_difference_on_delivery,
                  IssueRootCause.Waiting_for_confirmed_delivery,
                  IssueRootCause.Unknown,
                ]}
              />
            </div>
          </div>
          {issueBeingUpdated?.btC_Status === "Closed" && (
            <div className="row">
              <div className="labelContainer">DFT Status</div>
              <div className="valueContainer">
                <DatePicker
                  onChange={(e) => {
                    const newDate = new Date(e.value).toISOString();
                    setIssueBeingUpdated({
                      ...issueBeingUpdated,
                      btC_DateClosed: newDate,
                    });
                  }}
                  value={
                    issueBeingUpdated?.btC_DateClosed &&
                    new Date(issueBeingUpdated?.btC_DateClosed)
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const getContent = (id) => {
    switch (id) {
      case UpdateIssueItems.CENTRAL_WET_STOCK:
        return getCentralWetStockItems();

      case UpdateIssueItems.RSAA_WSFP:
        return getRSAAItems();

      case UpdateIssueItems.DFT:
        return getDFTItems();

      case UpdateIssueItems.BTC:
        return getBTCItems();
      default:
        return <></>;
    }
  };

  const showHeader = () => {
    return (
      <div className="header-row">
        <span className="left">
          <h4>Incident Id: {props.issue.incidentId}</h4>
          <p>
            {props.issue.issueDescription || "Issue description not available"}
          </p>
        </span>
        <span className="right">
          <Button
            className="submit-btn"
            onClick={saveUpdatedIssue}
            disabled={loading}
          >
            Save
          </Button>
          <Button className="btn-cancel" onClick={props.onClose}>
            Cancel
          </Button>
        </span>
      </div>
    );
  };

  return (
    <div className="issue-update-screen">
      {showHeader()}
      <div className="contentWrapper">
        <div style={{ overflowY: "auto", maxHeight: "70vh" }}>
          {updateConfig.map((item) => {
            const expandedItem = expandedItems?.find(
              (expandItem) => expandItem.id === item.id
            );
            const isExpanded = expandedItem ? true : false;
            return (
              <ExpansionPanel
                className={isExpanded ? "expanded" : ""}
                title={item.category}
                expanded={isExpanded}
                key={item.id}
                onAction={(event) => {
                  if (event.syntheticEvent.type === "click") {
                    const expanded = expandedItems?.find(
                      (expandItem) => expandItem.id === item.id
                    );
                    if (!expanded) {
                      setExpandedItems((prev) => {
                        return [...prev, item];
                      });
                    } else {
                      const items = expandedItems?.filter(
                        (expandItem) => expandItem.id !== item.id
                      );
                      setExpandedItems(items);
                    }
                  }
                }}
              >
                <Reveal>
                  {isExpanded && (
                    <ExpansionPanelContent>
                      <div className="content">
                        <div role="button" tabIndex="0" onKeyDown={(e) => e.stopPropagation()}>
                          {getContent(item.id)}
                        </div>
                      </div>
                    </ExpansionPanelContent>
                  )}
                </Reveal>
              </ExpansionPanel>
            );
          })}
          <div className="emptyItem"></div>
        </div>
      </div>
      <ContentWindow
        visible={modalVisible}
        onClose={() => setModalVisibility(false)}
        className="ContentWindow"
        title="View History"
      >
        <AppDataGrid
          pageable={true}
          filterable={true}
          data={history}
          columnsToShow={[
            {
              title: "Comment",
              field: "CommentText",
              filterable: true,
            },
            {
              title: "Commented On",
              field: "DateAdded",
              filterable: true,
            },
          ]}
        />
      </ContentWindow>
    </div>
  );
};

export { UpdateIssueScreen };
