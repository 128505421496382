import { React, useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import {
  setCurrentCluster,
  setCurrentCountry,
  triggerNotification,
} from "../../../state-management/actions";
import { useNavigate } from "react-router-dom";
import "./UserPreference.scss";
import { userService } from "../../../services/user";
import { saveLoggedInUser } from "../../../state-management/actions";
import { getAllClusterCountries } from "../reports/components/common-utils";
import { LoadingPanel } from "../../components/loading-panel/loading-panel";
import { DropDownList } from "@progress/kendo-react-dropdowns";

const UserPreferencePage = () => {
  const store = useStore();
  const dispatch = useDispatch();
  const loggedInUser = store.getState()?.user;
  const [userPreferenceData, setUserPreferenceData] = useState({
    defaultCountry: loggedInUser.UserPreference?.DefaultCountry,
    Region: loggedInUser.UserPreference?.Region || "ALL",
  });
  const [countries, setCountries] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useState(() => {
    const items = getAllClusterCountries();
    const options = items?.map((country) => ({
      title: country,
      value: country,
    }));
    setCountries(options);
  }, []);

  const getUserDetailsFromStore = () => {
    if (!loggedInUser) {
      navigate("/login");
      return;
    }
    setUserPreferenceData({
      ...userPreferenceData,
      defaultCountry: {
        title: loggedInUser.UserPreference?.DefaultCountry || "",
        value: loggedInUser.UserPreference?.DefaultCountry || "",
      },
    });
  };
  const onClickSavePreference = async () => {
    setLoading(true);
    const data = await userService.UpdateProfileService({
      Region: userPreferenceData.Region,
      DefaultCountry: userPreferenceData?.defaultCountry?.value,
    });
    if (data.data.Status === "Success") {
      const currentUser = store.getState()?.user;
      if (!currentUser) {
        navigate("/login");
        return;
      }
      const { clusters } = store.getState();

      const selectedCluster = Object?.keys(clusters)?.find((clusterName) => {
        const selectedItem = clusters?.[clusterName].find(
          (item) => item === userPreferenceData?.defaultCountry?.value
        );
        if (selectedItem) {
          return clusterName;
        }
        return null;
      });

      if (selectedCluster) {
        dispatch(setCurrentCluster(selectedCluster));
        dispatch(setCurrentCountry(userPreferenceData?.defaultCountry?.value));
      }

      const user = await userService.fetchUser(currentUser.UniqueUserId);
      store.dispatch(saveLoggedInUser(user));

      store.dispatch(
        triggerNotification({
          type: {
            style: "success",
            icon: false,
          },
          message: `Preferences saved successfully.`,
        })
      );
    } else {
      store.dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: false,
          },
          message: `Error occured while saving the preference.`,
        })
      );
    }
    setLoading(false);
  };
  const handelCountryChange = (option) => {
    const selected = option.value;
    setUserPreferenceData({
      ...userPreferenceData,
      defaultCountry: selected,
    });
  };
  useEffect(() => {
    getUserDetailsFromStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid">
      <div className="row profile-page">
        <div className="container user-details-container">
          <div
            className="row"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-md-6 col-sm-6 col-xs-6 col-lg-6 col-xl-6 user-details-table-container">
              <h2 className="user-details-heading">User Preferences</h2>
              <table>
                <tbody>
                  <tr style={{ display: "flex" }}>
                    <td style={{ display: "flex", flex: 0.2 }}>
                      <b>Country</b>
                    </td>
                    <td>
                      <DropDownList
                        className="dropdownList"
                        data={countries}
                        textField="value"
                        dataItemKey="title"
                        value={userPreferenceData.defaultCountry}
                        onChange={handelCountryChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="btn-container">
                <button
                  className="home-btn"
                  disabled={
                    userPreferenceData?.defaultCountry ===
                    loggedInUser.UserPreference?.DefaultCountry
                  }
                  onClick={onClickSavePreference}
                >
                  Save
                </button>
                <button
                  className="logout-btn"
                  onClick={() => navigate("/home")}
                >
                  Back
                </button>
              </div>
            </div>
            {loading && <LoadingPanel message="Please wait.." />}
          </div>
        </div>
      </div>
    </div>
  );
};

export { UserPreferencePage };
