import "./issue-list.scss";
import { DatePicker, Button, DropDownList } from "@progress/kendo-react-all";
import { useState } from "react";
import { useEffect } from "react";
import { issueService } from "../../../../../../services/issue";
import { siteService } from "../../../../../../services/sites";
import { store } from "../../../../../../state-management/store";
import { useDispatch, useSelector } from "react-redux";
import {
  getFormattedDate,
  serverError,
} from "../../../../../../services/utils";
import { LoadingPanel } from "../../../../../components/loading-panel/loading-panel";
import { SearchableDropdown } from "../../../../../components/searchable-dropdown/searchable-dropdown";
import { AppDataGrid } from "../../../../../components/data-grid/dataGrid";
import {
  colsToShow,
  getActionParties,
  getStatusList,
} from "./issue-list-utils";
import { SiteInfoScreen } from "../../out-of-tolerance-trend-analysis/out-of-tolerance-site-info";
import { AddIssueScreen } from "../issue-add/issue-add";
import { UpdateIssueScreen } from "../issue-update/issue-update";
import { DEFAULT_PAGE_SIZE, NUMBERS } from "../../common-utils";
import { reportsService } from "../../../../../../services/reports";
import { triggerNotification } from "../../../../../../state-management/actions";
const IssueListScreen = () => {
  const [showAddIssue, setShowAddIssue] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [showUpdateIssue, setShowUpdateIssue] = useState(false);
  const [issues, setIssueList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [fetching, setFetching] = useState(false);
  const defaultCountry = useSelector((state) => state?.selectedCountry);
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);
  const [resetIndex, setResetIndex] = useState(false);
  const [issueToUpdate, setIssueToUpdate] = useState();
  const [showSiteInfoModal, setShowSiteInfoModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState();
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({
    country: currentCountryCode,
    region: {
      id: "",
      text: "",
    },
    regions: [],
    raisedDate: null,
    raisedDateIsoString: new Date().toISOString(),
    closedDate: null,
    closedDateIsoString: new Date().toISOString(),
    selectedSiteId: "All",
    allSiteIds: [],
    status: {
      id: "Open",
      text: "Open",
    },
    page: null,
    limit: null,
    statusList: getStatusList(),
    actionParty: {
      id: "CWR",
      text: "CWR",
    },
    actionParties: getActionParties(),
  });

  const getSiteIds = async () => {
    let siteIds = await issueService.getAllSiteIds(currentCountryCode);
    siteIds = siteIds?.map((site) => site?.globalSiteId);
    siteIds = siteIds ? ["All", ...siteIds] : ["All"];
    setFilters((prev) => {
      return { ...prev, allSiteIds: siteIds, selectedSiteId: siteIds?.[0] };
    });
  };

  const setInitialDates = async () => {
    const today = new Date();
    const dateNow = new Date();
    const toDate = `${today.getFullYear()}-${(today.getMonth() + NUMBERS.ONE)
      .toString()
      .padStart(NUMBERS.TWO, "0")}-${today.getDate().toString().padStart(NUMBERS.TWO, "0")}`;
    setFilters((prev) => {
      return {
        ...prev,
        closedDate: toDate,
        closedDateIsoString: today.toISOString(),
      };
    });
    dateNow.setMonth(today.getMonth() - NUMBERS.THREE);
    const startDate = `${dateNow.getFullYear()}-${(dateNow.getMonth() + NUMBERS.ONE)
      .toString()
      .padStart(NUMBERS.TWO, "0")}-${dateNow.getDate().toString().padStart(NUMBERS.TWO, "0")}`;
    const raisedDateIsoString = dateNow.toISOString();
    setFilters((prev) => {
      return {
        ...prev,
        raisedDate: startDate,
        raisedDateIsoString: raisedDateIsoString,
      };
    });
  };

  const getData = async () => {
    setLoading(true);
    try {
      await setInitialDates();
      await getRegions();
      await getSiteIds();
      await getIssues();
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: serverError,
        })
      );
    }
    setLoading(false);
  };

  store.subscribe(() => {
    const { selectedCountry } = store.getState();
    setCurrentCountryCode(selectedCountry);
  });

  useEffect(() => {
    getData();
    setFilters((prev) => ({
      ...prev,
      country: currentCountryCode,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryCode]);

  const getRegions = async () => {
    const regions = await reportsService.fetchRegionsData({
      country: currentCountryCode,
    });
    const regionData = regions?.map((region) => ({
      id: region,
      text: region,
    }));
    const allOption = { id: "all", text: "All" };
    const allRegions =
      regionData?.length > 1 ? [allOption, ...regionData] : regionData;
    setFilters((prev) => ({
      ...prev,
      regions: allRegions,
      region: allRegions?.[0],
    }));
  };

  const getIssues = async (refresh = false) => {
    const filter = refresh ? appliedFilters : filters;
    if (!filter.raisedDate || !filter.closedDate) {
      const today = new Date();
      filter.closedDate = `${today.getFullYear()}-${(today.getMonth() + NUMBERS.ONE)
        .toString()
        .padStart(NUMBERS.TWO, "0")}-${today.getDate().toString().padStart(NUMBERS.TWO, "0")}`;
      today.setMonth(today.getMonth() - NUMBERS.THREE);
      filter.raisedDate = `${today.getFullYear()}-${(today.getMonth() + NUMBERS.ONE)
        .toString()
        .padStart(NUMBERS.TWO, "0")}-${today.getDate().toString().padStart(NUMBERS.TWO, "0")}`;
    }
    if (!refresh) {
      setAppliedFilters(filter);
    }
    setResetIndex(true);
    setFetching(true);
    try {
      let updatedIssues = await issueService.getAllIssues({
        ...filter,
        country: currentCountryCode,
      });

      updatedIssues = updatedIssues?.map((issue) => {
        const transactionDate = issue?.transactionDate
          ? getFormattedDate(new Date(issue?.transactionDate))
          : "";
        const raisedDate = issue?.raisedDate
          ? getFormattedDate(new Date(issue?.raisedDate))
          : "";

        return {
          ...issue,
          transactionDate: transactionDate,
          raisedDate: raisedDate,
        };
      });
      setIssueList(updatedIssues);
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: serverError,
        })
      );
    }

    setFetching(false);
  };

  const setRegion = async (e) => {
    const region = e?.target?.value;
    setFilters((prev) => {
      return { ...prev, region };
    });
    if (region === "All") {
      getSiteIds();
      return;
    }
    setFetching(true);
    const regionalSiteIds = await siteService.fetchSiteIdsBasedOnRegions({
      country: currentCountryCode,
      region: region?.text,
    });
    setFilters((prev) => {
      return {
        ...prev,
        allSiteIds: ["All", ...regionalSiteIds],
        selectedSiteId: regionalSiteIds?.[0],
        region,
      };
    });
    setFetching(false);
  };

  const setActionParty = (e) => {
    const actionParty = e?.target?.value;
    setFilters((prev) => {
      return { ...prev, actionParty };
    });
  };

  const setSiteId = (e) => {
    const siteId = e?.value?.text;
    setFilters((prev) => {
      return { ...prev, selectedSiteId: siteId };
    });
  };

  const setStatus = (e) => {
    const status = e?.target?.value;
    setFilters((prev) => {
      return { ...prev, status };
    });
  };

  const setRaisedDate = (e) => {
    const raisedDate = new Date(e?.value);
    const date = `${raisedDate.getFullYear()}-${(raisedDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${raisedDate.getDate().toString().padStart(2, "0")}`;
    const raisedDateIsoString = raisedDate.toISOString();
    setFilters((prev) => {
      return {
        ...prev,
        raisedDate: date,
        raisedDateIsoString: raisedDateIsoString,
      };
    });
  };

  const setClosedDate = (e) => {
    const closedDate = new Date(e?.value);
    const date = `${closedDate.getFullYear()}-${(closedDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${closedDate.getDate().toString().padStart(2, "0")}`;
    setFilters((prev) => {
      return {
        ...prev,
        closedDate: date,
        closedDateIsoString: closedDate.toISOString(),
      };
    });
  };

  const handleIssueUpdate = (issue) => {
    setIssueToUpdate(issue);
    setShowUpdateIssue(true);
  };

  const handleIssueAdd = () => {
    setShowAddIssue(true);
  };

  const headerButtons = () => {
    return (
      <div className="buttonsContainer">
        <div className="submitButtonContainer" />
        <div className="submitButtonContainer">
          <Button
            className="exportButton"
            onClick={exportData}
            disabled={exporting}
          >
            {exporting ? "Exporting..." : "Export"}
          </Button>
          <Button className="btn-primary" onClick={handleIssueAdd}>
            Add a new issue
          </Button>
        </div>
      </div>
    );
  };

  const exportData = async () => {
    setExporting(true);
    const payload = {
      region: filters.region,
      status: filters.status,
      startDate: filters.raisedDate,
      endDate: filters.closedDate,
      globalSiteId: filters.selectedSiteId,
      country: currentCountryCode,
      actionParty: filters.actionParty,
    };
    try {
      await issueService.downloadIssueExcel(payload);
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: serverError,
        })
      );
    }
    setExporting(false);
  };

  const filterHeader = () => {
    return (
      <div className="header-container">
        <span className="filters-container">
          {filters?.regions?.length > 1 && (
            <span className="filter">
              <label htmlFor="region">Region</label>
              <DropDownList
                data={filters?.regions}
                textField="text"
                dataItemKey="id"
                onChange={setRegion}
                value={filters.region}
                style={{
                  width: "7vw",
                  marginRight: "1vw",
                  fontSize: "10px",
                  height: "3vh",
                }}
              />
            </span>
          )}
          <span className="filter">
            <label htmlFor="region">Site ID</label>
            <SearchableDropdown
              data={filters?.allSiteIds}
              textField="text"
              dataItemKey="id"
              filterable={true}
              onSelect={setSiteId}
              value={{ text: filters?.selectedSiteId }}
              style={{
                width: "7vw",
                marginRight: "1vw",
                fontSize: "10px",
                height: "3vh",
              }}
            />
          </span>
          <span className="filter">
            <label htmlFor="region">Action Party</label>
            <DropDownList
              data={filters?.actionParties}
              textField="text"
              dataItemKey="id"
              onChange={setActionParty}
              value={filters.actionParty}
              style={{
                width: "7vw",
                marginRight: "1vw",
                fontSize: "10px",
                height: "3vh",
              }}
            />
          </span>
          <span className="filter">
            <label htmlFor="region">Status</label>
            <DropDownList
              filterable
              data={filters?.statusList}
              textField="text"
              dataItemKey="id"
              onChange={setStatus}
              value={filters.status}
              style={{
                width: "7vw",
                marginRight: "1vw",
                fontSize: "10px",
                height: "3vh",
              }}
            />
          </span>
          <span className="filter-date">
            <label htmlFor="region">From Date</label>
            <DatePicker
              format={"dd-MMM-yyyy"}
              value={new Date(filters.raisedDateIsoString)}
              onChange={setRaisedDate}
            />
          </span>
          <span className="filter-date">
            <label htmlFor="region">To Date</label>
            <DatePicker
              format={"dd-MMM-yyyy"}
              value={new Date(filters.closedDateIsoString)}
              onChange={setClosedDate}
            />
          </span>
          <span className="filter-button">
            <Button
              className="get-data-btn"
              onClick={() => {
                getIssues();
              }}
              disabled={loading}
            >
              {loading ? "Fetching..." : "Get data"}
            </Button>
          </span>
          <span className="filter-button">
            <Button
              className="refresh-btn"
              onClick={() => {
                getIssues(true);
              }}
              disabled={!appliedFilters}
            >
              Refresh
            </Button>
          </span>
        </span>
        {showAddIssue && (
          <AddIssueScreen
            issue={issueToUpdate}
            onClose={() => setShowAddIssue(false)}
            onSave={async () => {
              setShowAddIssue(false);
              await getIssues();
            }}
          />
        )}
      </div>
    );
  };

  const showUpdateScreen = () => {
    return (
      <UpdateIssueScreen
        issue={issueToUpdate}
        onClose={() => setShowUpdateIssue(false)}
        onSave={async () => {
          setShowUpdateIssue(false);
          await getIssues();
        }}
      />
    );
  };

  const handleShipToLinkAction = (siteId, country) => {
    setSelectedSite({ siteId: siteId, country: country });
    setShowSiteInfoModal(true);
  };

  const closeModal = () => {
    setShowSiteInfoModal(false);
  };

  const showListScreen = () => {
    return (
      <div className="issue-log-screen">
        {/* <h4 className="title">Issue Log Report</h4> */}
        {filterHeader()}
        {headerButtons()}
        {showSiteInfoModal && (
          <SiteInfoScreen
            globalSiteId={selectedSite?.siteId}
            countryCode={selectedSite?.country || currentCountryCode}
            close={closeModal}
          />
        )}
        {exporting && <LoadingPanel message="Exporting data.." />}
        {fetching && <LoadingPanel message="Fetching data.." />}
        <div className="row issue-logs-container">
          {loading ? (
            <LoadingPanel message="Loading please wait..." />
          ) : (
            <div>
              <AppDataGrid
                resetIndex={resetIndex}
                setResetIndex={setResetIndex}
                take={DEFAULT_PAGE_SIZE}
                style={{
                  height: "62vh",
                }}
                pageable={true}
                filterable={true}
                data={issues}
                columnsToShow={colsToShow(
                  issues,
                  handleIssueUpdate,
                  handleShipToLinkAction
                )}
                sortable={true}
                // rowHeight={30}
                // total={issues?.total}
                // scrollable={"virtual"}
                // pageSize={DEFAULT_INITIAL_PAGE_SIZE}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return showUpdateIssue ? showUpdateScreen() : showListScreen();
};
export { IssueListScreen };
