import React, { useEffect, useRef, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import "./site-information.scss";
import {
  DatePicker,
  DropDownList,
  Input,
  Loader,
} from "@progress/kendo-react-all";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { triggerNotification } from "../../../../../state-management/actions";
import { getFormattedDate } from "../../../../../services/utils";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useDispatch } from "react-redux";
import { TextArea } from "@progress/kendo-react-inputs";
import { SearchableDropdown } from "../../../../components/searchable-dropdown/searchable-dropdown";
import { siteService } from "../../../../../services/sites";
import ConfirmPopup from "../../../../components/confirmation-popup/confirmation-popup";
import { LabelField } from "./site-information-utils";
import { NUMBERS } from "../common-utils";

const SiteBasicDetails = (props) => {
  const siteItem = props?.selectedItem;
  const readOnly = props?.readOnly;
  const siteStatuses = props?.siteStatuses;
  const regionList = props?.regionList;
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [currentOffSet, setCurrentOffSet] = useState();
  const [showError, setShowError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const [cursorPosition, setCursorPosition] = useState(null);
  const [fields, setFields] = useState({
    regions: regionList,
    selectedRegion: siteItem?.Region,
    selectedPlant: siteItem?.Plant,
    selectedCity: siteItem?.City,
    selectedSloc: siteItem?.Sloc,
    status: siteStatuses,
    selectedStatus: siteItem?.SiteStatus,
    openingDate: new Date(),
    closingDate: null,
    operatingHours: siteItem?.OperatingHours,
    remarks: siteItem?.Remarks,
  });

  useEffect(() => {
    inputRef?.current?.focus();
    setTimeout(() => {
      if (inputRef?.current?.element?.setSelectionRange) {
        inputRef?.current?.element?.setSelectionRange(
          cursorPosition,
          cursorPosition
        );
      }
      else {
        inputRef?.current?.element?.current?.setSelectionRange(
          cursorPosition,
          cursorPosition
        );
      }

    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  useEffect(() => { }, [fields?.selectedShipTo]);

  const DropDownField = (propValues) => {
    const selectedField = `${propValues?.selectedField}`;
    const selectedValue = fields?.[selectedField];
    const data = fields?.[`${propValues?.field}`]?.map((item) => item);
    const filterable = propValues?.filterable;
    return (
      <div className="deliveryRow">
        <span className="deliveryLbl">
          {propValues?.label}
          {propValues?.mandatory && <span style={{ color: "red" }}>*</span>}
        </span>

        <span className="deliveryVal">
          {filterable ? (
            <SearchableDropdown
              data={data}
              textField="text"
              dataItemKey="id"
              filterable={true}
              disabled={readOnly}
              onSelect={(event) => {
                setSelectedItem(selectedField);
                setFields((prev) => {
                  return {
                    ...prev,
                    [selectedField]: event?.value?.text,
                  };
                });
              }}
              value={{ text: selectedValue }}
              style={{
                width: "15vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
            />
          ) : (
            <DropDownList
              data={data}
              disabled={readOnly}
              onChange={(event) => {
                setSelectedItem(selectedField);
                setFields((prev) => {
                  return {
                    ...prev,
                    [selectedField]: event?.value,
                  };
                });
              }}
              value={selectedValue}
              style={{
                width: "15vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
            />
          )}
        </span>
      </div>
    );
  };

  const DateField = (propValues) => {
    const selectedField = `${propValues?.selectedField}`;
    const selectedValue = fields?.[selectedField];
    const mandatory = propValues?.mandatory;

    const onDateChange = (e) => {
      const date = new Date(e?.value);
      setFields((prev) => {
        setSelectedItem(selectedField);
        return {
          ...prev,
          [selectedField]: date,
        };
      });
    };

    return (
      <div className="deliveryRow">
        <span className="dateLbl">
          {propValues?.label}
          {mandatory && <span style={{ color: "red" }}>*</span>}
        </span>
        <span className="dateVal">
          <DatePicker
            format={"dd-MMM-yyyy"}
            value={selectedValue}
            disabled={readOnly}
            onChange={onDateChange}
            placeholder=""
          />
        </span>
      </div>
    );
  };

  const TextInputField = (propValues) => {
    const selectedField = `${propValues?.selectedField}`;
    const selectedValue = fields?.[selectedField];
    const mandatory = propValues?.mandatory;

    const onChange = (event) => {
      const inputValue = event.target.value;
      const position = event.target.element?.selectionStart;
      setCursorPosition(position);
      setSelectedItem(selectedField);
      setFields((prev) => {
        return {
          ...prev,
          [selectedField]: inputValue,
        };
      });
    };

    const sameItem = selectedItem === selectedField;
    const errorClassName =
      showError && !(selectedValue?.length > 0) ? "errorClassName" : "";

    return (
      <div className="deliveryRow">
        <span className="inputLbl">
          {propValues?.label}
          {mandatory && <span style={{ color: "red" }}>*</span>}
        </span>
        <span className={`${errorClassName} inputVal`}>
          <Input
            ref={sameItem ? inputRef : null}
            disabled={readOnly}
            value={selectedValue}
            onChange={onChange}
            maxLength={10}
          />
        </span>
      </div>
    );
  };

  const TextAreaField = (propValues) => {
    const selectedField = `${propValues?.selectedField}`;
    const selectedValue = fields?.[selectedField];
    const sameItem = selectedItem === selectedField;
    const onChange = (e) => {
      const position = e.target.element.current.selectionStart;
      setCursorPosition(position);
      setSelectedItem(selectedField);
      setFields((prev) => {
        return {
          ...prev,
          [selectedField]: e.value,
        };
      });
    };


    return (
      <TextArea
        ref={sameItem ? inputRef : null}
        className="remarksTextArea"
        disabled={readOnly}
        onChange={onChange}
        maxLength={250}
        placeholder="Add remarks..."
        value={selectedValue}
      />
    );
  };

  const onTimeChange = (propValues) => {
    if (propValues?.type === "ignore") {
      setFields((prev) => {
        return {
          ...prev,
        };
      });
    } else {
      const date = propValues.target.value
        ? new Date(propValues?.target.value)
        : propValues?.defaultValue;
      setFields((prev) => {
        setSelectedItem(propValues?.selectedField);
        const hours = date?.getHours()?.toString()?.padStart(2, "0");
        const minutes = date?.getMinutes()?.toString()?.padStart(2, "0");
        const time = `${hours}:${minutes}`;
        return {
          ...prev,
          operatingHours: {
            ...fields?.operatingHours,
            [propValues.selectedField]: [{ ...propValues?.selectedValue, [propValues?.type]: time }],
          },
        };
      });
    }
  };

  const TimeField = (propValues) => {
    const defaultValue = new Date();
    defaultValue.setHours(0, 0, 0, 0);
    const selectedField = `${propValues?.selectedField}`;
    const selectedValue = fields?.operatingHours?.[selectedField]?.[0];
    const openTime = selectedValue?.Open;
    const openDate = new Date();
    const [openHours, openMinutes] =
      openTime?.split(":")?.map(Number) || [0, 0];

    // Set the hours and minutes
    openDate.setHours(openHours);
    openDate.setMinutes(openMinutes);

    const closeTime = selectedValue?.Close;
    const closeDate = new Date();
    const [closeHours, closeMinutes] =
      closeTime?.split(":")?.map(Number) || [0, 0];

    // Set the hours and minutes
    closeDate.setHours(closeHours);
    closeDate.setMinutes(closeMinutes);
    let error = openHours > closeHours;
    if (!error) {
      error = openHours === closeHours && openMinutes > closeMinutes;
    }

    return (
      <div className="openingHrsRow">
        <span className="dateLbl">{propValues?.label}</span>
        <span className="dateVal">
          <span className="timePicker">
            <TimePicker
              format="HH:mm"
              placeholder="HH:mm"
              defaultValue={defaultValue}
              disabled={readOnly}
              value={openDate}
              onChange={(e) => {
                if (e.nativeEvent.type === "click") {
                  onTimeChange({
                    ...e,
                    type: "Open",
                    defaultValue: defaultValue,
                    selectedField: selectedField,
                    selectedValue: selectedValue,
                  });
                } else if (!e.target.value) {
                  onTimeChange({
                    ...e,
                    type: "ignore",
                    defaultValue: defaultValue,
                    selectedField: selectedField,
                    selectedValue: selectedValue,
                  });
                }
              }}
            />
          </span>{" "}
          -
          <span className="timePicker">
            <TimePicker
              format="HH:mm"
              placeholder="HH:mm"
              defaultValue={defaultValue}
              disabled={readOnly}
              value={closeDate}
              onChange={(e) => {
                if (e.nativeEvent.type === "click") {
                  onTimeChange({
                    ...e,
                    type: "Close",
                    defaultValue: defaultValue,
                    selectedField: selectedField,
                    selectedValue: selectedValue,
                  });
                } else if (!e.target.value) {
                  onTimeChange({
                    ...e,
                    type: "ignore",
                    defaultValue: defaultValue,
                    selectedField: selectedField,
                    selectedValue: selectedValue,
                  });
                }
              }}
            />
          </span>
          {error && (
            <Tooltip openDelay={100} position="left" anchorElement="target">
              <img
                className="errorIcon"
                title="Opening hours should be less than the Closing hours."
                src={require("../../../../../assets/error-cross.png")}
                alt="Warning"
              />
            </Tooltip>
          )}
        </span>
      </div>
    );
  };

  const showHeader = () => {
    return (
      <div className="deliveryHeader">
        <div className="title">{`Site Details - ${siteItem?.GlobalSiteId}`}</div>
      </div>
    );
  };

  const showContent = () => {
    return (
      <div className="deliveryContent">
        <div className="leftContainer">
          <LabelField label="Site Name" value={siteItem?.Name} />
          <LabelField label="Ship To" value={siteItem?.GlobalSiteId} />
          <LabelField label="Site ID" value={siteItem?.SiteId} />
          <LabelField label="OBN" value={siteItem?.OBN} />
          <LabelField label="Country" value={siteItem?.PartitionKey} />

          <TextInputField label="City" selectedField="selectedCity" />
          <TextInputField
            label="Plant"
            selectedField="selectedPlant"
            mandatory
          />
          <TextInputField label="SLOC" selectedField="selectedSloc" mandatory />
          <DropDownField
            label="Region"
            field="regions"
            selectedField="selectedRegion"
            filterable
            mandatory
          />
          <DropDownField
            label="Status"
            field="status"
            selectedField="selectedStatus"
            mandatory
          />
          <DateField
            label="Opening Date"
            selectedField="openingDate"
            mandatory
          />
          {fields?.selectedStatus?.toLowerCase()?.includes("closed") && (
            <DateField
              label="Closing Date"
              selectedField="closingDate"
              mandatory
            />
          )}
        </div>
        <div className="rightContainer">
          <div className="openingHourTitle">Opening Hours:</div>
          {fields?.operatingHours ? (
            <div className="openingHourContainer">
              <TimeField label="Sunday" selectedField="Sun" />
              <TimeField label="Monday" selectedField="Mon" />
              <TimeField label="Tuesday" selectedField="Tue" />
              <TimeField label="Wednesday" selectedField="Wed" />
              <TimeField label="Thursday" selectedField="Thu" />
              <TimeField label="Friday" selectedField="Fri" />
              <TimeField label="Saturday" selectedField="Sat" />
            </div>
          ) : (
            <div className="openingHourNA">Not Available</div>
          )}
          <div className="remarksContainer">
            <TextAreaField
            />
          </div>
        </div>
      </div>
    );
  };

  const isValid = () => {
    const allValid =
      fields?.selectedPlant?.length > 0 && fields?.selectedSloc?.length > 0;

    setShowError(!allValid);
    return allValid;
  };

  const showSubmitButton = () => {
    return (
      <div className="submit-btn-container">
        {!readOnly && (
          <Button
            className="submit-btn"
            disabled={readOnly}
            onClick={(event) => {
              if (isValid()) {
                const x = event.clientX;
                const y = event.clientY;
                const offset = {
                  left: x,
                  top: y,
                };
                setCurrentOffSet(offset);
                setShowConfirmationPopup(true);
              }
            }}
          >
            {submitting ? "Submitting..." : "Save"}
          </Button>
        )}

        <Button
          className="close-btn"
          onClick={() => {
            props?.cancel();
          }}
        >
          Close
        </Button>
      </div>
    );
  };

  const createPayload = () => {
    const closingDate = fields?.closingDate
      ? getFormattedDate(fields?.closingDate)
      : "";
    const openingDate = fields?.openingDate
      ? getFormattedDate(fields?.openingDate)
      : "";
    const payload = {
      CountryCode: siteItem?.Country,
      GlobalSiteId: siteItem?.GlobalSiteId,
      IsRKSite: siteItem?.IsRKSite,
      Name: siteItem?.Name,
      PLANT: fields?.selectedPlant,
      PartitionKey: siteItem?.PartitionKey,
      Region: fields?.selectedRegion,
      SLOC: fields?.selectedSloc,
      SiteId: siteItem?.SiteId,
      SiteStatus: fields?.selectedStatus,
      OperatingHours: fields?.operatingHours,
      City: fields?.selectedCity,
      Remarks: fields?.remarks,
      SiteClosingDate: closingDate,
      SiteOpeningDate: openingDate,
      TankInfo: siteItem?.TankInfo,
    };

    return payload;
  };

  const saveChanges = async () => {
    setShowConfirmationPopup(false);
    setSubmitting(true);
    const payload = createPayload();
    const response = await siteService.updateSiteInfo(
      siteItem?.Country,
      siteItem?.GlobalSiteId,
      payload
    );
    if (response?.status >= NUMBERS.TWO_HUNDRED && response?.status < NUMBERS.THREE_HUNDRED) {
      dispatch(
        triggerNotification({
          type: {
            style: "success",
            icon: true,
          },
          message: response?.data?.message || response?.data,
        })
      );
      props?.save(payload);
    } else {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Failed to save site information!",
        })
      );
    }
    setSubmitting(false);
  };

  return (
    <div className="popupContainer">
      <div className="deliveryForm">
        {showHeader()}
        {showContent()}
        {showSubmitButton()}
        {showConfirmationPopup && (
          <ConfirmPopup
            offset={currentOffSet}
            position="top"
            isOpen={showConfirmationPopup}
            title={"Are you sure you want to update the site information?"}
            message={
              "This change would impact the existing report data and the report changes can’t be reverted."
            }
            saveAction={() => {
              if (isValid()) {
                saveChanges();
              }
            }}
            cancelAction={() => {
              setShowConfirmationPopup(false);
            }}
          />
        )}

        {submitting && (
          <div className="loaderContainer">
            <Loader
              size="medium"
              themeColor="warning"
              type="converging-spinner"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { SiteBasicDetails };
