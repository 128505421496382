// src/reducers/index.js

import loginReducer from "./loginReducer";
import reportReducer from "./reportReducer";

const initialState = {
    user: null,
    language: "english",
    selectedReport: null,
    siteData: null,
    userScreenList: [],
    refresh_token: null,
    access_token: null,
    id_token: null,
    notification: [],
  };

const combineReducersWithoutKeys = reducers => (state = initialState, action) => {
    return Object.keys(reducers).reduce((newState, key) => {
      const reducer = reducers[key];
      return reducer(newState, action);
    }, state);
  };

const rootReducer = combineReducersWithoutKeys({
  loginReducer,
  reportReducer,
});

export default rootReducer;
