import React, { useEffect, useRef, useState } from "react";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import "./account-for-deliveries.scss";
import { GridToolbar } from "@progress/kendo-react-grid";
import {
  auditColsToShow,
  colsToExport,
  colsToShow,
  defaultOption,
  getPlantValues,
  getSlocValues,
} from "./account-for-deliveries-utils";
import {
  getAllSelectedOptions,
  getLastDates,
  getMaterials,
  getFilterRegions,
  getShipTos,
  getTanks,
  getOBNs,
} from "../../reportUtils";
import {
  MultiSelectionDropDown,
  multiSelectionDropDownFields,
} from "../../../../components/multi-select-dropdown/multi-select-dropdown";
import { getMultiSelectTreeValue } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import { reportsService } from "../../../../../services/reports";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../../../../state-management/store";
import { triggerNotification } from "../../../../../state-management/actions";
import {
  error,
  getFormattedDate,
  getFormattedDateAndTime,
  getDaysBeforeDate,
} from "../../../../../services/utils";
import { AddDelivery } from "./add-delivery-screen";
import { SiteInfoScreen } from "../out-of-tolerance-trend-analysis/out-of-tolerance-site-info";
import {
  DEFAULT_PAGE_SIZE,
  EndDateInput,
  getSelectedCluster,
  NUMBERS,
  StartDateInput,
  isDachCluster,
} from "../common-utils";
import { DateRangePickerComponent } from "../../../../components/date-range-picker/date-range-picker";
import { SliderRangeComponent } from "../../../../components/slider-range/slider-range";
import { TabComponent } from "../../../../components/tab-component/tab-component";
import AFDDetailsController from "./account-for-deliveries-detail";

export const AFDTabOptions = {
  DELIVERY: 0,
  AUDIT: 1,
};

const DropDownSelection = {
  ShipTo: 0,
  OBN: 1,
};

const AccountForDeliveries = () => {
  const [originalData, setOriginalData] = useState([]);
  const [changedItems, setChangedItems] = useState([]);
  const [accountForDeliveriesData, setAccountForDeliveriesData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [exportExcelData, setExportExcelData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [commentsOptions, setCommentsOptions] = useState([]);
  const [masterDataLoading, setMasterDataLoading] = useState(true);
  const [changed, setChanged] = useState(false);
  const exportGridRef = useRef(null);
  const exportAuditGridRef = useRef(null);
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const defaultCountry = useSelector((state) => state?.selectedCountry);
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);
  const defaultCluster = useSelector((state) => state?.selectedCluster);
  const [currentCluster, setCurrentCluster] = useState(defaultCluster);
  const [resetIndex, setResetIndex] = useState(false);
  const [showAddDelivery, setShowAddDelivery] = useState(false);
  const inputRef = useRef(null);
  const [showSiteInfoModal, setShowSiteInfoModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState();
  const [selectedTab, setSelectedTab] = useState(AFDTabOptions.DELIVERY);
  const [sliderValue, setSliderValue] = useState({
    start: -1000,
    end: 1000,
  });
  const [currentField, setCurrentField] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const differenceVal = {
    min: -300000,
    max: 300000,
  };
  store.subscribe(() => {
    const { selectedCountry, selectedCluster } = store.getState();
    setCurrentCountryCode(selectedCountry);
    setCurrentCluster(selectedCluster);
  });

  const [filters, setFilters] = useState({
    selectedDates: {
      start: isDachCluster() ? getDaysBeforeDate(2) : getDaysBeforeDate(1),
      end: isDachCluster() ? getDaysBeforeDate(2) : getDaysBeforeDate(1),
    },
    dates: getLastDates(NUMBERS.FIVE, NUMBERS.ONE),
    selectedRegions: [],
    regions: getFilterRegions(filterData, false),
    selectedShipTo: [],
    shipTos: [],
    selectedOBN: [],
    allOBNs: [],
    selectedMaterials: [],
    materials: getMaterials(filterData),
    selectedSlocs: [],
    slocs: getSlocValues(filterData),
    selectedPlants: [],
    plants: [],
  });

  const [appliedFilters, setAppliedFilters] = useState(null);
  const [exporting, setExporting] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [masterDataCount, setMasterDataCount] = useState(0);

  const [dateSelectedValues, setDateSelectedValues] = useState(
    filters.selectedDates
  );

  const [regionSelectedValues, setRegionSelectedValues] = useState(
    filters.selectedRegions
  );

  const [shipToSelectedValues, setShipToSelectedValues] = useState(
    filters.selectedShipTo
  );

  const [obnSelectedValues, setObnSelectedValues] = useState(
    filters.selectedOBN
  );

  const [materialSelectedValues, setMaterialSelectedValues] = useState(
    filters.selectedMaterials
  );

  const [slocSelectedValues, setSlocSelectedValues] = useState(
    filters.selectedSlocs
  );

  const [plantSelectedValues, setPlantSelectedValues] = useState(
    filters.selectedPlants
  );

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedDates: {
        start: isDachCluster() ? getDaysBeforeDate(2) : getDaysBeforeDate(1),
        end: isDachCluster() ? getDaysBeforeDate(2) : getDaysBeforeDate(1),
      },
    }));
  }, [currentCluster]);

  const getAFDPayload = (filter) => {
    const regions = filter.selectedRegions?.map((region) => region?.text);
    const fromDate = filter.selectedDates.start;
    const toDate = filter.selectedDates.end;
    const startDate = fromDate && getFormattedDate(fromDate);
    const endDate = toDate && getFormattedDate(toDate);

    const currentCountries = filter.selectedRegions?.map(
      (region) => region?.countryCode
    );

    const countryCode = currentCountries?.[0];
    const payload = {
      startDate: startDate,
      endDate: endDate,
      countryCode: countryCode,
      regions,
      shipTos: filter.selectedShipTo?.map((shipTo) => shipTo.text),
      materialNos: filter.selectedMaterials?.map((material) => material.text),
      slocs: filter.selectedSlocs?.map((sloc) => sloc.text),
      plants: filter.selectedPlants?.map((plant) => plant.text),
    };
    return payload;
  };

  const handleAFDDeliveryResponse = (response) => {
    response?.sort((a, b) => a.materialNumber - b.materialNumber);
    response?.sort((a, b) => a.sloc - b.sloc);
    response?.sort((a, b) => a.shipTo - b.shipTo);

    const responseData = response?.map((item) => {
      const documentDate = item?.documentDate
        ? getFormattedDate(new Date(item?.documentDate))
        : "";
      const reportDate = item?.reportDate
        ? getFormattedDate(new Date(item?.reportDate))
        : "";
      const outBoundDeliveryId = item?.outBoundDeliveryId?.replace(",", "\n");
      return {
        ...item,
        documentDate: documentDate,
        reportDate: reportDate,
        outBoundDeliveryId: outBoundDeliveryId,
      };
    });

    setOriginalData(responseData ? responseData : []);
    setAccountForDeliveriesData(responseData ? responseData : []);
  };

  const handleAFDAuditResponse = (response) => {
    const responseData = response?.map((item) => {
      const reportDate = item?.reportDate
        ? getFormattedDate(new Date(item?.reportDate))
        : "";
      const outBoundDeliveryId = item?.outBoundDeliveryId?.replace(",", "\n");
      const isComment = item?.entity?.toLowerCase() === "comment";
      return {
        ...item,
        documentDate: item?.partitionKey,
        confirmedDelivery: isComment ? "" : item?.confimedDelivery,
        tankId: isComment ? "" : item?.tankId,
        outBoundDeliveryId: isComment ? "" : outBoundDeliveryId,
        reportDate: reportDate,
        modifiedOn: item?.modifiedOn
          ? getFormattedDateAndTime(new Date(item?.modifiedOn))
          : "",
      };
    });
    setAuditData(responseData);
  };

  const fetchAccountForDeliveriesReport = async (refresh = false) => {
    const filter = refresh ? appliedFilters : filters;
    setChanged(false);
    setChangedItems([]);
    setFetchingData(true);
    let payload = getAFDPayload(filter);

    if (selectedTab === AFDTabOptions.DELIVERY) {
      payload = {
        ...payload,
        LossDifference: sliderValue.start,
        GainDifference: sliderValue.end,
      };
      const response = await reportsService.fetchAccountForDeliveriesData(
        payload
      );
      handleAFDDeliveryResponse(response);
    } else {
      const response = await reportsService.fetchAFDAuditData(payload);

      handleAFDAuditResponse(response);
    }
    if (!refresh) {
      setAppliedFilters(filter);
    }

    setFetchingData(false);
  };

  const hasEmptyValues = () => {
    let emptySelection =
      filters?.selectedDates?.length === 0 ||
      filters?.selectedRegions?.length === 0 ||
      filters?.selectedShipTo?.length === 0 ||
      filters?.selectedMaterials?.length === 0;

    if (selectedTab === AFDTabOptions.DELIVERY && !emptySelection) {
      emptySelection =
        emptySelection ||
        filters?.selectedSlocs?.length === 0 ||
        filters?.selectedPlants?.length === 0;
    }

    return emptySelection;
  };

  const fetchMasterData = async () => {
    setMasterDataLoading(true);
    const masterData = await reportsService.fetchToleranceTrendMasterData({
      country: currentCountryCode,
    });
    setFilterData(masterData ? masterData : []);
    setMasterDataCount((prev) => prev + 1);
  };
  const fetchCommentsMasterData = async () => {
    setMasterDataLoading(true);
    const response = await reportsService.fetchCommentsMasterData({
      country: getSelectedCluster(),
    });
    const options = response?.map((option) => option?.categoryText);
    const optionsData = [defaultOption, ...options];
    setCommentsOptions(options ? optionsData : []);
    setMasterDataCount((prev) => prev + 1);
  };

  useEffect(() => {
    if (masterDataCount === 2) {
      setMasterDataCount(0);
      setMasterDataLoading(false);
    }
  }, [masterDataCount]);

  const updateExportData = () => {
    let data = [];
    accountForDeliveriesData?.forEach((item) => {
      const tankData = {
        plant: item?.plant,
        documentDate: item?.documentDate,
        shipTo: item?.shipTo,
        sloc: item?.sloc,
        materialNumber: item?.materialNumber,
        outBoundDeliveryId: item?.outBoundDeliveryId?.replace("\n", ", "),
        type: "Material Level",
        calculatedDelivery: item?.calculatedDelivery,
        confirmedDelivery: item?.confirmedDelivery,
        difference: item?.difference,
        comment: item?.comment,
      };
      data = [...data, tankData];
      item?.deliveries?.forEach((deliveryItem) => {
        const deliveryData = {
          plant: item?.plant,
          documentDate: item?.documentDate,
          shipTo: item?.shipTo,
          sloc: item?.sloc,
          materialNumber: item?.materialNumber,
          outBoundDeliveryId: deliveryItem?.outBoundDeliveryId,
          type: "Tank Level",
          calculatedDelivery: deliveryItem?.calculatedDelivery,
          confirmedDelivery: deliveryItem?.confirmedDelivery,
          difference: item?.difference,
          comment: item?.comment,
          deliveryStartDate:
            deliveryItem?.deliveryStartDate?.length > 0
              ? deliveryItem?.deliveryStartDate
              : null,
          startTemperature: deliveryItem?.startTemperature
            ? `${deliveryItem?.startTemperature}°C`
            : "",
          deliveryEndDate:
            deliveryItem?.deliveryEndDate?.length > 0
              ? deliveryItem?.deliveryEndDate
              : null,
          endTemperature: deliveryItem?.endTemperature
            ? `${deliveryItem?.endTemperature}°C`
            : "",
          tank: deliveryItem?.tank,
        };
        data = [...data, deliveryData];
      });
    });

    setExportExcelData(data);
  };

  useEffect(() => {
    updateExportData();
    inputRef?.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountForDeliveriesData]);

  useEffect(() => {
    fetchMasterData();
    fetchCommentsMasterData();
    setChanged(false);
    setAccountForDeliveriesData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryCode]);

  useEffect(() => {
    const regions = getFilterRegions(filterData, false, currentCountryCode);
    const selectedRegions = regions?.length > 0 ? [regions?.[0]] : [];
    setFilters({
      ...filters,
      regions,
      selectedRegions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    setDateSelectedValues(filters.selectedDates);
    setRegionSelectedValues(filters.selectedRegions);
    const shipTos =
      filters.selectedRegions?.length > 0
        ? getShipTos(filterData, filters.selectedRegions)
        : [];
    const selectedShipTo =
      filters.selectedRegions?.length > 0 && shipTos?.length > 0
        ? [shipTos?.[0]]
        : [];

    const obns =
      filters.selectedRegions?.length > 0
        ? getOBNs(filterData, filters.selectedRegions, true)
        : [];

    const selectedOBN =
      filters.selectedRegions?.length > 0 && obns?.length > 0
        ? [obns?.[0]]
        : [];

    setFilters({
      ...filters,
      shipTos,
      selectedShipTo: selectedShipTo,
      allOBNs: obns,
      selectedOBN,
    });
    setShipToSelectedValues(selectedShipTo);
    setObnSelectedValues(selectedOBN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedRegions]);

  useEffect(() => {
    setMaterialSelectedValues(filters.selectedMaterials);
  }, [filters.selectedMaterials]);

  useEffect(() => {
    setSlocSelectedValues(filters.selectedSlocs);
  }, [filters.selectedSlocs]);

  useEffect(() => {
    setPlantSelectedValues(filters.selectedPlants);
  }, [filters.selectedPlants]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedDates: dateSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedRegions: regionSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.ShipTo) {
      const siteData = filters?.shipTos?.find(
        (siteInfo) => siteInfo?.id === shipToSelectedValues?.[0]?.id
      );
      const obn = filters?.allOBNs?.find(
        (siteInfo) => siteInfo?.obn === siteData?.obn
      );
      const obns = obn && siteData ? [obn] : [];

      setFilters({
        ...filters,
        selectedShipTo: shipToSelectedValues ? shipToSelectedValues : [],
        selectedOBN: obns,
      });
      setObnSelectedValues(obns);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipToSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.OBN) {
      const siteData = filters?.allOBNs?.find(
        (siteInfo) => siteInfo?.id === obnSelectedValues?.[0]?.id
      );
      const site = filters?.shipTos?.find(
        (siteInfo) => siteInfo?.globalSiteId === siteData?.globalSiteId
      );
      const shipTo = site && siteData ? [site] : [];

      setFilters({
        ...filters,
        selectedShipTo: shipTo,
        selectedOBN: obnSelectedValues ? obnSelectedValues : [],
      });
      setShipToSelectedValues(shipTo);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obnSelectedValues]);

  useEffect(() => {
    const tanks =
      filters.selectedShipTo?.length > 0 ? getTanks(filterData, filters) : [];
    const selectedTanks = tanks?.length > 0 ? [tanks?.[0]] : [];
    const materials =
      filters.selectedShipTo?.length > 0
        ? getMaterials(filterData, filters)
        : [];
    const selectedMaterial = materials?.length > 0 ? [materials[0]] : [];
    const slocs = getSlocValues(filterData, filters);
    const selectedSlocs = slocs?.length > 0 ? [slocs[0]] : [];
    const plants = getPlantValues(filterData, filters);
    const selectedPlants = plants?.length > 0 ? [plants[0]] : [];

    setFilters({
      ...filters,
      materials,
      selectedMaterials: selectedMaterial,
      tanks,
      selectedTanks,
      slocs,
      selectedSlocs,
      plants,
      selectedPlants,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedShipTo]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedMaterials: materialSelectedValues,
    }));
    // loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedSlocs: slocSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slocSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedPlants: plantSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plantSelectedValues]);

  const handleDataChange = (e) => {
    const { dataItem } = e;
    const updatedItems = accountForDeliveriesData?.map((item, index) => {
      return index === dataItem.id ? e?.dataItem : item;
    });
    setAccountForDeliveriesData(updatedItems);

    const actualItem = originalData?.find(
      (item) => item.rowKey === dataItem.rowKey
    );

    const comment =
      actualItem.comment === defaultOption ? "" : actualItem.comment;
    const currentComment =
      dataItem.comment === defaultOption ? "" : dataItem.comment;

    const itemChanged =
      comment !== currentComment ||
      parseFloat(actualItem.confirmedDelivery) !==
        parseFloat(dataItem.confirmedDelivery) ||
      parseFloat(actualItem.outBoundDeliveryId) !==
        parseFloat(dataItem.outBoundDeliveryId);

    const onlyCommentUpdated =
      comment !== currentComment &&
      parseFloat(actualItem.confirmedDelivery) ===
        parseFloat(dataItem.confirmedDelivery);

    setChanged(itemChanged);
    if (itemChanged) {
      const sameItem = changedItems?.find(
        (item) => item.rowKey === dataItem.rowKey
      );

      if (sameItem) {
        const items = changedItems?.map((item) => {
          if (item.rowKey === dataItem.rowKey) {
            return dataItem;
          }
          return item;
        });
        setChangedItems(items);
      } else {
        setChangedItems([
          ...changedItems,
          { ...dataItem, onlyCommentUpdated: onlyCommentUpdated },
        ]);
      }
    } else {
      const items = changedItems?.filter(
        (item) => item.rowKey !== dataItem.rowKey
      );
      setChangedItems(items);
    }
  };

  const createPayload = () => {
    const { selectedCluster } = store.getState();
    return changedItems?.map((item) => {
      const comment = item.comment === defaultOption ? "" : item.comment;
      return {
        onlyCommentUpdated: item?.onlyCommentUpdated ? true : false,
        countryCode: item?.countryCode,
        rowKey: item.rowKey,
        shipTo: item?.shipTo,
        materialNumber: item.materialNumber,
        tankId: item?.tankId,
        outBoundDeliveryId: item?.outBoundDeliveryId,
        documentDate: item?.documentDate,
        reportDate: getFormattedDate(new Date()),
        calculatedDelivery: item.calculatedDelivery,
        confirmedDelivery: item?.confirmedDelivery,
        difference: item?.difference,
        Comment: comment,
        userName: store.getState()?.user?.UserEmail,
        market: selectedCluster,
      };
    });
  };

  const saveChanges = async () => {
    setSubmitting(true);
    const payload = createPayload();
    const response = await reportsService.updateAFDDelivery(payload);
    if (
      response?.status >= NUMBERS.TWO_HUNDRED &&
      response?.status < NUMBERS.THREE_HUNDRED
    ) {
      setChanged(false);
      setChangedItems([]);
      fetchAccountForDeliveriesReport();
      setSubmitting(false);
      dispatch(
        triggerNotification({
          type: {
            style: "success",
            icon: true,
          },
          message:
            response?.data?.message || "Deliveries updated successfully.",
        })
      );
    } else {
      dispatch(
        triggerNotification({
          type: {
            style: error,
            icon: true,
          },
          message: "Unable to submit the comments.",
        })
      );
    }
    setSubmitting(false);
  };

  const cancelChanges = async () => {
    setAccountForDeliveriesData(originalData);
    setChanged(false);
    setChangedItems([]);
  };

  const showHideLoaders = (show = false) => {
    setExporting(show);
  };
  const exportData = async () => {
    showHideLoaders(true);
    await cancelChanges();

    exportGridRef?.current?.exportDataInExcel &&
      (await exportGridRef.current.exportDataInExcel());

    setTimeout(showHideLoaders, 1000);
  };

  const exportAuditData = async () => {
    showHideLoaders(true);

    exportAuditGridRef?.current?.exportDataInExcel &&
      (await exportAuditGridRef.current.exportDataInExcel());

    setTimeout(showHideLoaders, 1000);
  };

  const addDelivery = () => {
    setShowAddDelivery(true);
  };

  const cancelDelivery = () => {
    setShowAddDelivery(false);
  };

  const saveDelivery = () => {
    setShowAddDelivery(false);
    fetchData();
  };

  const detailController = (propValues) => (
    <AFDDetailsController
      {...propValues}
      fetchAccountForDeliveriesReport={fetchAccountForDeliveriesReport}
    />
  );
  const showDeliveryView = () => {
    return (
      <span className="grid-container">
        <AppDataGrid
          resetIndex={resetIndex}
          setResetIndex={setResetIndex}
          take={DEFAULT_PAGE_SIZE}
          style={{
            height: "56vh",
            margin: "0 0.5vw 0.5vw 0.5vw",
          }}
          pageable={true}
          filterable={true}
          data={accountForDeliveriesData}
          expandable={true}
          detailsContainer={detailController}
          columnsToShow={colsToShow(commentsOptions, handleShipToLinkAction)}
          onItemChange={handleDataChange}
          sortable={true}
          ref={exportGridRef}
          exportFileName="AFD Report"
          exportData={exportExcelData}
          columnsToExport={colsToExport()}
          noRecordsMessage="No data found! Please try with different filter selections."
        />
      </span>
    );
  };

  const showChangeHistoryView = () => {
    return (
      <span className="grid-container">
        <AppDataGrid
          resetIndex={resetIndex}
          setResetIndex={setResetIndex}
          take={DEFAULT_PAGE_SIZE}
          style={{
            height: "59vh",
            margin: "0 0.5vw 0.5vw 0.5vw",
          }}
          pageable={true}
          filterable={true}
          data={auditData}
          expandable={false}
          columnsToShow={auditColsToShow(handleShipToLinkAction)}
          sortable={true}
          ref={exportAuditGridRef}
          exportFileName="AFD Audit Report"
          noRecordsMessage="No data found! Please try with different filter selections."
        />
      </span>
    );
  };
  const showTabContent = () => (
    <div>
      {showGridToolBar()}
      {selectedTab === AFDTabOptions.DELIVERY && showDeliveryView()}
      {selectedTab === AFDTabOptions.AUDIT && showChangeHistoryView()}
    </div>
  );

  const showTabComponent = () => {
    const items = [
      {
        label: "Delivery View",
        key: AFDTabOptions.DELIVERY,
      },
      {
        label: "Change History",
        key: AFDTabOptions.AUDIT,
      },
    ];
    return (
      <TabComponent
        items={items}
        activeIndex={selectedTab}
        onChange={(index) => {
          setSelectedTab(index);
        }}
      />
    );
  };

  const headerButtons = () => {
    const saveColor = changed && "#0092a5";
    const cancelColor = changed && "#fbce07";
    const borderWidth = changed && "0.15vw";
    return (
      <div className="buttonsContainer">
        {selectedTab === AFDTabOptions.DELIVERY ? (
          <div className="buttonsLeftContainer">
            <Button
              className="exportButton"
              onClick={exportData}
              disabled={
                exporting || !accountForDeliveriesData?.length || changed
              }
            >
              {exporting ? "Exporting..." : "Export"}
            </Button>
            <Button className="exportButton" onClick={addDelivery}>
              Add New Delivery
            </Button>
          </div>
        ) : (
          <div className="buttonsLeftContainer">
            <Button
              className="exportButton"
              onClick={exportAuditData}
              disabled={!auditData?.length}
            >
              {exporting ? "Exporting..." : "Export"}
            </Button>
          </div>
        )}
        <div className="submitButtonContainer">
          {selectedTab === AFDTabOptions.DELIVERY && (
            <>
              <button
                title="Save"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base toolbar-button"
                onClick={saveChanges}
                style={{ borderColor: saveColor, borderWidth: borderWidth }}
                disabled={!changed}
              >
                Save
              </button>
              <button
                title="Cancel"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base toolbar-button"
                onClick={cancelChanges}
                style={{ borderColor: cancelColor, borderWidth: borderWidth }}
                disabled={!changed}
              >
                Cancel
              </button>
            </>
          )}
        </div>
      </div>
    );
  };

  const onDateSelection = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedDates: event.value,
      };
    });
  };

  const onRegionSelection = (event) => {
    if (event.operation === "delete") {
      setRegionSelectedValues([]);
    } else {
      setRegionSelectedValues((prev) => {
        const selectedValues = getAllSelectedOptions(event, prev);
        return getMultiSelectTreeValue(filters?.regions, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        });
      });
    }
  };

  const onShipToSelection = (event) => {
    setCurrentField(DropDownSelection.ShipTo);
    if (event.operation === "delete") {
      setShipToSelectedValues([]);
    } else {
      setShipToSelectedValues((prev) => {
        const selectedValues = getAllSelectedOptions(event, prev);
        return getMultiSelectTreeValue(filters?.shipTos, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        });
      });
    }
  };

  const onOBNSelection = (event) => {
    setCurrentField(DropDownSelection.OBN);
    if (event.operation === "delete") {
      setObnSelectedValues([]);
    } else {
      setObnSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.allOBNs, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onMaterialSelection = (event) => {
    if (event.operation === "delete") {
      setMaterialSelectedValues([]);
    } else {
      setMaterialSelectedValues((prev) => {
        const selectedValues = getAllSelectedOptions(event, prev);
        return getMultiSelectTreeValue(filters?.materials, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        });
      });
    }
  };

  const onSlocSelection = (event) => {
    if (event.operation === "delete") {
      setSlocSelectedValues([]);
    } else {
      setSlocSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.slocs, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onPlantSelection = (event) => {
    if (event.operation === "delete") {
      setPlantSelectedValues([]);
    } else {
      setPlantSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.plants, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const fetchData = () => {
    const canShowError = hasEmptyValues();

    setShowError(canShowError);
    if (!canShowError) {
      fetchAccountForDeliveriesReport();
      setResetIndex(true);
    }
  };

  const startDateInput = (propValues) => (
    <StartDateInput {...propValues} showError={showError} />
  );

  const endDateInput = (propValues) => (
    <EndDateInput {...propValues} showError={showError} />
  );

  const headerFilters = () => {
    return (
      <div className="filters-container">
        <div className="filter-date">
          <DateRangePickerComponent
            placeholder="Select date range..."
            defaultValue={filters.selectedDates}
            value={filters.selectedDates}
            startDateInput={startDateInput}
            endDateInput={endDateInput}
            onChange={onDateSelection}
            max={isDachCluster() ? getDaysBeforeDate(2) : getDaysBeforeDate(1)}
          />
        </div>
        {filters.regions?.length > 1 && (
          <span className="filter">
            <label htmlFor="region">Region</label>
            <MultiSelectionDropDown
              data={filters.regions}
              values={regionSelectedValues}
              onChange={onRegionSelection}
              filterable={false}
              showError={showError && !masterDataLoading}
              customStyle={{
                width: "7.5vw",
                minHeight: "3vh",
              }}
              maxSelection={1}
            />
          </span>
        )}

        <span className="filter">
          <label htmlFor="shipTo">OBN</label>
          <MultiSelectionDropDown
            data={filters.allOBNs}
            values={obnSelectedValues}
            onChange={onOBNSelection}
            filterable={true}
            showError={showError}
            customStyle={{ width: "5.2vw", minHeight: "3vh" }}
            maxSelection={1}
          />
        </span>

        <span className="filter">
          <label htmlFor="shipTo">Ship To</label>
          <MultiSelectionDropDown
            data={filters.shipTos}
            values={shipToSelectedValues}
            onChange={onShipToSelection}
            filterable={true}
            showError={showError && !masterDataLoading}
            customStyle={{ width: "6.5vw", minHeight: "3vh" }}
          />
        </span>
        <span className="filter">
          <label htmlFor="material">Material No</label>
          <MultiSelectionDropDown
            data={filters.materials}
            values={materialSelectedValues}
            onChange={onMaterialSelection}
            filterable={true}
            showError={showError && !masterDataLoading}
            customStyle={{ width: "6.5vw", minHeight: "3vh" }}
          />
        </span>

        {selectedTab === AFDTabOptions.DELIVERY && (
          <>
            <span className="filter">
              <label htmlFor="SLOC">SLOC</label>
              <MultiSelectionDropDown
                data={filters.slocs}
                values={slocSelectedValues}
                onChange={onSlocSelection}
                filterable={false}
                showError={showError && !masterDataLoading}
                customStyle={{
                  width: "6.0vw",
                  minHeight: "3vh",
                }}
              />
            </span>
            <span className="filter">
              <label htmlFor="Plant">Plant</label>
              <MultiSelectionDropDown
                data={filters.plants}
                values={plantSelectedValues}
                onChange={onPlantSelection}
                filterable={false}
                showError={showError && !masterDataLoading}
                customStyle={{
                  width: "5vw",
                  minHeight: "3vh",
                }}
              />
            </span>
            <SliderRangeComponent
              label={"Threshold"}
              sliderValue={sliderValue}
              differenceVal={differenceVal}
              setSliderValue={setSliderValue}
            />
          </>
        )}

        <Button
          className={
            selectedTab === AFDTabOptions.DELIVERY
              ? "get-data-btn-delivery"
              : "get-data-btn-change"
          }
          onClick={fetchData}
          disabled={fetchingData}
        >
          {fetchingData ? "Fetching..." : "Get data"}
        </Button>
        <Button
          className={
            selectedTab === AFDTabOptions.DELIVERY
              ? "refresh-btn-delivery"
              : "refresh-btn-change"
          }
          onClick={() => {
            fetchAccountForDeliveriesReport(true);
          }}
          disabled={!appliedFilters}
        >
          Refresh
        </Button>
      </div>
    );
  };

  const showGridToolBar = () => {
    return (
      <GridToolbar>
        <div className="gridToolbar">
          {headerFilters()}
          <div className="rowContainer">{headerButtons()}</div>
        </div>
      </GridToolbar>
    );
  };

  const handleShipToLinkAction = (siteId, country) => {
    setSelectedSite({ siteId: siteId, country: country });
    setShowSiteInfoModal(true);
  };

  const closeModal = () => {
    setShowSiteInfoModal(false);
  };
  return (
    <>
      <div className="account-for-deliveries-screen-container data-grid">
        <div className="row ">
          {showTabComponent()}
          <div className="data-grid-container">
            {showTabContent()}
            {showSiteInfoModal && (
              <SiteInfoScreen
                globalSiteId={selectedSite?.siteId}
                countryCode={selectedSite?.country || currentCountryCode}
                close={closeModal}
              />
            )}

            {masterDataLoading && (
              <LoadingPanel message="Fetching the config data.. please wait.." />
            )}
            {exporting && (
              <LoadingPanel message="Exporting the data.. please wait.." />
            )}
            {fetchingData && (
              <LoadingPanel message="Fetching the data.. please wait.." />
            )}
            {submitting && (
              <LoadingPanel message="Updating Deliveries.. please wait.." />
            )}
          </div>
        </div>
        {showAddDelivery && (
          <AddDelivery
            filters={filters}
            filterData={filterData}
            commentOptions={commentsOptions}
            cancelDelivery={cancelDelivery}
            saveDelivery={saveDelivery}
          />
        )}
      </div>
    </>
  );
};
export { AccountForDeliveries };
