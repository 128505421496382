import "./eod-summary.scss";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { triggerNotification } from "../../../../../state-management/actions";
import { reportsService } from "../../../../../services/reports";
import { Button } from "@progress/kendo-react-all";
import { getMultiSelectTreeValue } from "@progress/kendo-react-dropdowns";
import { store } from "../../../../../state-management/store";
import { colsToShow, EODViewOptions } from "./eod-summary-utils";
import {
  MultiSelectionDropDown,
  multiSelectionDropDownFields,
} from "../../../../components/multi-select-dropdown/multi-select-dropdown";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import {
  getFormattedDate,
  serverError,
  unableToFetch,
  getDaysBeforeDate,
} from "../../../../../services/utils";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { SiteInfoScreen } from "../out-of-tolerance-trend-analysis/out-of-tolerance-site-info";
import {
  getAllSelectedOptions,
  getFilterRegions,
  getMaterials,
  getShipTos,
  getSiteNames,
  getTankList,
} from "../../reportUtils";
import AlertPopup from "../../../../components/alert-popup/alert-popup";
import {
  DEFAULT_PAGE_SIZE,
  EndDateInput,
  getSelectedCluster,
  isBFXCluster,
  isDachCluster,
  NUMBERS,
  StartDateInput,
} from "../common-utils";
import { DateRangePickerComponent } from "../../../../components/date-range-picker/date-range-picker";
import { TabComponent } from "../../../../components/tab-component/tab-component";

export const LeakTabOptions = {
  TANK_LEAKS: 0,
  DAILY_REPORT: 1,
  MINS_REPORT: 2,
};

const DropDownSelection = {
  ShipTo: 0,
  SiteName: 1,
};

const EODSummaryScreen = () => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [firstLoad, setFirstLoad] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [resetIndex, setResetIndex] = useState(false);
  const [currentField, setCurrentField] = useState(null);
  const [showSiteInfoModal, setShowSiteInfoModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState();
  const [masterData, setMasterData] = useState();

  const [filters, setFilters] = useState({
    selectedShipTo: null,
    selectedRegions: [],
    regions: getFilterRegions(masterData, false),
    selectedSiteName: null,
    selectedProducts: [],
    products: [],
    selectedTanks: [],
    tankGroup: [],
    selectedDates: {
      start: isDachCluster() ? getDaysBeforeDate(2) : getDaysBeforeDate(1),
      end: isDachCluster() ? getDaysBeforeDate(2) : getDaysBeforeDate(1),
    },
    prevSelectedDates: {
      start: null,
      end: null,
    },
    allSiteIds: [],
    allSiteNames: [],
  });
  const [eodSummaryData, setEodSummaryData] = useState([]);
  const [showError, setShowError] = useState(false);
  const [changed, setChanged] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [changedItems, setChangedItems] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [exporting, setExporting] = useState(false);
  const exportGridRef = useRef(null);
  const [error, setError] = useState("");
  const [filterDataLoading, setFilterDataLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const defaultCountry = useSelector((state) => state?.selectedCountry);
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);
  const defaultCluster = useSelector((state) => state?.selectedCluster);
  const [currentCluster, setCurrentCluster] = useState(defaultCluster);
  const [selectedTab, setSelectedTab] = useState(EODViewOptions.TANK);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [regionSelectedValues, setRegionSelectedValues] = useState(
    filters.selectedRegions
  );

  store.subscribe(() => {
    const { selectedCountry, selectedCluster } = store.getState();
    setCurrentCountryCode(selectedCountry);
    setCurrentCluster(selectedCluster);
  });

  useEffect(() => {
    fetchMasterData();
    setChanged(false);
    setDataUpdated(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryCode]);

  const [shipToSelectedValues, setShipToSelectedValues] = useState(
    filters.selectedShipTo
  );

  const [siteNameSelectedValues, setSiteNameSelectedValues] = useState(
    filters.selectedSiteName
  );

  const [productSelectedValues, setProductSelectedValues] = useState(
    filters?.selectedProducts
  );
  const [tankSelectedValues, setTankSelectedValues] = useState(
    filters?.selectedTanks
  );

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedDates: {
        start: isDachCluster() ? getDaysBeforeDate(2) : getDaysBeforeDate(1),
        end: isDachCluster() ? getDaysBeforeDate(2) : getDaysBeforeDate(1),
      },
    }));
  }, [currentCluster]);

  useEffect(() => {
    const regions = getFilterRegions(masterData, false, currentCountryCode);
    const selectedRegions = regions?.length > 0 ? [regions?.[0]] : [];

    setFilters((prev) => {
      return {
        ...prev,
        selectedRegions: selectedRegions,
        regions: regions,
      };
    });
    setRegionSelectedValues(selectedRegions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterData]);

  useEffect(() => {
    setEodSummaryData([]);
    setChanged(false);
    setDataUpdated(false);
    setChangedItems([]);
    fetchEodSummaryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedRegions: regionSelectedValues,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionSelectedValues]);

  useEffect(() => {
    const siteIds =
      filters.selectedRegions?.length > 0
        ? getShipTos(masterData, filters.selectedRegions)
        : [];

    if (siteIds?.length) {
      setShipToSelectedValues([siteIds?.[0]]);
    } else {
      setShipToSelectedValues([]);
    }

    const selectedShipTo =
      filters.selectedRegions?.length > 0 && siteIds?.length > 0
        ? [siteIds?.[0]]
        : [];

    const siteNames =
      filters.selectedRegions?.length > 0
        ? getSiteNames(masterData, filters.selectedRegions)
        : [];

    const selectedSiteName = siteNames?.length > 0 ? [siteNames?.[0]] : [];
    setFilters((prev) => {
      return {
        ...prev,
        selectedShipTo: selectedShipTo,
        selectedSiteName: selectedSiteName,
        allSiteIds: siteIds,
        allSiteNames: siteNames,
      };
    });
    setSiteNameSelectedValues(selectedSiteName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedRegions]);

  useEffect(() => {
    const setSiteNameSelection = () => {
      if (filters?.selectedShipTo?.id === "all") {
        const site = filters?.allSiteNames?.find((siteInfo) => siteInfo?.id === "all");
        setSiteNameSelectedValues([site]);
      } else {
        const site = filters?.allSiteNames?.find(
          (siteInfo) => siteInfo?.globalSiteId === filters?.selectedShipTo
        );
        if (site) {
          setSiteNameSelectedValues([site]);
        }
      }
    };
    if (currentField !== DropDownSelection.SiteName) {
      if (filters?.selectedShipTo) {
        setSiteNameSelection();
      } else {
        setSiteNameSelectedValues([]);
      }

      const productsData = getMaterials(masterData, filters, true);
      const selectedProducts =
        productsData?.length > 0 ? [productsData[0]] : [];

      setFilters((prev) => {
        return {
          ...prev,
          products: productsData,
          selectedProducts: selectedProducts,
        };
      });
      if (productsData?.length) {
        setProductSelectedValues([productsData[0]]);
      } else {
        setProductSelectedValues([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.selectedShipTo]);

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedProducts: productSelectedValues,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSelectedValues]);

  useEffect(() => {
    const tankGroupsData = getTankList(masterData, filters);
    const selectedTanks = tankGroupsData?.length > 0 ? [tankGroupsData[0]] : [];
    setFilters((prev) => {
      return {
        ...prev,
        tankGroup: tankGroupsData,
        selectedTanks: selectedTanks,
      };
    });
    if (tankGroupsData?.length) {
      setTankSelectedValues([tankGroupsData[0]]);
    } else {
      setTankSelectedValues([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedProducts]);

  useEffect(() => {
    if (!firstLoad && filters.selectedTanks?.length > 0) {
      fetchEodSummaryData();
      setFirstLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedTanks]);

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedTanks: tankSelectedValues,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tankSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.ShipTo) {
      const siteData = filters?.allSiteIds?.find(
        (siteInfo) => siteInfo?.id === shipToSelectedValues?.[0]?.id
      );

      const site = filters?.allSiteNames?.find(
        (siteInfo) => siteInfo?.siteName === siteData?.siteName
      );
      const siteNames = site && siteData ? [site] : [];
      setFilters((prev) => {
        return {
          ...prev,
          selectedShipTo: shipToSelectedValues ? shipToSelectedValues : [],
          selectedSiteName: siteNames,
        };
      });
      setSiteNameSelectedValues(siteNames);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipToSelectedValues]);

  useEffect(() => {
    const setShipToSelection = () => {
      if (filters?.selectedSiteName?.[0]?.id === "all") {
        const site = filters?.allSiteIds?.find((siteInfo) => siteInfo?.id === "all");
        setShipToSelectedValues([site]);
      } else {
        const site = filters?.allSiteIds?.find(
          (siteInfo) => siteInfo?.siteName === filters?.selectedSiteName
        );
        if (site) {
          setShipToSelectedValues([site]);
        }
      }
    };
    if (currentField === DropDownSelection.SiteName) {
      if (filters?.selectedSiteName) {
        setShipToSelection();
      } else {
        setShipToSelectedValues([]);
      }

      const productsData = getMaterials(masterData, filters, true);
      const selectedProducts =
        productsData?.length > 0 ? [productsData[0]] : [];

      setFilters((prev) => {
        return {
          ...prev,
          products: productsData,
          selectedProducts: selectedProducts,
        };
      });
      if (productsData?.length) {
        setProductSelectedValues([productsData[0]]);
      } else {
        setProductSelectedValues([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.selectedSiteName]);

  useEffect(() => {
    if (currentField === DropDownSelection.SiteName) {
      const siteData = filters?.allSiteNames?.find(
        (siteInfo) => siteInfo?.id === siteNameSelectedValues?.[0]?.id
      );
      const site = filters?.allSiteIds?.find(
        (siteInfo) => siteInfo?.globalSiteId === siteData?.globalSiteId
      );
      const selectedShipTo = site && siteData ? [site] : [];
      setFilters((prev) => {
        return {
          ...prev,
          selectedShipTo: selectedShipTo,
          selectedSiteName: siteNameSelectedValues,
        };
      });

      setShipToSelectedValues(selectedShipTo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteNameSelectedValues]);

  const onShipToSelection = (event) => {
    setCurrentField(DropDownSelection.ShipTo);
    if (event.operation === "delete") {
      setShipToSelectedValues([]);
    } else {
      setShipToSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.allSiteIds, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onSiteNameSelection = (event) => {
    setCurrentField(DropDownSelection.SiteName);
    if (event.operation === "delete") {
      setSiteNameSelectedValues([]);
    } else {
      setSiteNameSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.allSiteNames, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onProductSelection = (event) => {
    setCurrentField(DropDownSelection.SiteName);
    if (event.operation === "delete") {
      setProductSelectedValues([]);
    } else {
      setProductSelectedValues((prev) => {
        const selectedValues = getAllSelectedOptions(event, prev);
        return getMultiSelectTreeValue(filters?.products, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        });
      });
    }
  };

  const onTankSelection = (event) => {
    if (event.operation === "delete") {
      setTankSelectedValues([]);
    } else {
      setTankSelectedValues((prev) => {
        const selectedValues = getAllSelectedOptions(event, prev);
        return getMultiSelectTreeValue(filters?.tankGroup, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        });
      });
    }
  };

  const onDateSelection = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedDates: event.value,
      };
    });
  };

  const fetchMasterData = async () => {
    try {
      setEodSummaryData([]);
      setShowError(false);
      setFilterDataLoading(true);
      const response = await reportsService.fetchToleranceTrendMasterData({
        country: currentCountryCode,
      });
      setFilterDataLoading(false);
      if (response) {
        setMasterData(response);
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: unableToFetch,
          })
        );
      }
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: serverError,
        })
      );
      setError(serverError);
      setFilterDataLoading(false);
      setError("");
    }
  };

  const onRegionSelection = (event) => {
    if (event.operation === "delete") {
      setRegionSelectedValues([]);
    } else {
      setRegionSelectedValues((prev) => {
        const selectedValues = getAllSelectedOptions(event, prev);
        return getMultiSelectTreeValue(filters?.regions, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        });
      });
    }
  };

  const showHideLoaders = (show = false) => {
    setExporting(show);
  };
  const exportData = async () => {
    showHideLoaders(true);
    await cancelChanges();
    exportGridRef?.current?.exportDataInExcel &&
      (await exportGridRef.current.exportDataInExcel());

    setTimeout(showHideLoaders, 1000);
  };

  const validateResponse = (responseData) => {
    const response = responseData?.map((item) => {
      const eodStock = parseInt(item?.eodStock);
      const stockAt1145PM = parseInt(item?.stockAt1145PM);
      const stockDiff = parseInt(item?.stockDiff);
      const eodSales = parseInt(item?.eodSales);
      const cummSalesBy15min = parseInt(item?.cummSalesBy15min);
      const salesDiff = parseInt(item?.salesDiff);
      const eodDeliveries = parseInt(item?.eodDeliveries);
      const cummDeliveriesBy15min = parseInt(item?.cummDeliveriesBy15min);
      const deliveryDiff = parseInt(item?.deliveryDiff);
      const eodVariance = parseInt(item?.eodVariance);
      const eodStockOld = parseInt(item?.eodStockOld);
      const eodSalesOld = parseInt(item?.eodSalesOld);
      const eodDeliveryOld = parseInt(item?.eodDeliveryOld);
      const totalTestVolume = parseInt(item?.totalTestVolume);
      const hasTestSales = totalTestVolume > 0 ? "Y" : "N";

      return {
        ...item,
        eodStock: !Number.isNaN(eodStock) ? eodStock : "",
        eodStockOld: !Number.isNaN(eodStockOld) ? eodStockOld : "",
        stockAt1145PM: !Number.isNaN(stockAt1145PM) ? stockAt1145PM : "",
        stockDiff: !Number.isNaN(stockDiff) ? stockDiff : "",
        eodSales: !Number.isNaN(eodSales) ? eodSales : "",
        eodSalesOld: !Number.isNaN(eodSalesOld) ? eodSalesOld : "",
        cummSalesBy15min: !Number.isNaN(cummSalesBy15min)
          ? cummSalesBy15min
          : "",
        salesDiff: !Number.isNaN(salesDiff) ? salesDiff : "",
        eodDeliveries: !Number.isNaN(eodDeliveries) ? eodDeliveries : "",
        eodDeliveryOld: !Number.isNaN(eodDeliveryOld) ? eodDeliveryOld : "",
        cummDeliveriesBy15min: !Number.isNaN(cummDeliveriesBy15min)
          ? cummDeliveriesBy15min
          : "",
        deliveryDiff: !Number.isNaN(deliveryDiff) ? deliveryDiff : "",
        eodVariance: !Number.isNaN(eodVariance) ? eodVariance : "",
        totalTestVolume: !Number.isNaN(totalTestVolume) ? totalTestVolume : "",
        hasTestSales: hasTestSales,
      };
    });
    return response;
  };

  const fetchEodSummaryData = async (refresh = false) => {
    const filter = refresh ? appliedFilters : filters;
    const fromDate = filter.selectedDates.start;
    const toDate = filter.selectedDates.end;
    const selectedShipTo =
      filter?.selectedShipTo?.[0].id === "all"
        ? "ALL"
        : filter?.selectedShipTo?.[0].id;

    const selectedRegions = filter?.selectedRegions?.map((region) =>
      region?.id === "all" ? "ALL" : region?.id
    );
    const selectedTankIds = filter?.selectedTanks?.map((tank) =>
      tank?.id === "all" ? "ALL" : tank?.id
    );
    const selectedProducts = filter?.selectedProducts?.map((product) =>
      product?.id === "all" ? "ALL" : product?.text
    );
    const filteredProducts =
      selectedProducts?.length > 0 ? selectedProducts : ["ALL"];
    const filteredTanks =
      selectedTankIds?.length > 0 ? selectedTankIds : ["ALL"];
    const dateSelected = fromDate && toDate;
    const filterSelected =
      filter?.selectedShipTo && filteredTanks && filteredProducts;

    let valuesSelected = dateSelected && getSelectedCluster() && filterSelected;

    if (selectedTab === EODViewOptions.AUDIT) {
      valuesSelected = selectedShipTo && selectedRegions;
    }

    if (valuesSelected) {
      const startDate = fromDate && getFormattedDate(fromDate);
      const endDate = toDate && getFormattedDate(toDate);

      const payload =
        selectedTab === EODViewOptions.AUDIT
          ? {
            shipTo: selectedShipTo,
            Country: currentCountryCode,
          }
          : {
            Country: currentCountryCode,
            RequestDateFrom: startDate,
            RequestDateTo: endDate,
            GlobalSiteIds: [selectedShipTo],
            TankGroups: filteredTanks,
            Materials: filteredProducts,
          };
      setShowError(false);
      setSubmitLoading(true);
      if (!refresh) {
        setAppliedFilters(filter);
      }
      try {
        let response = [];
        if (selectedTab === EODViewOptions.AUDIT) {
          response = await reportsService.fetchEodAuditData(payload);
        } else {
          const endPoint = isBFXCluster() ? "GetEODData" : "GetEODDataDACH";
          response = await reportsService.fetchEodSummaryData(
            payload,
            endPoint
          );
        }
        response.sort((a, b) => new Date(b.date) - new Date(a.date));
        response = validateResponse(response);
        setSubmitLoading(false);
        setEodSummaryData(response);
        setOriginalData(response);
      } catch (error1) {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: serverError,
          })
        );
        setError(serverError);
        setSubmitLoading(false);
        setError("");
      }
    } else {
      setShowError(true);
    }
  };

  const showGridDataView = () => (
    <AppDataGrid
      resetIndex={resetIndex}
      setResetIndex={setResetIndex}
      take={DEFAULT_PAGE_SIZE}
      style={{ height: "59vh", margin: "0 0.5vw 0.5vw 0.5vw" }}
      pageable={true}
      filterable={true}
      data={eodSummaryData}
      columnsToShow={colsToShow(
        eodSummaryData,
        inputRef,
        handleDataChange,
        selectedItem,
        selectedTab,
        handleShipToLinkAction
      )}
      onItemChange={handleDataChange}
      sortable={true}
      ref={exportGridRef}
      exportFileName="EOD Summary"
    />
  );

  const showTabContent = () => (
    <div>
      {showHeaderFilters()}
      {showHeaderButtons()}
      {showGridDataView()}
    </div>
  );

  const showTabComponent = () => {
    const items = [
      {
        label: "Tank View",
        key: EODViewOptions.TANK,
      },
      {
        label: "Change History",
        key: EODViewOptions.AUDIT,
      },
    ];
    return (
      <TabComponent
        items={items}
        activeIndex={selectedTab}
        onChange={(index) => {
          if (changed) {
            setIsPopupOpen(true);
          } else {
            setSelectedTab(index);
          }
        }}
      />
    );
  };

  const startDateInput = propValues => (
    <StartDateInput {...propValues} showError={showError} />
  );

  const endDateInput = propValues => (
    <EndDateInput {...propValues} showError={showError} />
  );

  const showHeaderFilters = () => {
    return (
      <div className="header-container">
        <div className="filters-container">
          {selectedTab === EODViewOptions.TANK && (
            <span className="filter-date">
              <DateRangePickerComponent
                defaultValue={filters.selectedDates}
                value={filters.selectedDates}
                startDateInput={startDateInput}
                endDateInput={endDateInput}
                onChange={onDateSelection}
                max={isDachCluster() ? getDaysBeforeDate(2) : getDaysBeforeDate(1)}
              />
            </span>
          )}
          {filters.regions?.length > 1 && (
            <span className="filter">
              <label htmlFor="region">Region</label>
              <MultiSelectionDropDown
                data={filters.regions}
                values={regionSelectedValues}
                onChange={onRegionSelection}
                maxSelection={1}
                filterable={false}
                showError={showError || !filterDataLoading}
                customStyle={{
                  width: isDachCluster() ? "9vw" : "7vw",
                  minHeight: "3vh",
                }}
              />
            </span>
          )}

          <span className="filter">
            <label htmlFor="shipTo">Site ID</label>
            <MultiSelectionDropDown
              data={filters.allSiteIds}
              values={shipToSelectedValues}
              onChange={onShipToSelection}
              filterable={true}
              showError={showError || !filterDataLoading}
              customStyle={{ width: "7vw", minHeight: "3vh" }}
              maxSelection={1}
            />
          </span>
          <span className="filter">
            <label htmlFor="shipTo">Site Name</label>
            <MultiSelectionDropDown
              data={filters.allSiteNames}
              values={siteNameSelectedValues}
              onChange={onSiteNameSelection}
              filterable={true}
              showError={showError || !filterDataLoading}
              customStyle={{ width: "10vw", minHeight: "3vh" }}
              maxSelection={1}
            />
          </span>
          {selectedTab === EODViewOptions.TANK && (
            <span className="filter">
              <label htmlFor="region">Product</label>
              <MultiSelectionDropDown
                data={filters.products}
                values={productSelectedValues}
                onChange={onProductSelection}
                filterable={false}
                placeholder="Select ..."
                customStyle={{ width: "10vw", minHeight: "3vh" }}
              />
            </span>
          )}

          {selectedTab === EODViewOptions.TANK && (
            <span className="filter">
              <label htmlFor="region">Tank</label>
              <MultiSelectionDropDown
                data={filters.tankGroup}
                values={tankSelectedValues}
                onChange={onTankSelection}
                filterable={false}
                placeholder="Select ..."
                customStyle={{ width: "7vw", minHeight: "3vh" }}
              />
            </span>
          )}

          <span className="filter-button">
            <Button
              className="get-data-btn"
              onClick={() => {
                setChanged(false);
                setDataUpdated(false);
                setChangedItems([]);
                fetchEodSummaryData();
                setResetIndex(!resetIndex);
              }}
              disabled={filterDataLoading}
            >
              {filterDataLoading ? "Fetching..." : "Get data"}
            </Button>
          </span>
          <span className="filter-button">
            <Button
              className="refresh-btn"
              onClick={() => {
                fetchEodSummaryData(true);
              }}
              disabled={!appliedFilters}
            >
              Refresh
            </Button>
          </span>
        </div>
      </div>
    );
  };

  const getRemarkUpdatedItem = (changeItem, updatedItem) => {
    if (changeItem?.remarks?.length > 0) {
      updatedItem = {
        ...updatedItem,
        Remarks: changeItem?.remarks,
      };
    }
    return updatedItem;
  };

  const getDEUpdatedItem = (actualItem, changeItem, updatedItem) => {
    if (
      parseInt(actualItem?.stockAt1145PM) !==
      parseInt(changeItem?.stockAt1145PM)
    ) {
      updatedItem = {
        ...updatedItem,
        EODStock: parseInt(changeItem?.stockAt1145PM),
      };
    }
    if (
      parseInt(actualItem?.cummSalesBy15min) !==
      parseInt(changeItem?.cummSalesBy15min)
    ) {
      updatedItem = {
        ...updatedItem,
        EODSales: parseInt(changeItem?.cummSalesBy15min),
      };
    }

    return updatedItem;
  };
  const getBFXUpdatedItem = (actualItem, changeItem, updatedItem) => {
    if (parseInt(actualItem?.eodStock) !== parseInt(changeItem?.eodStock)) {
      updatedItem = {
        ...updatedItem,
        EODStock: parseInt(changeItem?.eodStock),
      };
    }
    if (parseInt(actualItem?.eodSales) !== parseInt(changeItem?.eodSales)) {
      updatedItem = {
        ...updatedItem,
        EODSales: parseInt(changeItem?.eodSales),
      };
    }
    return updatedItem;
  };
  const createPayload = () => {
    const payload = changedItems?.map((changeItem) => {
      const email = store.getState().user.UserEmail;
      const actualItem = originalData?.find((item) => {
        const valuesSame =
          item.globalSiteId === changeItem.globalSiteId &&
          item.product === changeItem.product &&
          item.tank === changeItem.tank;
        return (
          item.rowKey === changeItem.rowKey &&
          item.date === changeItem.date &&
          valuesSame
        );
      });
      let updatedItem = {
        RowKey: changeItem?.rowKey,
        Country: changeItem?.country,
        Tank: changeItem?.tank,
        UserEmail: email,
        Date: changeItem?.date,
        SiteId: changeItem?.globalSiteId,
      };
      if (isBFXCluster()) {
        updatedItem = getBFXUpdatedItem(actualItem, changeItem, updatedItem);
      } else {
        updatedItem = getDEUpdatedItem(actualItem, changeItem, updatedItem);
      }

      updatedItem = getRemarkUpdatedItem(changeItem, updatedItem);
      return updatedItem;
    });

    return payload;
  };

  const showConfirmation = (responses) => {
    responses.forEach((item) => {
      const success = item?.statusCode >= NUMBERS.TWO_HUNDRED && item?.statusCode < NUMBERS.THREE_HUNDRED;
      dispatch(
        triggerNotification({
          type: {
            style: success ? "success" : "error",
            icon: true,
          },
          message: item?.value,
        })
      );
    });
  };

  const saveChanges = async () => {
    setSubmitting(true);
    const payload = createPayload();
    const endPoint = isBFXCluster() ? "UpdateEODData" : "UpdateEODDataDACH";
    const response = await reportsService.saveEodSummaryValues(
      payload,
      endPoint
    );
    if (response?.status >= NUMBERS.TWO_HUNDRED && response?.status < NUMBERS.THREE_HUNDRED) {
      setChanged(false);
      setDataUpdated(false);
      setChangedItems([]);
      showConfirmation(response?.data);
      fetchEodSummaryData();
    } else {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Unable to submit the comments.",
        })
      );
    }
    setSubmitting(false);
  };

  const cancelChanges = async () => {
    setEodSummaryData(originalData);
    setChanged(false);
    setDataUpdated(false);
    setChangedItems([]);
  };

  useEffect(() => {
    inputRef?.current?.focus();
    setTimeout(() => {
      inputRef?.current?.element?.setSelectionRange(
        cursorPosition,
        cursorPosition
      );
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eodSummaryData]);

  const handleDataChange = (props) => {
    const { dataItem, field, position } = props;
    const bfxCluster = isBFXCluster();
    setSelectedItem({ ...dataItem, editField: field });
    const changeItem = props?.dataItem;
    const actualItem = originalData?.find((item) => {
      const valuesSame =
        item.globalSiteId === changeItem.globalSiteId &&
        item.product === changeItem.product &&
        item.tank === changeItem.tank;
      return (
        item.rowKey === changeItem.rowKey &&
        item.date === changeItem.date &&
        valuesSame
      );
    });

    const updatedItems = eodSummaryData?.map((item) => {
      const valuesSame =
        item.globalSiteId === dataItem.globalSiteId &&
        item.tank === dataItem.tank &&
        item.product === dataItem.product;
      if (
        item.rowKey === dataItem.rowKey &&
        item.date === dataItem.date &&
        valuesSame
      ) {
        let itemChanged = false;
        if (bfxCluster) {
          itemChanged =
            parseInt(actualItem.eodStock) !== parseInt(dataItem.eodStock) ||
            parseInt(actualItem.eodSales) !== parseInt(dataItem.eodSales);
        } else {
          itemChanged =
            parseInt(actualItem.stockAt1145PM) !==
            parseInt(dataItem.stockAt1145PM) ||
            parseInt(actualItem.cummSalesBy15min) !==
            parseInt(dataItem.cummSalesBy15min);
        }

        if (itemChanged && !(dataItem?.remarks?.length > 0)) {
          dataItem.remarksError = true;
        } else {
          dataItem.remarksError = false;
        }

        return dataItem;
      }
      return item;
    });
    setEodSummaryData(updatedItems);

    const remarksUpdated = actualItem.remarks !== changeItem.remarks;
    let isChanged = false;
    const matchingField = bfxCluster
      ? field === "eodSales" || field === "eodStock"
      : field === "cummSalesBy15min" || field === "stockAt1145PM";

    if (matchingField || field === "remarks") {
      const bfxValid =
        parseInt(actualItem.eodStock) !== parseInt(changeItem.eodStock) ||
        parseInt(actualItem.eodSales) !== parseInt(changeItem.eodSales) ||
        remarksUpdated;

      const dachValid =
        parseInt(actualItem.stockAt1145PM) !==
        parseInt(changeItem.stockAt1145PM) ||
        parseInt(actualItem.cummSalesBy15min) !==
        parseInt(changeItem.cummSalesBy15min) ||
        remarksUpdated;

      const isUpdated = bfxCluster ? bfxValid : dachValid;

      setDataUpdated(isUpdated);

      const bfxChanged =
        (parseInt(actualItem.eodStock) !== parseInt(changeItem.eodStock) ||
          parseInt(actualItem.eodSales) !== parseInt(changeItem.eodSales)) &&
        dataItem?.remarks?.length > 0;

      const dachChanged =
        (parseInt(actualItem.stockAt1145PM) !==
          parseInt(changeItem.stockAt1145PM) ||
          parseInt(actualItem.cummSalesBy15min) !==
          parseInt(changeItem.cummSalesBy15min)) &&
        dataItem?.remarks?.length > 0;

      isChanged = bfxCluster ? bfxChanged : dachChanged;

      setChanged(isChanged);
    }

    if (isChanged || remarksUpdated) {
      const sameItem = changedItems?.find((item) => {
        const valuesSame =
          item.globalSiteId === changeItem.globalSiteId &&
          item.product === changeItem.product &&
          item.tank === changeItem.tank;
        return (
          item.rowKey === changeItem.rowKey &&
          item.date === changeItem.date &&
          valuesSame
        );
      });
      if (sameItem) {
        const items = changedItems?.map((item) => {
          const valuesSame =
            item.globalSiteId === changeItem.globalSiteId &&
            item.product === changeItem.product &&
            item.tank === changeItem.tank;
          if (
            item.rowKey === changeItem.rowKey &&
            item.date === changeItem.date &&
            valuesSame
          ) {
            return changeItem;
          }
          return item;
        });
        setChangedItems(items);
      } else {
        setChangedItems([...changedItems, changeItem]);
      }
    } else {
      setChangedItems(
        changedItems?.filter((changedItem) => {
          const valuesSame =
            actualItem.globalSiteId === changedItem.globalSiteId &&
            actualItem.product === changedItem.product &&
            actualItem.tank === changedItem.tank;
          return !(
            actualItem.rowKey === changedItem.rowKey &&
            actualItem.date === changedItem.date &&
            valuesSame
          );
        })
      );
    }
    setCursorPosition(position);
  };

  const showHeaderButtons = () => {
    const saveColor = changed && "#0092a5";
    const cancelColor = dataUpdated && "#fbce07";
    const saveBorderWidth = changed && "0.15vw";
    const cancelBorderWidth = dataUpdated && "0.15vw";
    return (
      <div className="buttonsContainer">
        <Button
          className="exportButton"
          onClick={exportData}
          disabled={exporting || !eodSummaryData?.length}
        >
          {exporting ? "Exporting..." : "Export"}
        </Button>
        {selectedTab === EODViewOptions.TANK && (
          <div className="submitButtonContainer">
            <button
              title="Save"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base toolbar-button"
              onClick={saveChanges}
              style={{ borderColor: saveColor, borderWidth: saveBorderWidth }}
              disabled={!changed}
            >
              Save
            </button>
            <button
              title="Cancel"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base toolbar-button"
              onClick={cancelChanges}
              style={{
                borderColor: cancelColor,
                borderWidth: cancelBorderWidth,
              }}
              disabled={!dataUpdated}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    );
  };

  const handleShipToLinkAction = (siteId, country) => {
    setSelectedSite({ siteId: siteId, country: country });
    setShowSiteInfoModal(true);
  };

  const closeModal = () => {
    setShowSiteInfoModal(false);
  };

  return (
    <div className="eod-summary-screen-container data-grid">
      <div className="row ">
        {showTabComponent()}
        {error && <span>{error}</span>}
        <div className="data-grid-container">
          {showTabContent()}
          {isPopupOpen && (
            <AlertPopup
              isOpen={isPopupOpen}
              setIsPopupOpen={setIsPopupOpen}
              title={"Information"}
              message={"You have unsaved comments.. please save (or) cancel."}
            />
          )}
          {showSiteInfoModal && (
            <SiteInfoScreen
              globalSiteId={selectedSite?.siteId}
              countryCode={selectedSite?.country || currentCountryCode}
              close={closeModal}
            />
          )}
          {submitLoading && (
            <LoadingPanel message="Fetching the leaks data.. please wait." />
          )}
          {filterDataLoading && (
            <LoadingPanel message="Fetching the configurations.. please wait.." />
          )}
          {submitting && (
            <LoadingPanel message="Submitting the values.. please wait.." />
          )}
        </div>
      </div>
    </div>
  );
};

export { EODSummaryScreen };
