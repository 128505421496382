import React, { useEffect, useRef, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import "./account-for-deliveries.scss";
import {
  DatePicker,
  DropDownList,
  Input,
  Loader,
} from "@progress/kendo-react-all";
import { reportsService } from "../../../../../services/reports";
import { triggerNotification } from "../../../../../state-management/actions";
import { error, getFormattedDate } from "../../../../../services/utils";
import {
  defaultOption,
  getPlantValues,
  getRegionForSiteId,
  getRegionSiteIds,
  getSlocValues,
} from "./account-for-deliveries-utils";
import {
  getMaterials,
  getShipTos,
  getTankListForMaterial,
  getYesterdayDate,
} from "../../reportUtils";
import { useDispatch, useSelector } from "react-redux";
import { SearchableDropdown } from "../../../../components/searchable-dropdown/searchable-dropdown";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { isDachCluster } from "../common-utils";

const AddDelivery = (props) => {
  const filters = props?.filters;
  const filterData = props?.filterData;

  const defaultTank = {
    id: defaultOption,
    text: defaultOption,
  };

  const getDefaultShipTo = () => {
    return filters?.selectedShipTo?.[0]?.id === "all" ||
      !filters?.selectedShipTo
      ? null
      : [filters?.selectedShipTo?.[0]];
  };

  const getDefaultRegion = () => {
    let regions = [];
    if (filters?.selectedRegions?.[0]?.id === "all" ||
      !filters?.selectedRegions) {
      regions = selectedShipTo
        ? getRegionForSiteId(filterData, selectedShipTo?.[0]?.id)
        : null;
    }
    else {
      regions = [filters?.selectedRegions?.[0]];
    }
    return regions;
  };

  const getDefaultSlocs = () => {
    return filters?.selectedSlocs?.[0]?.id === "all" || !filters?.selectedSlocs
      ? null
      : [filters?.selectedSlocs?.[0]];
  };

  const getDefaultPlants = () => {
    return filters?.selectedPlants?.[0]?.id === "all" ||
      !filters?.selectedPlants
      ? null
      : [filters?.selectedPlants?.[0]];
  };

  const selectedShipTo = getDefaultShipTo();
  const selectedRegion = getDefaultRegion();
  const selectedSlocs = getDefaultSlocs();
  const selectedPlants = getDefaultPlants();

  const [showError, setShowError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const removeAllItem = (items) => {
    const filteredItems = items?.filter(
      (item) => item?.id?.toLowerCase() !== "all" || item?.text !== "All"
    );

    return filteredItems;
  };

  const user = useSelector((state) => state?.user);

  const commentOptions = props?.commentOptions?.map((option) => ({
    id: option,
    text: option,
  }));

  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [fields, setFields] = useState({
    regions: removeAllItem(filters?.regions),
    selectedRegions:
      selectedRegion ||
      (filters?.regions?.length > 1 ? [filters?.regions?.[1]] : []),
    shipTos: selectedRegion
      ? getRegionSiteIds(filterData, selectedRegion?.[0]?.id)
      : removeAllItem(filters?.shipTos),
    selectedShipTo:
      selectedShipTo ||
      (filters?.shipTos?.length > 1 ? [filters?.shipTos?.[1]] : []),
    slocs: removeAllItem(filters?.slocs),
    selectedSlocs:
      selectedSlocs ||
      (filters?.slocs?.length > 1 ? [filters?.slocs?.[1]] : []),
    materials: removeAllItem(filters?.materials),
    selectedMaterial: [defaultTank],

    plants: removeAllItem(filters?.plants),
    selectedPlants:
      selectedPlants ||
      (filters?.plants?.length > 1 ? [filters?.plants?.[1]] : []),
    tanks: [],
    selectedTank: [defaultTank],
    documentDate: null,
    reportDate: new Date(),
    calculatedDelivery: null,
    confirmedDelivery: null,
    difference: null,
    outBoundDeliveryId: null,
    selectedComments: commentOptions?.length > 0 ? [commentOptions?.[0]] : [],
    commentOptions: commentOptions,
    deliveryStartTime: null,
    deliveryEndTime: null,
    startTemperature: null,
    endTemperature: null,
  });

  useEffect(() => {
    inputRef?.current?.focus();
    setTimeout(() => {
      inputRef?.current?.element?.setSelectionRange(
        cursorPosition,
        cursorPosition
      );
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  useEffect(() => {
    if (selectedItem) {
      const shipTos =
        fields.selectedRegions?.length > 0
          ? getShipTos(filterData, fields?.selectedRegions, false)
          : null;
      const shipTo =
        filters.selectedRegions && shipTos?.length > 0 ? [shipTos?.[0]] : [];
      setFields((prev) => {
        return {
          ...prev,
          shipTos: shipTos,
          selectedShipTo: shipTo,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields?.selectedRegions]);

  useEffect(() => {
    if (selectedItem) {
      const plants = getPlantValues(filterData, fields, false);
      const plant = plants?.length > 0 ? [plants[0]] : [];
      const slocs = getSlocValues(filterData, fields);
      const sloc = slocs?.length > 0 ? [slocs[0]] : [];
      const materials =
        fields.selectedShipTo?.length > 0
          ? getMaterials(filterData, fields, false, false)
          : [];
      const tanks =
        fields.selectedShipTo?.length > 0
          ? getTankListForMaterial(filterData, fields, false)
          : [];
      setFields((prev) => {
        return {
          ...prev,
          plants: plants,
          selectedPlants: plant,
          slocs: slocs,
          selectedSlocs: sloc,
          materials: materials,
          selectedMaterial: [defaultTank],
          tanks: tanks,
          selectedTank: [defaultTank],
        };
      });
    } else {
      const tanks =
        fields.selectedShipTo?.length > 0
          ? getTankListForMaterial(filterData, fields, false)
          : [];
      setFields((prev) => {
        return {
          ...prev,
          tanks: tanks,
          selectedTank: [defaultTank],
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields?.selectedShipTo]);

  useEffect(() => {
    const tanks =
      fields.selectedShipTo?.length > 0
        ? getTankListForMaterial(filterData, fields, false)
        : [];
    setFields((prev) => {
      return {
        ...prev,
        tanks: tanks,
        selectedTank: [defaultTank],
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields?.selectedMaterial]);

  useEffect(() => {
    setFields((prev) => {
      const diff = prev?.calculatedDelivery - prev?.confirmedDelivery;
      return {
        ...prev,
        difference: diff,
      };
    });
  }, [fields?.calculatedDelivery, fields?.confirmedDelivery]);

  const DropDownField = (propValues) => {
    const selectedField = `${propValues?.selectedField}`;
    const value = fields?.[selectedField];
    const selectedValue = value?.length > 0 ? value?.[0] : null;
    const field = `${propValues?.field}`;
    const data = fields?.[field]?.map((item) => item?.text);
    const filterable = propValues?.filterable;
    const errorClassName =
      showError &&
        (selectedValue?.id === defaultOption || !(selectedValue?.id?.length > 0))
        ? "errorClassName"
        : "";
    return (
      <div className="deliveryRow">
        <span className="deliveryLbl">
          {propValues?.label}
          {propValues?.mandatory && <span style={{ color: "red" }}>*</span>}
        </span>
        <span className="deliveryVal">
          {filterable ? (
            <SearchableDropdown
              data={data}
              textField="text"
              dataItemKey="id"
              filterable={true}
              onSelect={(event) => {
                setSelectedItem(selectedField);
                setFields((prev) => {
                  const selectedOption = fields?.[field]?.find(
                    (option) => option?.text === event?.value?.text
                  );
                  if (selectedOption) {
                    return {
                      ...prev,
                      [selectedField]: [selectedOption],
                    };
                  }
                  return prev;
                });
              }}
              value={{ text: selectedValue?.text }}
              style={{
                width: "15vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
            />
          ) : (
            <DropDownList
              data={fields?.[`${propValues?.field}`]}
              textField="text"
              dataItemKey="id"
              className={errorClassName}
              onChange={(event) => {
                setSelectedItem(selectedField);
                setFields((prev) => {
                  return {
                    ...prev,
                    [selectedField]: [event?.value],
                  };
                });
              }}
              value={selectedValue}
              style={{
                width: "15vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
            />
          )}
        </span>
      </div>
    );
  };

  const onTimeChange = (propValues) => {
    if (propValues?.type === "ignore") {
      setFields((prev) => {
        return {
          ...prev,
        };
      });
    } else {
      const date = propValues.target.value
        ? new Date(propValues?.target.value)
        : propValues?.defaultValue;
      setFields((prev) => {
        setSelectedItem(propValues?.selectedField);
        return {
          ...prev,
          [propValues?.selectedField]: date,
        };
      });
    }
  };

  const TimeField = (propValues) => {
    const defaultValue = new Date();
    const mandatory = propValues?.mandatory;
    defaultValue.setHours(0, 0, 0, 0);
    const selectedField = `${propValues?.selectedField}`;
    const selectedValue = fields?.[selectedField];

    const min =
      propValues?.type === "start"
        ? fields?.deliveryStartTime || defaultValue : defaultValue;
    return (
      <div className="deliveryRow">
        <span className="dateLbl">
          {propValues?.label}
          {mandatory && <span style={{ color: "red" }}>*</span>}
        </span>
        <span className="dateVal">
          <TimePicker
            format="HH:mm"
            placeholder="HH:mm"
            min={min}
            disabled={propValues?.disabled}
            defaultValue={defaultValue}
            value={selectedValue}
            onChange={(e) => {
              if (e.nativeEvent.type === "click") {
                onTimeChange({
                  ...e,
                  selectedField,
                  defaultValue,
                });
              } else if (!e.target.value) {
                onTimeChange({
                  ...e,
                  type: "ignore",
                  selectedField,
                  defaultValue,
                });
              }
            }}
          />
        </span>
      </div>
    );
  };

  const onDateChange = (propValues) => {
    const { event, selectedField } = propValues;
    const date = new Date(event?.value);
    setFields((prev) => {
      setSelectedItem(selectedField);
      return {
        ...prev,
        [selectedField]: date,
      };
    });
  };
  const DateField = (propValues) => {
    const selectedField = `${propValues?.selectedField}`;
    const selectedValue = fields?.[selectedField];
    const mandatory = propValues?.mandatory;
    const errorClassName = showError && !selectedValue ? "errorClassName" : "";
    return (
      <div className="deliveryRow">
        <span className="dateLbl">
          {propValues?.label}
          {mandatory && <span style={{ color: "red" }}>*</span>}
        </span>
        <span className="dateVal">
          <DatePicker
            format={"dd-MMM-yyyy"}
            value={selectedValue}
            onChange={(event) => {
              onDateChange({
                event,
                selectedField,
              });
            }}
            className={errorClassName}
            placeholder={defaultOption}
            max={getYesterdayDate()}
          />
        </span>
      </div>
    );
  };

  const onChange = (propValues) => {
    const { event, selectedField } = propValues;
    const position = event.target.element?.selectionStart;
    setCursorPosition(position);
    const inputValue = event.target.value;
    const newValue = inputValue.replace(/[^-?\d*\\.?\d+$]/g, "");
    setSelectedItem(selectedField);
    setFields((prev) => {
      return {
        ...prev,
        [selectedField]: newValue,
      };
    });
  };
  const TextInputField = (propValues) => {
    const selectedField = `${propValues?.selectedField}`;
    const selectedValue = fields?.[selectedField];
    const mandatory = propValues?.mandatory;

    const sameItem = selectedItem === selectedField;
    const errorClassName =
      showError && mandatory && !(selectedValue?.length > 0)
        ? "errorClassName"
        : "";

    const disabled = propValues?.disabled ? "disabled-input" : "";

    return (
      <div className="deliveryRow">
        <span className="inputLbl">
          {propValues?.label}
          {mandatory && <span style={{ color: "red" }}>*</span>}
        </span>
        <span className={`${errorClassName} inputVal ${disabled}`}>
          <Input
            ref={sameItem ? inputRef : null}
            value={selectedValue}
            onChange={(event) => {
              onChange({
                event,
                selectedField,
              });
            }}
            disabled={propValues?.disabled}
          />
        </span>
      </div>
    );
  };

  const showHeader = () => {
    return (
      <div className="deliveryHeader">
        <div className="title">Add New Delivery</div>
        <div className="close-btn-container">
          <Button
            className="close-btn"
            onClick={() => {
              props?.cancelDelivery();
            }}
          >
            Close
          </Button>
        </div>
      </div>
    );
  };

  const showContent = () => {
    return (
      <div className="deliveryContent">
        <DropDownField
          label="Region"
          field="regions"
          selectedField="selectedRegions"
          filterable
        />
        <DropDownField
          label="ShipTo"
          field="shipTos"
          selectedField="selectedShipTo"
          mandatory
          filterable
        />
        <DropDownField
          label="Plant"
          field="plants"
          selectedField="selectedPlants"
        />
        <DropDownField
          label="SLOC"
          field="slocs"
          selectedField="selectedSlocs"
        />
        <DropDownField
          label="Material No"
          field="materials"
          mandatory
          selectedField="selectedMaterial"
        />
        <DropDownField
          label="Tank"
          field="tanks"
          mandatory
          selectedField="selectedTank"
        />
        <DateField
          label="Delivery Date"
          selectedField="documentDate"
          mandatory
        />
        <TimeField
          label="Delivery Start Time"
          selectedField="deliveryStartTime"
          type="start"
          disabled
        />
        <TimeField
          label="Delivery End Time"
          selectedField="deliveryEndTime"
          type="end"
          disabled
        />
        <TextInputField
          label="Start Temperature"
          selectedField="startTemperature"
          disabled
        />
        <TextInputField
          label="End Temperature"
          selectedField="endTemperature"
          disabled
        />
        <TextInputField
          label={isDachCluster() ? "Calculated Delivery" : "Detected Delivery"}
          selectedField="calculatedDelivery"
          disabled
        />
        <TextInputField
          label="Confirmed Delivery"
          selectedField="confirmedDelivery"
          mandatory
        />
        <div className="deliveryRow">
          <span className="dateLbl">Difference</span>
          <span className="dateVal">{fields?.difference}</span>
        </div>
        <TextInputField
          label="OutBounded Delivery Id"
          selectedField="outBoundDeliveryId"
          mandatory
        />
        {/* <DropDownField
          label="Comment"
          field="commentOptions"
          selectedField="selectedComments"
        /> */}
      </div>
    );
  };

  const isValid = () => {
    const deliveryValues =
      fields?.confirmedDelivery &&
      fields?.outBoundDeliveryId &&
      fields?.documentDate;

    const tankValues =
      fields?.reportDate &&
      fields?.selectedTank?.[0]?.id !== defaultOption &&
      fields?.selectedTank?.[0]?.id?.length > 0;

    const materialValues =
      fields?.selectedMaterial?.[0]?.id !== defaultOption &&
      fields?.selectedMaterial?.[0]?.id?.length > 0;

    const allValid = deliveryValues && tankValues && materialValues;

    setShowError(!allValid);
    return allValid;
  };

  const showSubmitButton = () => {
    return (
      <div className="submit-btn-container">
        <Button
          className="submit-btn"
          onClick={() => {
            if (isValid()) {
              saveChanges();
            }
          }}
        >
          {submitting ? "Submitting..." : "Submit Delivery"}
        </Button>
      </div>
    );
  };

  const createPayload = () => {
    const documentDate = getFormattedDate(fields?.documentDate);
    const reportDate = getFormattedDate(fields?.reportDate);
    const shipTo = fields?.selectedShipTo?.[0]?.id;
    const siteData = fields?.shipTos?.find(
      (item) => item.id === shipTo
    );
    const selectedComment = fields?.selectedComments?.[0]?.id;
    const selectedTank =
      fields?.selectedTank?.[0]?.id === defaultOption
        ? ""
        : fields?.selectedTank?.[0]?.id;

    const deliveryStartDate = fields?.documentDate
      ? new Date(fields?.documentDate)
      : new Date();
    deliveryStartDate.setHours(
      fields?.deliveryStartTime ? fields?.deliveryStartTime?.getHours() : 0
    );
    deliveryStartDate.setMinutes(
      fields?.deliveryStartTime ? fields?.deliveryStartTime?.getMinutes() : 0
    );

    const deliveryEndDate = fields?.documentDate
      ? new Date(fields?.documentDate)
      : new Date();
    deliveryEndDate.setHours(
      fields?.deliveryEndTime ? fields?.deliveryEndTime?.getHours() : 0
    );
    deliveryEndDate.setMinutes(
      fields?.deliveryEndTime ? fields?.deliveryEndTime?.getMinutes() : 0
    );

    const payload = {
      countryCode: fields?.selectedRegions?.[0]?.countryCode,
      plant: fields?.selectedPlants?.[0]?.id,
      shipTo: fields?.selectedShipTo?.[0]?.id,
      sloc: fields?.selectedSlocs?.[0]?.id,
      materialNumber: fields?.selectedMaterial?.[0]?.id,
      documentDate: documentDate,
      reportDate: reportDate,
      calculatedDelivery: null,
      confirmedDelivery: parseFloat(fields?.confirmedDelivery),
      difference: fields?.difference,
      outBoundDeliveryId: fields?.outBoundDeliveryId,
      comment: selectedComment === defaultOption ? "" : selectedComment,
      userName: user.UserEmail,
      localSiteId: siteData?.localSiteId,
      obn: siteData?.obn,
      region: fields?.selectedRegions?.[0]?.id,
      isRkSIte: siteData?.isRKSite,
      tankId: selectedTank,
      deliveryStartTime: null,
      deliveryEndTime: null,
      startTemperature: null,
      endTemperature: null,
    };

    return payload;
  };

  const saveChanges = async () => {
    setSubmitting(true);
    const payload = createPayload();
    const response = await reportsService.addAFDDelivery(payload);
    if (response?.data?.isSuccess) {
      dispatch(
        triggerNotification({
          type: {
            style: "success",
            icon: true,
          },
          message: response?.data?.message || "New delivery created.",
        })
      );
      props?.saveDelivery();
    } else {
      dispatch(
        triggerNotification({
          type: {
            style: error,
            icon: true,
          },
          message: response?.data?.message || "Unable to submit the delivery.",
        })
      );
      setSubmitting(false);
    }
  };

  return (
    <div className="popupContainer">
      <div className="deliveryForm">
        {showHeader()}
        {showContent()}
        {showSubmitButton()}

        {submitting && (
          <div className="loaderContainer">
            <Loader
              size="medium"
              themeColor="warning"
              type="converging-spinner"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { AddDelivery };
