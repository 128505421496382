import { useEffect, useState } from "react";
import {
  nozzleValidator,
  pumpValidator,
  tankPumpColsToShow,
} from "./site-information-utils";
import { siteService } from "../../../../../services/sites";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { PumpInputCell } from "./PumpInputCell";
import ConfirmPopup from "../../../../components/confirmation-popup/confirmation-popup";
import { triggerNotification } from "../../../../../state-management/actions";
import { useDispatch } from "react-redux";
import { NUMBERS } from "../common-utils";

const TankPumpDetailsContainer = (props) => {
  const tankData = props.dataItem;
  const dispatch = useDispatch();
  const [pumpEditing, setPumpEditing] = useState();
  const [showPumpSaveConfirmation, setShowPumpSaveConfirmation] =
    useState(false);
  const [currentOffSet, setCurrentOffSet] = useState();
  const [pumpData, setPumpData] = useState([]);
  const [disableAdd, setDisableAdd] = useState(false);
  const tankItem = {
    ...tankData,
    GlobalSiteId: props?.currentSiteItem?.GlobalSiteId,
  };

  const sortKeys = (keys) => {
    const sortedArray = [...keys].sort((a, b) => {
      const aStr = a.toString();
      const bStr = b.toString();

      if (aStr.length !== bStr.length) {
        return aStr.length - bStr.length;
      }
      return a - b;
    });
    return sortedArray;
  };

  const formatPumpData = () => {
    const pumpNozzle = tankItem?.PumpNozzle;
    const tankEdit =
      pumpEditing && tankItem?.deviceID === props?.selectedTankItem?.deviceID;

    let pumpList = [];
    const nozzleKeys = pumpNozzle && sortKeys(Object.keys(pumpNozzle));
    pumpNozzle &&
      nozzleKeys?.forEach((key, index) => {
        if (key?.length === 0) {
          setDisableAdd(true);
        }
        const editing =
          key?.length === 0 ||
          (tankEdit && key === props?.selectedTankPumpItem?.pump?.toString());
        const nozzles = pumpNozzle?.[key];
        if (Array.isArray(nozzles)) {
          nozzles.forEach((pump) => {
            const pumpItem = {
              pump: key,
              nozzle: pump,
              location: index,
              inEdit: editing,
              title: editing ? "Save" : "Edit",
              subTitle: editing ? "Cancel" : "Delete",
              pumpValid: pumpValidator(key),
              nozzleValid: nozzleValidator(pump),
            };
            pumpList = [...pumpList, pumpItem];
          });
        } else {
          const pumpItem = {
            pump: key,
            nozzle: nozzles,
            location: index,
            inEdit: editing,
            title: editing ? "Save" : "Edit",
            subTitle: editing ? "Cancel" : "Delete",
            pumpValid: pumpValidator(key),
            nozzleValid: nozzleValidator(nozzles),
          };
          pumpList = [...pumpList, pumpItem];
        }
      });

    setPumpData(pumpList);
  };

  useEffect(() => {
    formatPumpData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isPumpAndNozzleExist = (item, pumpDataItem) => {
    let error1 = null;
    const exist = pumpDataItem?.find(
      (data) =>
        data?.pump?.toString() === item?.pump?.toString() &&
        data?.nozzle?.toString() === item?.nozzle?.toString()
    );
    if (exist) {
      error1 = "The Pump & Nozzle is already exist.";
    }
    return error1;
  };

  const handleDataChange = (e) => {
    const { dataItem, field, value } = e;
    const updatedData = pumpData?.map((item) => {
      if (item?.location === dataItem?.location) {
        item[field] = value;
        const filteredPumpData = pumpData?.filter(
          (pump) => pump.location !== dataItem?.location
        );
        const error1 = isPumpAndNozzleExist(item, filteredPumpData);
        if (error1) {
          item["pumpValid"] = error1;
          item["nozzleValid"] = error1;
        } else {
          item["pumpValid"] = pumpValidator(item?.pump);
          item["nozzleValid"] = nozzleValidator(item?.nozzle);
        }
      }
      return item;
    });
    setPumpData(updatedData);
  };

  const handleTankPumpEditSave = async (pumpDataItem, offset) => {
    setCurrentOffSet(offset);
    setPumpData(pumpDataItem);
    setShowPumpSaveConfirmation(true);
  };

  const tankPumpSaveAction = async () => {
    setShowPumpSaveConfirmation(false);
    setPumpEditing(false);

    const siteItem = props?.siteData?.find(
      (data) => data?.GlobalSiteId === tankItem?.GlobalSiteId
    );

    const tankDetails = siteItem?.TankInfo?.map((tank) => {
      const tankDetailsInfo = tank;
      if (
        tankDetailsInfo?.deviceID === tankItem?.deviceID &&
        tankDetailsInfo?.tankName === tankItem?.tankName
      ) {
        const pumpNozzle = {};
        pumpData?.forEach((item) => {
          pumpNozzle[item.pump] = [item?.nozzle];
        });
        tankDetailsInfo.PumpNozzle = pumpNozzle;
      }
      return tankDetailsInfo;
    });
    siteItem.TankInfo = tankDetails;
    props?.setSubmitting(true);
    const response = await siteService.updateSiteInfo(
      siteItem?.Country,
      siteItem?.GlobalSiteId,
      siteItem
    );
    if (
      response?.status >= NUMBERS.TWO_HUNDRED &&
      response?.status < NUMBERS.THREE_HUNDRED
    ) {
      props?.setSelectedTankPumpItem(null);
      dispatch(
        triggerNotification({
          type: {
            style: "success",
            icon: true,
          },
          message: response?.data?.message || response?.data,
        })
      );
    } else {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Failed to save site information!",
        })
      );
    }
    props?.setSubmitting(false);
  };

  const handleTankPumpEditCancel = (pumpDataInfo, tankItemInfo, addingPump) => {
    if (addingPump) {
      const updatedSiteData = props?.siteData?.map((data) => {
        if (data?.GlobalSiteId === tankItemInfo?.GlobalSiteId) {
          data.expanded = true;
          const tankDetails = data?.TankInfo?.map((tank) => {
            if (
              tank?.deviceID === tankItemInfo?.deviceID &&
              tank?.tankName === tankItemInfo?.tankName
            ) {
              tank.expanded = true;
              const pumpNozzle = {};
              pumpDataInfo?.forEach((item) => {
                if (item.pump?.length > 0) {
                  pumpNozzle[item.pump] = [item?.nozzle];
                }
              });
              tank.PumpNozzle = pumpNozzle;
            }
            return tank;
          });
          data.TankInfo = tankDetails;
        }
        return data;
      });
      props?.setSiteData(updatedSiteData);
    } else {
      formatPumpData();
    }
    setPumpEditing(false);
    props?.setSelectedTankPumpItem(null);
  };

  const handleTankPumpEdit = (_tankItem, item) => {
    setPumpEditing(true);
    const pumpList = pumpData?.map((pump) => {
      if (pump.pump === item.pump) {
        return {
          ...pump,
          inEdit: true,
          title: "Save",
          subTitle: "Cancel",
        };
      } else {
        return {
          ...pump,
          inEdit: false,
          title: "Edit",
          subTitle: "Delete",
        };
      }
    });
    setPumpData(pumpList);
  };

  const handleTankPumpAdd = () => {
    const pumpItem = {
      pump: "",
      nozzle: "",
      location: 0,
      inEdit: true,
      title: "Save",
      subTitle: "Cancel",
      pumpValid: pumpValidator(null),
      nozzleValid: nozzleValidator(null),
    };
    const pumpList = [pumpItem, ...pumpData]?.map((item, index) => ({
      ...item,
      location: index,
    }));
    setPumpData(pumpList);
    setPumpEditing(true);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="pumpGridContainer">
          <AppDataGrid
            style={{ maxHeight: "50vh", borderColor: "#fbce07" }}
            take={10000000}
            pageable={false}
            sortable={true}
            data={pumpData}
            onItemChange={handleDataChange}
            cells={{
              edit: {
                numeric: PumpInputCell,
              },
            }}
            columnsToShow={tankPumpColsToShow({
              tankItem: tankItem,
              pumpData: pumpData,
              onEdit: handleTankPumpEdit,
              onDelete: props?.handleTankPumpDelete,
              onSave: handleTankPumpEditSave,
              onCancel: handleTankPumpEditCancel,
              onAdd: handleTankPumpAdd,
              disableAdd: disableAdd,
              readOnly: props?.readOnly,
            })}
          />
          {showPumpSaveConfirmation && (
            <ConfirmPopup
              offset={currentOffSet}
              position="top"
              isOpen={showPumpSaveConfirmation}
              title={"Are you sure you want to update the site information?"}
              message={""}
              saveAction={() => {
                tankPumpSaveAction();
              }}
              cancelAction={() => {
                setShowPumpSaveConfirmation(false);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TankPumpDetailsContainer;
