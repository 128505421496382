import { httpService } from "./http";
import { appConfig } from "../configs/app-config";
import { loggerService } from "../services/logger";
import { API } from "./utils";
import { store } from "../state-management/store";
import {
  setUserScreenList,
  triggerNotification,
} from "../state-management/actions";

/**
 * This service is responsible for handling the users.
 * It is capable of fetching users from the remote server.
 */
class UserService {
  /**
   * Fetches a particular user from the remote server.
   * @param {string} UID  the unique user id associated with each user.
   */
  async fetchUser(UID, country) {
    let URL = `${API.userManagement}/User/Details/${UID}`;
    if (country) {
      URL = `${API.userManagement}/User/Details/${UID}/${country}`;
    }
    try {
      const response = await httpService.get(URL);
      if (response?.hasOwnProperty("data")) {
        const { ScreenList } = response.data;
        store.dispatch(setUserScreenList(ScreenList));
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  /**
   * Fetches the audit list from the remote server.
   * Calls different APIs based on environment.
   */
  async fetchAuditList() {
    const URL = `${API.userManagement}/User/Details/all`;
    try {
      const response = await httpService.get(URL);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  /**
   * Fetches the user's login history from the remote server.
   * Calls different APIs based on environment.
   */
  async fetchLoginHistory() {
    const URL = `${API.userManagement}/User/LoginHistory`;
    try {
      const response = await httpService.get(URL);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  /**
   * This function fetches all user role types from remote server.
   */
  async fetchUserRoles(userType) {
    const URL = `${API.userManagement}/User/${
      userType === "nonShellUser" ? "NonAdminUserRoles" : "UserRoles"
    }`;
    try {
      const response = await httpService.get(URL);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  /**
   * Pre registers the non shell user.
   * This function makes a preregister API call to the CIPM server.
   */
  async preRegisterNonShellUser(newUser) {
    const URL = `${appConfig.auth.cipm.baseUrl}/idp/v1/account/pre-register`;
    const auth = `${appConfig.auth.cipm.clientId}:${appConfig.auth.cipm.clientSecret}`;
    const options = {
      headers: {
        Authorization: `Basic ${btoa(auth)}`,
      },
    };
    const payload = {
      client_id: appConfig.auth.cipm.clientId,
      scope: "openid",
      auth_type: "email",
      redirect_uri: appConfig.auth.cipm.redirectUrl,
      grant_type: "password",
      profile_fields: {
        emailAddress: newUser.email,
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        addressCountry: newUser.country,
      },
      locale: "en-US",
    };
    try {
      const response = await httpService.post(URL, payload, options);
      if (response?.hasOwnProperty("data")) {
        return response.data.uuid;
      } else {
        return null;
      }
    } catch (error) {
      store.dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: false,
          },
          message: `Unable to onboard user: ${error.message}`,
        })
      );
      return null;
    }
  }

  /**
   * This function onboards new shell/non-shell user to the system.
   */
  async onboardNewUser(payload) {
    const URL = `${API.userManagement}/User`;
    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
  async UpdateProfileService(data) {
    const url = `${API.userManagement}/User/UpdateProfile`;
    return httpService.put(url, data);
  }
  async updateUserRecord(payload) {
    const URL = `${API.userManagement}/User`;
    try {
      const response = await httpService.put(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
}

loggerService.dev("Creating User Service");
const userService = new UserService();

loggerService.dev("Creating User Service");
export { userService };
