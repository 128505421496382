import "./pump-index-monthly-exception.scss";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { triggerNotification } from "../../../../../state-management/actions";
import { reportsService } from "../../../../../services/reports";
import { store } from "../../../../../state-management/store";
import { exceptionColsToShow } from "./pump-index-utils";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import {
  Button,
  DropDownList,
  getMultiSelectTreeValue,
} from "@progress/kendo-react-all";
import {
  getAllSelectedOptions,
  getMaterials,
  getMonths,
  getShipTos,
  getSiteNames,
  getThresholdYears,
} from "../../reportUtils";
import { useNavigate } from "react-router-dom";

import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import {
  MultiSelectionDropDown,
  multiSelectionDropDownFields,
} from "../../../../components/multi-select-dropdown/multi-select-dropdown";
import { DEFAULT_PAGE_SIZE, isBFXCluster } from "../common-utils";
import { serverError, unableToFetch } from "../../../../../services/utils";

export const DropDownSelection = {
  ShipTo: 0,
  SiteName: 1,
};

const PumpIndexMonthlyExceptionScreen = () => {
  const dispatch = useDispatch();
  const [pumpIndexReportData, setPumpIndexReportData] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState(null);
  const defaultCountry = useSelector((state) => state?.selectedCountry);
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [resetIndex, setResetIndex] = useState(false);
  const [filterDataLoading, setFilterDataLoading] = useState(false);
  const [error, setError] = useState("");
  const [masterData, setMasterData] = useState();
  const gridRef = useRef(null);
  const exportGridRef = useRef(null);
  const [exporting, setExporting] = useState(false);
  const [showError, setShowError] = useState(false);
  const [currentField, setCurrentField] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [filters, setFilters] = useState({
    selectedShipTo: [],
    selectedRegions: [
      {
        id: "all",
        text: "All",
      },
    ],
    selectedYear: getThresholdYears(1)?.[0],
    years: getThresholdYears(10),
    selectedMonth: {
      id: new Date().getMonth() + 1,
      text: new Date().toLocaleString("en-US", { month: "short" }),
    },
    months: getMonths(),
    selectedSiteNames: [],
    selectedProducts: [],
    products: [],
    allSiteIds: [],
    allSiteNames: [],
  });

  const navigate = useNavigate();
  store.subscribe(() => {
    const { selectedCountry } = store.getState();
    setCurrentCountryCode(selectedCountry);
  });

  useEffect(() => {
    if (!isBFXCluster()) {
      navigate("/home");
      return;
    }
    setFirstLoad(true);
    fetchMasterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryCode]);

  const [shipToSelectedValues, setShipToSelectedValues] = useState(
    filters.selectedShipTo
  );

  const [siteNameSelectedValues, setSiteNameSelectedValues] = useState(
    filters.selectedSiteNames
  );

  const [productSelectedValues, setProductSelectedValues] = useState(
    filters?.selectedProducts
  );

  useEffect(() => {
    if (currentField === DropDownSelection.ShipTo) {
      let siteData = {
        selectedShipTo: [],
        selectedSiteNames: [],
      };
      if (filters?.allSiteIds?.length) {
        shipToSelectedValues?.forEach((siteIdData) => {
          const data = filters?.allSiteIds?.find(
            (site) => site?.globalSiteId === siteIdData?.id
          );
          if (data) {
            siteData = {
              selectedShipTo: [...siteData.selectedShipTo, data?.globalSiteId],
              selectedSiteNames: [
                ...siteData.selectedSiteNames,
                data?.siteName,
              ],
            };
          }
        });

        setFilters((prev) => {
          return {
            ...prev,
            ...siteData,
          };
        });
      }

      let selectedSiteNames = [];
      siteData?.selectedSiteNames?.forEach((siteName) => {
        const site = filters?.allSiteNames?.find(
          (siteInfo) => siteInfo?.siteName === siteName
        );

        if (site) {
          selectedSiteNames = [...selectedSiteNames, site];
        }
      });

      setSiteNameSelectedValues(selectedSiteNames);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipToSelectedValues]);

  useEffect(() => {
    if (currentField !== DropDownSelection.SiteName) {
      let selectedSiteNames = [];
      filters?.selectedSiteNames?.forEach((siteName) => {
        const site = filters?.allSiteNames?.find(
          (siteInfo) => siteInfo?.siteName === siteName
        );

        if (site) {
          selectedSiteNames = [...selectedSiteNames, site];
        }
      });
      setSiteNameSelectedValues(selectedSiteNames);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.selectedShipTo]);

  useEffect(() => {
    if (currentField === DropDownSelection.SiteName) {
      let siteData = {
        selectedShipTo: [],
        selectedSiteNames: [],
      };
      if (filters?.allSiteNames?.length) {
        siteNameSelectedValues?.forEach((siteNameData) => {
          const data = filters?.allSiteNames?.find(
            (siteInfo) => siteInfo?.siteName === siteNameData?.id
          );
          if (data) {
            siteData = {
              selectedShipTo: [...siteData.selectedShipTo, data?.globalSiteId],
              selectedSiteNames: [
                ...siteData.selectedSiteNames,
                data?.siteName,
              ],
            };
          }
        });

        setFilters((prev) => {
          return {
            ...prev,
            ...siteData,
          };
        });
      }

      let selectedShipTo = [];
      siteData?.selectedShipTo?.forEach((siteId) => {
        const site = filters?.allSiteIds?.find(
          (siteInfo) => siteInfo?.globalSiteId === siteId
        );

        if (site) {
          selectedShipTo = [...selectedShipTo, site];
        }
      });

      setShipToSelectedValues(selectedShipTo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteNameSelectedValues]);

  useEffect(() => {
    if (filters.selectedSiteNames && firstLoad) {
      fetchPumpMonthExceptionData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.selectedSiteNames]);

  useEffect(() => {
    setFilters({
      ...filters,
      selectedProducts: productSelectedValues,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSelectedValues]);

  const showHideLoaders = (show = false) => {
    setExporting(show);
  };

  const exportData = async () => {
    showHideLoaders(true);
    exportGridRef?.current?.exportDataInExcel &&
      (await exportGridRef.current.exportDataInExcel());

    setTimeout(showHideLoaders, 1000);
  };

  const onYearSelection = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedYear: event,
      };
    });
  };

  const onMonthSelection = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedMonth: event,
      };
    });
  };

  const onShipToSelection = (event) => {
    setCurrentField(DropDownSelection.ShipTo);
    if (event.operation === "delete") {
      setShipToSelectedValues([]);
    } else {
      setShipToSelectedValues((prev) => {
        const selectedValues = getMultiSelectTreeValue(filters?.allSiteIds, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
        return selectedValues;
      });
    }
  };

  const onSiteNameSelection = (event) => {
    setCurrentField(DropDownSelection.SiteName);
    if (event.operation === "delete") {
      setSiteNameSelectedValues([]);
    } else {
      setSiteNameSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.allSiteNames, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onProductSelection = (event) => {
    if (event.operation === "delete") {
      setProductSelectedValues([]);
    } else {
      setProductSelectedValues((prev) => {
        const selectedValues = getAllSelectedOptions(event, prev);
        return getMultiSelectTreeValue(filters?.products, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        });
      });
    }
  };

  useEffect(() => {
    const siteIds =
      filters.selectedRegions?.length > 0
        ? getShipTos(masterData, filters.selectedRegions, false)
        : [];

    const siteNames =
      filters.selectedRegions?.length > 0
        ? getSiteNames(masterData, filters.selectedRegions, false)
        : [];

    if (siteIds?.[0] || siteNames?.[0]) {
      setFilters((prev) => {
        return {
          ...prev,
          selectedShipTo: [siteIds?.[0]?.id],
          selectedSiteNames: [siteNames?.[0]?.id],
          allSiteIds: siteIds,
          allSiteNames: siteNames,
        };
      });
    }

    if (siteIds?.[0]) {
      setShipToSelectedValues([siteIds?.[0]]);
    }

    if (siteNames?.[0]) {
      setSiteNameSelectedValues([siteNames?.[0]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterData]);

  const getMonthsData = (selectedYear) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const months = filters?.months?.map((month) => {
      return selectedYear === currentYear
        ? {
            ...month,
            disabled: month.id > currentMonth + 1,
          }
        : {
            ...month,
            disabled: false,
          };
    });

    return months;
  };
  useEffect(() => {
    const year = parseInt(filters?.selectedYear?.text);
    const months = getMonthsData(year);
    const currentYear = new Date().getFullYear();

    let selectedMonth = filters.selectedMonth;
    const currentMonth = new Date().getMonth();
    if (year === currentYear && selectedMonth.id > currentMonth + 1) {
      selectedMonth = {
        id: new Date().getMonth() + 1,
        text: new Date().toLocaleString("en-US", { month: "short" }),
      };
    }
    setFilters((prev) => {
      return {
        ...prev,
        months: months,
        selectedMonth: selectedMonth,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedYear]);

  useEffect(() => {
    const products = getMaterials(masterData, filters, false);

    const selectedProducts = products?.length > 0 ? [products?.[0]] : [];
    setFilters((prev) => {
      return {
        ...prev,
        products: products,
        selectedProducts: selectedProducts,
      };
    });
    setProductSelectedValues(selectedProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.selectedShipTo]);

  const fetchMasterData = async () => {
    try {
      setPumpIndexReportData([]);
      setFilterDataLoading(true);
      const response = await reportsService.fetchToleranceTrendMasterData({
        country: currentCountryCode,
      });
      setFilterDataLoading(false);
      if (response) {
        setMasterData(response);
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: unableToFetch,
          })
        );
      }
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: serverError,
        })
      );
      setError(serverError);
      setFilterDataLoading(false);
      setError("");
    }
  };

  const showHeaders = () => {
    return (
      <div className="header-container">
        <div className="filters-container">
          <span className="filter">
            <label htmlFor="year">Year</label>
            <DropDownList
              data={filters.years}
              textField="text"
              dataItemKey="id"
              onChange={(event) => {
                onYearSelection(event.target.value);
              }}
              value={filters.selectedYear}
              style={{
                width: "7vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
            />
          </span>
          <span className="filter">
            <label htmlFor="month">Month</label>
            <DropDownList
              data={filters.months}
              textField="text"
              filterable={true}
              dataItemKey="id"
              onChange={(event) => {
                onMonthSelection(event.target.value);
              }}
              value={filters.selectedMonth}
              style={{
                width: "7vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
            />
          </span>
          <span className="filter">
            <label htmlFor="shipTo">Ship To</label>
            <MultiSelectionDropDown
              data={filters.allSiteIds}
              values={shipToSelectedValues}
              onChange={onShipToSelection}
              filterable={true}
              showError={showError}
              customStyle={{ width: "8vw", minHeight: "3vh" }}
            />
          </span>
          <span className="filter">
            <label htmlFor="shipTo">Site Name</label>
            <MultiSelectionDropDown
              data={filters.allSiteNames}
              values={siteNameSelectedValues}
              onChange={onSiteNameSelection}
              filterable={true}
              showError={showError}
              customStyle={{ width: "15vw", minHeight: "3vh" }}
            />
          </span>
          <span className="filter">
            <label htmlFor="region">Material</label>
            <MultiSelectionDropDown
              data={filters.products}
              values={productSelectedValues}
              onChange={onProductSelection}
              filterable={false}
              placeholder="Select ..."
              customStyle={{ width: "13vw", minHeight: "3vh" }}
            />
          </span>
        </div>
        <span className="filter-button">
          <Button
            className="get-data-btn"
            onClick={() => {
              fetchPumpMonthExceptionData();
              setResetIndex(!resetIndex);
            }}
            disabled={filterDataLoading}
          >
            {filterDataLoading ? "Fetching..." : "Get data"}
          </Button>
        </span>
        <span className="filter-button">
          <Button
            className="refresh-btn"
            onClick={() => {
              fetchPumpMonthExceptionData(true);
            }}
            disabled={!appliedFilters}
          >
            Refresh
          </Button>
        </span>
      </div>
    );
  };

  const fetchPumpMonthExceptionData = async (refresh = false) => {
    const filter = refresh ? appliedFilters : filters;
    const month = filter.selectedMonth;
    const year = filter.selectedYear;
    const products = filter?.selectedProducts?.map((item) => item.id);
    const selectedShipTo = filter?.selectedShipTo;

    const valuesSelected = month && year && selectedShipTo?.length > 0;

    if (valuesSelected) {
      const payload = {
        month: month?.id,
        year: year?.text,
        globalSiteIds: filter?.selectedShipTo,
        materialCodes: products,
      };
      setShowError(false);
      setFirstLoad(false);
      setSubmitLoading(true);
      if (!refresh) {
        setAppliedFilters(filter);
      }
      try {
        let response = await reportsService.fetchPumpMonthlyExceptionData(
          payload
        );
        response = response?.map((item) => {
          return {
            ...item,
            endOfMonthStock: parseInt(item?.endOfMonthStock),
            totalDeliveries: parseInt(item?.totalDeliveries),
            totalOverallPumpSales: parseInt(item?.totalOverallPumpSales),
            variancePercentage: parseInt(item?.variancePercentage),
            varianceQuantity: parseInt(item?.varianceQuantity),
          };
        });
        setPumpIndexReportData(response);
        setSubmitLoading(false);
        if (gridRef.current) {
          gridRef.current.scrollIntoView({
            rowIndex: 0,
          });
        }
      } catch (error1) {
        setPumpIndexReportData([]);
        setSubmitLoading(false);
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: serverError,
          })
        );
        setError(serverError);
        setSubmitLoading(false);
        setError("");
      }
    } else {
      setShowError(true);
    }
  };
  return (
    <div className="pump-index-monthly-exception-container data-grid">
      <div className="row ">
        {/* <h4 className="title">Pump Register Monthly Exception Report</h4> */}
        {error && <span>{error}</span>}
        <div className="data-grid-container">
          {showHeaders()}
          <div className="spaceContainer">
            <Button
              className="exportButton"
              onClick={exportData}
              disabled={exporting || !pumpIndexReportData?.length}
            >
              {exporting ? "Exporting..." : "Export"}
            </Button>
          </div>
          <div className="gridContainer">
            <AppDataGrid
              gridRef={gridRef}
              take={DEFAULT_PAGE_SIZE}
              style={{ height: "61.5vh" }}
              pageable={true}
              filterable={true}
              data={pumpIndexReportData}
              columnsToShow={exceptionColsToShow()}
              sortable={true}
              ref={exportGridRef}
              exportFileName="Pump Register Monthly Exception Report"
            />
          </div>
          {submitLoading && (
            <LoadingPanel message="Fetching the leaks data please wait.." />
          )}
          {filterDataLoading && (
            <LoadingPanel message="Fetching the configurations please wait.." />
          )}
        </div>
      </div>
    </div>
  );
};

export { PumpIndexMonthlyExceptionScreen };
