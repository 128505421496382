import { appConfig } from "../configs/app-config";
import { createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // localStorage for web
import rootReducer from './reducers'; // Your combined reducers
import { loggerService } from "../services/logger";

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

loggerService.dev("Creating The Global Store");

/**
 * This is the global store that the entire application will refer to for state management.
 * The store will expose redux dev tools but only if the application runs in development environment.
 */
const store =
  appConfig.isDev && window.__REDUX_DEVTOOLS_EXTENSION__
    ? createStore(persistedReducer, window?.__REDUX_DEVTOOLS_EXTENSION__())
    : createStore(persistedReducer);

loggerService.dev("Exporting The Global Store");

const persistor = persistStore(store)

export { store, persistor };
