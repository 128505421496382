import { React, useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import { triggerNotification } from "../../../state-management/actions";
import "./profile.scss";

const ProfilePage = () => {
  const userDetails = [
    { title: "Email", value: "UserEmail" },
    { title: "Role", value: "UserRole" },
    { title: "First Name", value: "UserFirstName" },
    { title: "Last Name", value: "UserLastName" },
    { title: "User Type", value: "UserType" },
    { title: "Role ID", value: "RoleId" },
    { title: "Internal User ID", value: "InternalUserId" },
    { title: "Active", value: "IsActive" },
    { title: "Country", value: "Country" },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useStore();

  const [user, setUser] = useState(null);

  const getUserDetailsFromStore = () => {
    const loggedInUser = store.getState()?.user;
    if (!loggedInUser) {
      navigate("/login");
      return;
    }
    setUser(loggedInUser);
  };

  useEffect(() => {
    getUserDetailsFromStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    dispatch(
      triggerNotification({
        type: {
          style: "info",
          icon: true,
        },
        message: "Text Copied To Clipboard",
      })
    );
  };

  const logOut = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    user && (
      <div className="container-fluid">
        <div className="row profile-page">
          <div className="container user-details-container">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-6 col-lg-6 col-xl-6 user-details-img-container">
                <img
                  className="img img-responsive user-img"
                  src={`https://ui-avatars.com/api/?name=${user.UserFirstName}+${user.UserLastName}&color=random&background=random`}
                  alt="User Icon"
                />
                <h1>
                  {user.UserFirstName} {user.UserLastName}
                </h1>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-6 col-lg-6 col-xl-6 user-details-table-container">
                <h2 className="user-details-heading">User Details</h2>
                <table>
                  <tbody>
                    {userDetails.map((detail) => {
                      return (
                        <tr key={`${detail.title}-${detail.value}`}>
                          <td>
                            <b>{detail.title}</b>
                          </td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              copyToClipboard(
                                user?.[detail.value] || "Unknown"
                              );
                            }}
                          >
                            {user?.[detail.value] || "Unknown"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <button className="home-btn" onClick={() => navigate("/home")}>
                  Home
                </button>
                <button className="logout-btn" onClick={logOut}>
                  Log Out
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export { ProfilePage };
