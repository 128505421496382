import {
  Avatar,
  Button,
  Popover,
  Section,
  Variants,
  Text,
  Flexbox,
  Sentiments,
} from "@sede-x/shell-ds-react-framework";
import "./navbar.scss";
import {
  ChevronDown,
  LogOut,
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import { Menu } from "@sede-x/shell-ds-react-framework";
import { useNavigate } from "react-router-dom";

export const HeaderProfile = (props) => {
  const navigate = useNavigate();
  const profileOptions = [
    {
      label: "Profile",
      key: "profile",
    },
    {
      label: "Preference",
      key: "preference",
    },
  ];
  const { profile, logout } = props || {};
  const firstName = profile.UserFirstName || "";
  const lastName = profile.UserLastName || "";
  let fullName = "";
  let abbreviation = "";
  if (firstName && lastName) {
    fullName = lastName + ", " + firstName;
    abbreviation = lastName?.charAt(0) + firstName?.charAt(0);
  }
  const onLogoutClick = () => {
    logout();
  };

  const onSelect = (e) => {
    const path = e?.key;
    if (path) {
      navigate(`/${path}`);
    }
  };

  const profileDropdown = (
    <Section>
      <div className="profile-items">
        <div className="msip-hdr-profile-dp-div">
          <Menu items={profileOptions} onSelect={onSelect} />
          <div className="msip-hdr-profile-divider" />
          <Button
            aria-label="LogOut"
            sentiment={Sentiments.Negative}
            variant={Variants.Transparent}
            icon={<LogOut />}
            size="small"
            onClick={onLogoutClick}
          >
            LogOut
          </Button>
        </div>
      </div>
    </Section>
  );

  return (
    <Popover
      className="profile-popover"
      popupPlacement="bottomRight"
      popup={profileDropdown}
    >
      <Button
        variant={Variants.Transparent}
        icon={<ChevronDown />}
        iconPosition="right"
      >
        <Flexbox gap="5px">
          <Avatar size="medium" abbreviation={abbreviation}></Avatar>
          <div className="profile-info">
            <Text
              size="small"
              className="msip-hdr-profile-dp-font msip-font-weight"
            >
              {fullName}
            </Text>
            <Text
              size="small"
              className="msip-hdr-profile-dp-font msip-hdr-profile-dp-color-grey"
            >
              {profile?.UserEmail}
            </Text>
            <Text
              size="small"
              className="msip-hdr-profile-dp-font msip-hdr-profile-dp-color-grey"
            >
              [{profile?.InternalUserId}]
            </Text>
          </div>
        </Flexbox>
      </Button>
    </Popover>
  );
};
