import { Input, RangeSlider } from "@progress/kendo-react-inputs";
import React, { useState } from "react";

export const SliderRangeComponent = (props) => {
  const { sliderValue, differenceVal, setSliderValue } = props;
  const [value, setValue] = useState(sliderValue);

  const [currentEvent, setCurrentEvent] = useState();

  const onSliderChange = (event, type) => {
    const inputValue = event.target.value;
    const regex = /^(-?\d+|\d+)$/;
    const valid = regex.test(inputValue);
    const inputRange =
      inputValue >= differenceVal.min && inputValue <= differenceVal.max;
    if ((valid && inputRange) || inputValue === "-" || inputValue === "") {
      setValue((prev) => ({
        ...prev,
        [type]: inputValue,
      }));
    }
  };

  return (
    <span className="filter">
      <label htmlFor="Threshold">{props?.label}</label>
      <span className="filter-row">
        <span>
          <Input
            value={value.start}
            onChange={(e) => {
              onSliderChange(e, "start");
            }}
          />
        </span>
        <span>
          <Input
            value={value.end}
            onChange={(e) => {
              onSliderChange(e, "end");
            }}
          />
        </span>
      </span>
      <span
        onTouchEnd={() => {
          setSliderValue({
            start: Math.round(currentEvent?.value?.start),
            end: Math.round(currentEvent?.value?.end),
          });
        }}
      >
        <RangeSlider
          defaultValue={value}
          value={value}
          step={1}
          min={differenceVal.min}
          max={differenceVal.max}
          onChange={(event) => {
            setValue({
              start: Math.round(event?.value?.start),
              end: Math.round(event?.value?.end),
            });
            setCurrentEvent(event);
          }}
        ></RangeSlider>
      </span>
    </span>
  );
};
