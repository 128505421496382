import { DropDownList } from "@progress/kendo-react-dropdowns";
import { addAllItem } from "../../reportUtils";
import { Button } from "@progress/kendo-react-buttons";
import { DECIMAL, isDachCluster, ShipToLinkCell } from "../common-utils";

export const defaultOption = "---- select ----";
const documentDateLbl = "Document Date";
const materialNo = "Material No";
const outBoundDeliveryNo = "OutBound Delivery No";
const calcDeliveryLbl = "Calculated Delivery";
const detectedDeliveryLbl = "Detected Delivery";
const confirmedDeliveryLbl = "Confirmed Delivery";

const getAllSlocs = (sites) => {
  let slocs = [];
  sites?.forEach((siteData) => {
    if (siteData?.sloc) {
      const isExist = slocs?.find((item) => item.id === siteData?.sloc);
      if (!isExist) {
        const sloc = {
          id: siteData?.sloc,
          text: siteData?.sloc,
        };
        slocs = [...slocs, sloc];
      }
    }
  });

  return slocs;
};

export const getRegionSiteIds = (regions, selectedRegion) => {
  const region = regions?.find(
    (regionData) => regionData?.region === selectedRegion
  );
  if (region) {
    const shipTos = region?.sites?.map((item) => ({
      id: item?.globalSiteId,
      text: item?.globalSiteId,
    }));
    return shipTos?.length > 0 ? shipTos : [];
  }
  return [];
};

export const getRegionForSiteId = (regions, siteId) => {
  let region = null;
  regions?.some((regionData) => {
    const currentSites = regionData?.sites;
    const siteFound = currentSites?.find(
      (site) => site?.globalSiteId === siteId
    );
    if (siteFound) {
      region = regionData?.region;
      return true;
    }
    return false;
  });
  if (region) {
    return [
      {
        id: region?.toUpperCase(),
        text: region?.toUpperCase(),
      },
    ];
  }
  return [];
};

const allRegionsSites = (filterData) => {
  let sites = [];
  filterData?.forEach((regionData) => {
    const currentSites = regionData?.sites;
    if (currentSites?.length > 0) {
      sites = [...sites, ...currentSites];
    }
  });

  return sites;
};

const getAllPlants = (sites) => {
  let plants = [];
  sites?.forEach((siteData) => {
    if (siteData?.plant) {
      const isExist = plants?.find((item) => item.id === siteData?.plant);
      if (!isExist) {
        const plant = {
          id: siteData?.plant,
          text: siteData?.plant,
        };
        plants = [...plants, plant];
      }
    }
  });

  return plants;
};

export const getSlocValues = (filterData, filters = null) => {
  const selectedRegion = filters?.selectedRegions?.[0];
  if (!selectedRegion) {
    return [];
  } else {
    let slocs = [];
    const sites = getPlantAndSlocSites(selectedRegion, filterData, filters);
    const selectedShipTo = filters?.selectedShipTo?.[0]?.id;

    if (selectedShipTo === "all") {
      slocs = getAllSlocs(sites);
    } else {
      slocs = getSlocsForSites(filters, sites, slocs);
    }
    slocs = slocs?.length > 1 ? addAllItem(slocs) : slocs;
    return slocs;
  }
};
export const getPlantValues = (
  filterData,
  filters = null,
  addAllOption = true
) => {
  const selectedRegion = filters?.selectedRegions?.[0];
  if (!selectedRegion) {
    return [];
  } else {
    let plants = [];
    const sites = getPlantAndSlocSites(selectedRegion, filterData, filters);

    const selectedShipTo = filters?.selectedShipTo?.[0]?.id;

    if (selectedShipTo === "all") {
      plants = getAllPlants(sites);
    } else {
      plants = getPlantsForSites(filters, sites, plants);
    }
    return plants?.length > 1 && addAllOption ? addAllItem(plants) : plants;
  }
};

const DropDownCell = (props) => {
  const {
    dataItem,
    field,
    onChange,
    commentsOptions,
    disabled = false,
  } = props;

  const handleDropDownChange = (event) => {
    onChange({
      dataItem: { ...dataItem, [field]: event.target.value },
      field: field,
      syntheticEvent: event,
    });
  };
  const options = commentsOptions;
  return (
    <td className="dropDownCellContainer">
      <DropDownList
        data={options}
        value={dataItem?.[field] || defaultOption}
        onChange={handleDropDownChange}
        disabled={disabled}
      />
    </td>
  );
};

export const CustomHeaderCell = (props) => {
  const columnTitle = props?.columnMenuWrapperProps?.column?.title;
  const classNames = "k-customHeader";
  return (
    <th {...props}>
      <div className={classNames} title={props?.label || columnTitle}>
        {columnTitle}
        {props.children}
      </div>
    </th>
  );
};

export const colsToShow = (
  commentsOptions,
  handleShipToLinkAction
) => {
  const columns = [
    {
      title: documentDateLbl,
      field: "documentDate",
      headerCell: CustomHeaderCell,
      filterable: true,
      sortable: true,
    },
    {
      title: "Ship To",
      field: "shipTo",
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return (
          <ShipToLinkCell {...props} linkAction={handleShipToLinkAction} />
        );
      },
      filterable: true,
      sortable: true,
    },
    {
      title: "Plant",
      field: "plant",
      headerCell: CustomHeaderCell,
      filterable: true,
      sortable: true,
    },
    {
      title: "SLOC",
      field: "sloc",
      filterable: true,
      sortable: true,
    },
    {
      title: materialNo,
      field: "materialNumber",
      headerCell: CustomHeaderCell,
      filterable: true,
      sortable: true,
    },
    {
      title: outBoundDeliveryNo,
      field: "outBoundDeliveryId",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_EIGHT_FIVE,
      filterable: true,
      sortable: true,
    },
    {
      title: isDachCluster() ? calcDeliveryLbl : detectedDeliveryLbl,
      field: "calculatedDelivery",
      headerCell: CustomHeaderCell,
      filterable: true,
      sortable: true,
    },
    {
      title: confirmedDeliveryLbl,
      field: "confirmedDelivery",
      headerCell: CustomHeaderCell,
      filterable: true,
      sortable: true,
    },
    {
      title: "Difference",
      field: "difference",
      headerCell: CustomHeaderCell,
      filterable: true,
      sortable: true,
    },
    {
      title: "Comment",
      field: "comment",
      sortable: true,
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return <DropDownCell {...props} commentsOptions={commentsOptions} />;
      },
      width: window.innerWidth * DECIMAL.POINT_ONE_TWO,
    },
  ];

  return columns;
};

export const auditColsToShow = (handleShipToLinkAction) => {
  const columns = [
    {
      title: documentDateLbl,
      field: "documentDate",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_SIX,
      filterable: true,
      sortable: true,
    },
    {
      title: "Ship To",
      field: "shipTo",
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return (
          <ShipToLinkCell {...props} linkAction={handleShipToLinkAction} />
        );
      },
      width: window.innerWidth * DECIMAL.POINT_ZERO_SIX,
      filterable: true,
      sortable: true,
    },
    {
      title: materialNo,
      field: "materialNumber",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_SIX,
      filterable: true,
      sortable: true,
    },
    {
      title: "Tank",
      field: "tankId",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_SIX,
      filterable: true,
      sortable: true,
    },
    {
      title: "Entity",
      field: "entity",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_EIGHT,
      filterable: true,
      sortable: true,
    },
    {
      title: outBoundDeliveryNo,
      field: "outBoundDeliveryId",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_NINE,
      filterable: true,
      sortable: true,
    },
    {
      title: confirmedDeliveryLbl,
      field: "confirmedDelivery",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_SEVEN,
      filterable: true,
      sortable: true,
    },
    {
      title: "Comment",
      field: "comment",
      headerCell: CustomHeaderCell,
      sortable: true,
    },
    {
      title: "Modified By",
      field: "userName",
      filterable: true,
      sortable: true,
      editable: false,
      headerCell: CustomHeaderCell,
    },
    {
      title: "Modified On",
      field: "modifiedOn",
      filterable: true,
      sortable: true,
      editable: false,
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_EIGHT,
    },
  ];

  return columns;
};

export const colsToExport = () => {
  const columns = [
    {
      title: "Plant",
      field: "plant",
    },
    {
      title: documentDateLbl,
      field: "documentDate",
    },
    {
      title: "Ship To",
      field: "shipTo",
    },
    {
      title: "SLOC",
      field: "sloc",
    },
    {
      title: materialNo,
      field: "materialNumber",
    },
    {
      title: "OutBound Delivery Id's",
      field: "outBoundDeliveryId",
    },
    {
      title: "Tank /Material Level",
      field: "type",
    },
    {
      title: "Delivery Start Date",
      field: "deliveryStartDate",
    },
    {
      title: "Start Temperature",
      field: "startTemperature",
    },
    {
      title: "Delivery End Date",
      field: "deliveryEndDate",
    },
    {
      title: "End Temperature",
      field: "endTemperature",
    },
    {
      title: "Tank",
      field: "tank",
    },
    {
      title: isDachCluster() ? calcDeliveryLbl : detectedDeliveryLbl,
      field: "calculatedDelivery",
    },
    {
      title: confirmedDeliveryLbl,
      field: "confirmedDelivery",
    },
    {
      title: "Difference",
      field: "difference",
    },
    {
      title: "Comment",
      field: "comment",
    },
  ];

  return columns;
};

const EditDeliveryCell = (props) => {
  const { rowItem, field, dataItem } = props;
  const title = dataItem?.[field];
  const EditIcon = require("../../../../../assets/edit-icon.png");
  const className = title === "Edit" ? "edit-btn" : "cancel-btn";
  const saveEnabled = dataItem?.saveEnabled;
  return (
    <td style={{ paddingTop: "0.8vh" }}>
      <Button
        className={className}
        disabled={!saveEnabled && title === "Save"}
        onClick={(event) => {
          if (title === "Save") {
            const x = event.clientX;
            const y = event.clientY;
            const offset = {
              left: x,
              top: y,
            };
            props?.onSave(rowItem, dataItem, offset);
          } else {
            props?.onEdit(rowItem, dataItem);
          }
        }}
      >
        {title === "Edit" ? (
          <img
            className="editIcon"
            src={EditIcon}
            alt="Edit"
            title={"Edit Details"}
          />
        ) : (
          title
        )}
      </Button>
    </td>
  );
};

const CancelDeliveryCell = (props) => {
  const { rowItem, field, dataItem } = props;
  const title = dataItem?.[field];
  const className = "cancel-btn";
  return title === "Cancel" ? (
    <td style={{ paddingTop: "0.8vh" }}>
      <Button
        className={className}
        onClick={() => {
          props?.onCancel(rowItem, dataItem);
        }}
      >
        {title}
      </Button>
    </td>
  ) : (
    <td></td>
  );
};

export const detailColsToShow = ({
  rowItem,
  onEdit,
  onSave,
  onCancel,
}) => {
  const columns = [
    {
      title: "Delivery Start Time",
      field: "deliveryStartDate",
      headerCell: CustomHeaderCell,
      editable: false,
    },
    {
      title: "Start Temperature",
      field: "startTemperature",
      editable: false,
      headerCell: CustomHeaderCell,
    },
    {
      title: "Delivery End Time",
      field: "deliveryEndDate",
      editable: false,
      headerCell: CustomHeaderCell,
    },
    {
      title: "End Temperature",
      field: "endTemperature",
      editable: false,
      headerCell: CustomHeaderCell,
    },
    {
      title: outBoundDeliveryNo,
      field: "outBoundDeliveryId",
      headerCell: CustomHeaderCell,
      editable: true,
      editor: "text",
    },
    {
      title: "Tank",
      field: "tank",
      editable: false,
      headerCell: CustomHeaderCell,
    },
    {
      title: isDachCluster() ? calcDeliveryLbl : detectedDeliveryLbl,
      field: "calculatedDelivery",
      headerCell: CustomHeaderCell,
      editable: false,
    },
    {
      title: confirmedDeliveryLbl,
      field: "confirmedDelivery",
      headerCell: CustomHeaderCell,
      editable: true,
      editor: "text",
    },
    {
      title: "Difference",
      field: "difference",
      editable: false,
      headerCell: CustomHeaderCell,
    },
    {
      title: "",
      field: "title",
      width: window.innerWidth * DECIMAL.POINT_ZERO_THREE_FIVE,
      headerCell: () => <></>,
      cell: (props) => {
        return (
          <EditDeliveryCell
            {...props}
            rowItem={rowItem}
            onEdit={onEdit}
            onSave={onSave}
          />
        );
      },
    },
    {
      title: "",
      field: "subTitle",
      headerCell: () => <></>,
      cell: (props) => {
        return (
          <CancelDeliveryCell
            {...props}
            rowItem={{}}
            childItem={{}}
            onCancel={onCancel}
          />
        );
      },
    },
  ];

  return columns;
};

const getPlantsForSites = (filters, sites, plants) => {
  filters?.selectedShipTo?.forEach((shipTo) => {
    const siteData = sites?.find(
      (site) => site?.globalSiteId === shipTo.id
    );
    if (siteData?.plant) {
      const isExist = plants?.find((item) => item.id === siteData?.plant);
      if (!isExist) {
        const plant = {
          id: siteData?.plant,
          text: siteData?.plant,
        };
        plants = [...plants, plant];
      }
    }
  });
  return plants;
};

const getPlantAndSlocSites = (selectedRegion, filterData, filters) => {
  let sites = [];
  if (selectedRegion.id === "all") {
    sites = allRegionsSites(filterData);
  } else {
    filters?.selectedRegions?.forEach((currentRegion) => {
      const regionData = filterData?.find(
        (region) => region.region === currentRegion.id
      );
      const currentSites = regionData?.sites;
      if (currentSites?.length > 0) {
        sites = [...sites, ...currentSites];
      }
    });
  }
  return sites;
};

const getSlocsForSites = (filters, sites, slocs) => {
  filters?.selectedShipTo?.forEach((shipTo) => {
    const siteData = sites?.find(
      (site) => site?.globalSiteId === shipTo.id
    );
    if (siteData?.sloc) {
      const isExist = slocs?.find((item) => item.id === siteData?.sloc);
      if (!isExist) {
        const sloc = {
          id: siteData?.sloc,
          text: siteData?.sloc,
        };
        slocs = [...slocs, sloc];
      }
    }
  });
  return slocs;
};
