import React, {useState, useRef} from "react";
import { Popup } from "@progress/kendo-react-popup";
import { Error } from "@progress/kendo-react-all";

const TextInlineEditCell = (props) => {
  const [focused, setFocused] = useState(false);
  const anchor = useRef(null);
  const isValid = props.dataItem[props.field + "Valid"];
  return props.tdProps ? (
    <>
      <td
        ref={anchor}
        {...props.tdProps}
        style={{
          ...props.tdProps.style,
        }}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
      >
        {React.Children.toArray(props.children).map((child) => {
          return React.cloneElement(child, {
            className:
              child.props.className +
              (isValid ? " k-input-solid k-invalid" : ""),
          });
        })}
      </td>
      {props.dataItem.inEdit && focused && (
        <Popup
          anchor={anchor.current}
          show={!!isValid}
          popupClass={"popup-content"}
        >
          <Error>{isValid}</Error>
        </Popup>
      )}
    </>
  ) : null;
};

export { TextInlineEditCell };
