import { useEffect, useState } from "react";
import { userService } from "../../../../../services/user";
import { siteService } from "../../../../../services/sites";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import "../../reports.scss";
import { DEFAULT_PAGE_SIZE } from "../common-utils";
import UserEditDetailsContainer from "./user-edit-detail-screen";

const EditUserScreen = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [siteIds, setSiteIds] = useState([]);

  const fetchAllUsers = async () => {
    const userList = await userService.fetchAuditList();
    setUsers(userList);
  };
  const fetchUserRoles = async () => {
    const roleList = await userService.fetchUserRoles();
    setRoles(roleList);
  };

  const fetchAllSiteIds = async () => {
    const siteIdList = await siteService.fetchSiteIdList("DE");
    setSiteIds(siteIdList);
  };

  useEffect(() => {
    fetchAllUsers();
    fetchUserRoles();
    fetchAllSiteIds();
  }, []);

  const colsToShow = [
    {
      title: "First Name",
      field: "UserFirstName",
      filterable: true,
      sortable: true,
    },
    {
      title: "Last Name",
      field: "UserLastName",
      filterable: true,
      sortable: true,
    },
    {
      title: "Email",
      field: "UserEmail",
      filterable: true,
      sortable: true,
    },
    {
      title: "Is Active",
      field: "IsActive",
      filterable: true,
      sortable: true,
    },
    {
      title: "Role",
      field: "RoleName",
      filterable: true,
      sortable: true,
    },
  ];

  return (
    <>
      <div className="container data-grid">
        <div className="row ">
          <div className="data-grid-container">
            <AppDataGrid
              exportable={true}
              exportFileName="Users Information"
              style={{ height: "71.5vh" }}
              take={DEFAULT_PAGE_SIZE}
              pageable={true}
              filterable={true}
              expandable={true}
              sortable={true}
              data={users}
              detailsContainer={(props) => (
                <UserEditDetailsContainer {...props} roles={roles} siteIds={siteIds} />
              )}
              columnsToShow={colsToShow}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { EditUserScreen };
