import React, { useEffect, useRef, useState } from "react";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { Loader } from "@progress/kendo-react-indicators";
import "./out-of-tolerance-trend-analysis.scss";
import { GridToolbar } from "@progress/kendo-react-grid";
import {
  TrendViewMode,
  colsToShow,
} from "./out-of-tolerance-trend-analysis-utils";
import {
  getAllSelectedOptions,
  getLastDates,
  getMaterials,
  getFilterRegions,
  getShipTos,
  getTanks,
  getYesOrNoValues,
  getSelectedRegions,
  getOBNs,
} from "../../reportUtils";
import {
  MultiSelectionDropDown,
  multiSelectionDropDownFields,
} from "../../../../components/multi-select-dropdown/multi-select-dropdown";
import { getMultiSelectTreeValue } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import { reportsService } from "../../../../../services/reports";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../../../../state-management/store";
import { triggerNotification } from "../../../../../state-management/actions";
import { SiteInfoScreen } from "./out-of-tolerance-site-info";
import {
  DECIMAL,
  DEFAULT_PAGE_SIZE,
  getOOTASourceName,
  isBFXCluster,
  isDachCluster,
  NUMBERS,
} from "../common-utils";
import { DatePicker } from "@progress/kendo-react-all";
import {
  getDaysBeforeDate,
  getFormattedDate,
  serverError,
  unableToFetch,
} from "../../../../../services/utils";
import { Switch } from "@progress/kendo-react-inputs";
import { TabComponent } from "../../../../components/tab-component/tab-component";

const DropDownSelection = {
  ShipTo: 0,
  OBN: 1,
};

const OutOfToleranceTrendAnalysis = (props) => {
  const [originalData, setOriginalData] = useState([]);
  const [changedItems, setChangedItems] = useState([]);
  const [countValues, setCountValues] = useState({
    gainCount: 0,
    lossCount: 0,
    fiveCumVariance: 0,
    fiveCumThruPut: 0,
    thirtyCumVariance: 0,
    thirtyCumThruPut: 0,
  });
  const [currentField, setCurrentField] = useState(null);

  const [historicalViewEnabled, setHistoricalViewEnabled] = useState(false);
  const [trendAnalysisData, setTrendAnalysisData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [commentsOptions, setCommentsOptions] = useState([]);
  const [masterDataLoading, setMasterDataLoading] = useState(true);
  const [blankCount, setBlankCount] = useState(0);
  const [missingCommentsCount, setMissingCommentsCount] = useState(0);
  const [missingOutOfToleranceCount, setMissingOutOfToleranceCount] =
    useState(0);
  const exportGridRef = useRef(null);
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const defaultCountry = useSelector((state) => state?.selectedCountry);
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);
  const [resetIndex, setResetIndex] = useState(false);
  store.subscribe(() => {
    const { selectedCountry } = store.getState();
    setCurrentCountryCode(selectedCountry);
  });

  const [filters, setFilters] = useState({
    selectedDate: getLastDates(
      NUMBERS.ONE,
      isDachCluster() ? NUMBERS.TWO : NUMBERS.ONE
    ),
    selectedDateVal: null,
    selectedDates: {
      start: getDaysBeforeDate(isDachCluster() ? NUMBERS.TEN : NUMBERS.THIRTY),
      end: getDaysBeforeDate(NUMBERS.ONE),
    },
    dates: getLastDates(
      NUMBERS.FOUR,
      isDachCluster() ? NUMBERS.TWO : NUMBERS.ONE
    ),
    selectedRegions: getSelectedRegions(filterData, false),
    regions: getFilterRegions(filterData),
    selectedShipTo: [],
    shipTos: [],
    selectedOBN: [],
    allOBNs: [],
    selectedTanks: [],
    tanks: getTanks(filterData),
    selectedMaterial: [],
    materials: getMaterials(filterData),
    selectedComments: [],
    comments: [],
    selectedDay250: [getYesOrNoValues()[0]],
    oneDayGain: getYesOrNoValues(),
    selectedOneDayMins: [getYesOrNoValues()[0]],
    oneDayLoss: getYesOrNoValues(),
    selectedFiveDCumulVar: [getYesOrNoValues()[0]],
    isFiveDaysCumulative: getYesOrNoValues(),
    selectedFiveDCumulTh: [getYesOrNoValues()[0]],
    isFiveDaysCumulativePercent: getYesOrNoValues(),
    selectedThirtyDaysCumul: [getYesOrNoValues()[0]],
    isThirtyDaysCumulative: getYesOrNoValues(),
    selectedThirtyDaysCumulPercentage: [getYesOrNoValues()[0]],
    isThirtyDaysCumulativePercent: getYesOrNoValues(),
  });

  const [appliedFilters, setAppliedFilters] = useState(null);
  const [exporting, setExporting] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedTab, setSelectedTab] = useState(TrendViewMode.MATERIAL);
  const [reportConfig, setReportConfig] = useState();
  const [showSiteInfoModal, setShowSiteInfoModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState();

  const handleShipToLinkAction = (siteId, country) => {
    setSelectedSite({ siteId: siteId, country: country });
    setShowSiteInfoModal(true);
  };

  useEffect(() => {
    setTrendAnalysisData([]);
  }, [historicalViewEnabled]);

  useEffect(() => {
    setBlankCount(0);
    setMissingCommentsCount(0);
    setMissingOutOfToleranceCount(0);
  }, [fetchingData]);

  const fetchReportData = async () => {
    try {
      const response = await reportsService.getRestoreData();
      setFetchingData(false);
      if (response) {
        const ootReportConfig = response?.find(
          (item) => item?.reportCode === "oot-daily"
        );
        setReportConfig(ootReportConfig);
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: unableToFetch,
          })
        );
      }
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: serverError,
        })
      );
      setFetchingData(false);
    }
  };

  useEffect(() => {
    fetchReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const startDate = new Date(reportConfig?.restoreStartDate);
    const endDate = new Date(reportConfig?.restoreEndDate);

    if (startDate && endDate) {
      setFilters((prev) => {
        return {
          ...prev,
          selectedDates: {
            start: startDate,
            end: endDate,
          },
          selectedDateVal: endDate,
        };
      });
    }
  }, [reportConfig]);

  const closeModal = () => {
    setShowSiteInfoModal(false);
  };

  const [dateSelectedValues, setDateSelectedValues] = useState(
    filters.selectedDate
  );

  const [regionSelectedValues, setRegionSelectedValues] = useState(
    filters.selectedRegions
  );

  const [shipToSelectedValues, setShipToSelectedValues] = useState(
    filters.selectedShipTo
  );

  const [obnSelectedValues, setObnSelectedValues] = useState(
    filters.selectedOBN
  );

  const [tankSelectedValues, setTankSelectedValues] = useState(
    filters.selectedTanks
  );

  const [materialSelectedValues, setMaterialSelectedValues] = useState(
    filters.selectedMaterial
  );

  const [commentSelectedValues, setCommentSelectedValues] = useState(
    filters.selectedComments
  );

  const [day250SelectedValues, setDay250SelectedValues] = useState(
    filters.selectedDay250
  );

  const [oneDayMinsSelectedValues, setOneDayMinsSelectedValues] = useState(
    filters.selectedOneDayMins
  );

  const [fiveDCumVarSelectedValues, setFiveDCumVarSelectedValues] = useState(
    filters.selectedFiveDCumulVar
  );

  const [fiveDCumThSelectedValues, setFiveDCumThSelectedValues] = useState(
    filters.selectedFiveDCumulTh
  );

  const [thirtyDaysCumulSelectedValues, setThirtyDaysCumulSelectedValues] =
    useState(filters.selectedThirtyDaysCumul);

  const [
    thirtyDaysCumulPercentageSelectedValues,
    setThirtyDaysCumulPercentageSelectedValues,
  ] = useState(filters.selectedThirtyDaysCumulPercentage);

  const fetchTrendAnalysisReport = async (refresh = false) => {
    const filter = refresh ? appliedFilters : filters;

    setFetchingData(true);
    setChangedItems([]);
    const regions = filter.selectedRegions?.map((region) => region?.text);

    let payload = {
      countryCode: currentCountryCode,
      isHistoricalView: historicalViewEnabled,
      isMaterialView: selectedTab === TrendViewMode.MATERIAL,
      date: filter.selectedDate?.[0]?.text,
      regions: regions,
      shipTos: filter.selectedShipTo?.map((shipTo) => shipTo.text),
      tankIds:
        selectedTab === TrendViewMode.MATERIAL
          ? []
          : filter.selectedTanks?.map((tank) => tank.text),
      materialNumbers: filter.selectedMaterial?.map(
        (material) => material.text
      ),
      comment: filter.selectedComments?.map((comment) => comment.text),
      oneDayPlus: filter.selectedDay250?.[0]?.text,
      oneDayMinus: filter.selectedOneDayMins?.[0]?.text,
      fiveDaysCumul: filter.selectedFiveDCumulVar?.[0]?.text,
      fiveDaysCumulPercentage: filter.selectedFiveDCumulTh?.[0]?.text,
      thirtyDaysCumul: filter.selectedThirtyDaysCumul?.[0]?.text,
      thirtyDaysCumulPercentage:
        filter?.selectedThirtyDaysCumulPercentage?.[0]?.text,
      sourceName: getOOTASourceName(currentCountryCode),
    };

    if (historicalViewEnabled) {
      payload = {
        ...payload,
        date: getFormattedDate(filters?.selectedDateVal),
      };
    }
    try {
      const response = await reportsService.fetchToleranceTrendDataV2(
        payload,
        historicalViewEnabled
      );

      setFetchingData(false);
      if (response) {
        if (!refresh) {
          setAppliedFilters(filter);
        }
        response.sort((a, b) => a.materialNo - b.materialNo);
        response.sort((a, b) => a.sloc - b.sloc);
        response.sort((a, b) => a.shipTo - b.shipTo);

        const count = {};
        const missingCount = {};
        let updatedMissingCommentsCount = 0;
        const trendData = response?.map((dataItem) => {
          let disabled = false;
          if (!dataItem.comment) {
            updatedMissingCommentsCount += 1;
          }
          const datesArray = Object.fromEntries(
            dataItem?.dates.map((dateObj, index) => {
              let value = dateObj.value;
              if (index === 0) {
                disabled = true;
              }
              if (!dateObj.value && dateObj.value !== 0) {
                value = "";
                const current = count?.[`day${index}`]
                  ? count?.[`day${index}`]
                  : 0;
                count[`day${index}`] = current + 1;
              }
              if (dateObj?.missingOutOfTolerance) {
                const current = missingCount?.[`day${index}`]
                  ? missingCount?.[`day${index}`]
                  : 0;
                missingCount[`day${index}`] = current + 1;
              }
              value = value ? Math.round(value) : value;
              return [[`day${index}`], value];
            })
          );
          return {
            ...dataItem,
            ...datesArray,
            country: dataItem?.country || currentCountryCode,
            fiveDaysTotal: Math.round(dataItem?.fiveDaysTotal),
            tenDaysTotal: Math.round(dataItem?.tenDaysTotal),
            thirtyDaysTotal: Math.round(dataItem?.thirtyDaysTotal),
            disabled,
          };
        });
        setBlankCount(count);
        setMissingCommentsCount(updatedMissingCommentsCount);
        setMissingOutOfToleranceCount(missingCount);
        setOriginalData(trendData ? trendData : []);
        setTrendAnalysisData(trendData ? trendData : []);

        const counterItems = {
          gainCount: 0,
          lossCount: 0,
          fiveCumVariance: 0,
          fiveCumThruPut: 0,
          thirtyCumVariance: 0,
          thirtyCumThruPut: 0,
        };

        trendData?.forEach((item) => {
          if (item?.oneDayGain === "Y") {
            counterItems.gainCount += 1;
          }
          if (item?.oneDayLoss === "Y") {
            counterItems.lossCount += 1;
          }
          if (item?.isFiveDaysCumulative === "Y") {
            counterItems.fiveCumVariance += 1;
          }
          if (item?.isFiveDaysCumulativePercent === "Y") {
            counterItems.fiveCumThruPut += 1;
          }
          if (item?.isThirtyDaysCumulative === "Y") {
            counterItems.thirtyCumVariance += 1;
          }
          if (item?.isThirtyDaysCumulativePercent === "Y") {
            counterItems.thirtyCumThruPut += 1;
          }
        });
        setCountValues(counterItems);
        setFetchingData(false);
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: unableToFetch,
          })
        );
        setFetchingData(false);
      }
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: serverError,
        })
      );
      setFetchingData(false);
    }
  };

  const hasEmptyValues = () => {
    const selected1 =
      filters?.selectedDate?.length === 0 ||
      filters?.selectedRegions?.length === 0 ||
      filters?.selectedShipTo?.length === 0;

    const selected2 =
      filters?.selectedMaterial?.length === 0 ||
      filters?.selectedComments?.length === 0 ||
      filters?.selectedDay250?.length === 0;

    const selected3 =
      filters?.selectedOneDayMins?.length === 0 ||
      filters?.selectedFiveDCumulVar?.length === 0 ||
      filters?.selectedFiveDCumulTh?.length === 0;

    const selected4 =
      filters?.selectedThirtyDaysCumul?.length === 0 ||
      filters?.selectedThirtyDaysCumulPercentage?.length === 0;

    const emptySelection = selected1 || selected2 || selected3 || selected4;

    return emptySelection;
  };

  const loadData = (modeChanged = false) => {
    let emptySelection = hasEmptyValues();

    emptySelection =
      emptySelection ||
      (selectedTab === 1 && filters?.selectedTanks?.length === 0);
    if (!emptySelection && modeChanged) {
      fetchTrendAnalysisReport();
    }
  };

  const fetchMasterData = async () => {
    setMasterDataLoading(true);
    const masterData = await reportsService.fetchToleranceTrendMasterData({
      country: currentCountryCode,
    });
    setFilterData(masterData || []);
    setMasterDataLoading(false);
  };
  const fetchCommentsMasterData = async () => {
    setMasterDataLoading(true);
    const response = await reportsService.fetchCommentsMasterData();
    const options = response?.map((option) => option?.categoryText);
    setCommentsOptions(options || []);
    setMasterDataLoading(false);
    setFetchingData(true);
  };

  useEffect(() => {
    const selectedDate = getLastDates(
      NUMBERS.ONE,
      isDachCluster() ? NUMBERS.TWO : NUMBERS.ONE
    );
    setFilters((prev) => {
      return {
        ...prev,
        selectedDate: selectedDate,
        dates: getLastDates(
          NUMBERS.FOUR,
          isDachCluster() ? NUMBERS.TWO : NUMBERS.ONE
        ),
        selectedRegions: [],
      };
    });
    fetchMasterData();
    fetchCommentsMasterData();
    setChangedItems([]);
    setTrendAnalysisData([]);
    setBlankCount(0);
    setMissingCommentsCount(0);
    setMissingOutOfToleranceCount(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryCode]);

  useEffect(() => {
    loadData(true);
    setChangedItems([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    setTrendAnalysisData([]);
    setOriginalData([]);
  }, [selectedTab]);

  useEffect(() => {
    let comments = commentsOptions?.map((option) => ({
      id: option,
      text: option,
    }));
    comments = [{ id: "all", text: "All" }, ...comments];
    const selectedComment = comments?.[0];
    setFilters((prev) => {
      return {
        ...prev,
        comments: comments,
        selectedComments: selectedComment ? [selectedComment] : [],
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsOptions]);

  useEffect(() => {
    const dates = getLastDates(
      NUMBERS.FOUR,
      isDachCluster() ? NUMBERS.TWO : NUMBERS.ONE
    );
    const selectedDate = getLastDates(
      NUMBERS.ONE,
      isDachCluster() ? NUMBERS.TWO : NUMBERS.ONE
    );
    const regions = getFilterRegions(filterData, true, currentCountryCode);
    const selectedRegions = regions?.length > 0 ? [regions?.[0]] : [];
    setFilters((prev) => {
      return {
        ...prev,
        regions,
        dates,
        selectedDate,
        selectedRegions,
      };
    });
    setFetchingData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    setDateSelectedValues(filters.selectedDate);
    setRegionSelectedValues(filters.selectedRegions);
    const shipTos =
      filters.selectedRegions?.length > 0
        ? getShipTos(filterData, filters.selectedRegions)
        : [];
    const selectedShipTo =
      filters.selectedRegions?.length > 0 ? [shipTos?.[0]] : [];

    const obns =
      filters.selectedRegions?.length > 0
        ? getOBNs(filterData, filters.selectedRegions, true)
        : [];

    const selectedOBN =
      filters.selectedRegions?.length > 0 && obns?.length > 0
        ? [obns?.[0]]
        : [];

    setFilters((prev) => {
      return {
        ...prev,
        shipTos,
        selectedShipTo,
        allOBNs: obns,
        selectedOBN,
      };
    });

    setShipToSelectedValues(selectedShipTo);
    setObnSelectedValues(selectedOBN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedRegions]);

  useEffect(() => {
    const tanks =
      filters.selectedShipTo?.length > 0 ? getTanks(filterData, filters) : [];
    const selectedTanks = tanks?.length > 0 ? [tanks?.[0]] : [];
    const materials =
      filters.selectedShipTo?.length > 0
        ? getMaterials(filterData, filters)
        : [];
    const selectedMaterial = materials?.length > 0 ? [materials[0]] : [];

    setFilters((prev) => {
      return {
        ...prev,
        materials,
        selectedMaterial,
        tanks,
        selectedTanks,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedShipTo]);

  useEffect(() => {
    setMaterialSelectedValues(filters.selectedMaterial);
  }, [filters.selectedMaterial]);

  useEffect(() => {
    setCommentSelectedValues(filters.selectedComments);
  }, [filters.selectedComments]);

  useEffect(() => {
    setTankSelectedValues(filters.selectedTanks);
  }, [filters.selectedTanks]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedDate: dateSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedRegions: regionSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.ShipTo) {
      const siteData = filters?.shipTos?.find(
        (siteInfo) => siteInfo?.id === shipToSelectedValues?.[0]?.id
      );
      const obn = filters?.allOBNs?.find(
        (siteInfo) => siteInfo?.obn === siteData?.obn
      );
      const obns = obn && siteData ? [obn] : [];

      setFilters((prev) => {
        return {
          ...prev,
          selectedShipTo: shipToSelectedValues ? shipToSelectedValues : [],
          selectedOBN: obns,
        };
      });
      setObnSelectedValues(obns);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipToSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.OBN) {
      const siteData = filters?.allOBNs?.find(
        (siteInfo) => siteInfo?.id === obnSelectedValues?.[0]?.id
      );
      const site = filters?.shipTos?.find(
        (siteInfo) => siteInfo?.globalSiteId === siteData?.globalSiteId
      );
      const shipTo = site && siteData ? [site] : [];

      setFilters((prev) => {
        return {
          ...prev,
          selectedShipTo: shipTo,
          selectedOBN: obnSelectedValues ? obnSelectedValues : [],
        };
      });
      setShipToSelectedValues(shipTo);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obnSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedTanks: tankSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tankSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedMaterial: materialSelectedValues,
    }));
    // loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedComments: commentSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedDay250: day250SelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day250SelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedOneDayMins: oneDayMinsSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneDayMinsSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedFiveDCumulVar: fiveDCumVarSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiveDCumVarSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedFiveDCumulTh: fiveDCumThSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiveDCumThSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedThirtyDaysCumul: thirtyDaysCumulSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thirtyDaysCumulSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedThirtyDaysCumulPercentage:
        thirtyDaysCumulPercentageSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thirtyDaysCumulPercentageSelectedValues]);

  const handleDataChange = (e) => {
    const changeItem = e?.dataItem;
    const updatedItems = trendAnalysisData?.map((item, index) => {
      return index === changeItem.id ? e?.dataItem : item;
    });
    setTrendAnalysisData(updatedItems);

    const actualItem = originalData?.find(
      (item) =>
        item.shipTo === changeItem.shipTo &&
        item.tank === changeItem.tank &&
        item.materialNo === changeItem.materialNo
    );

    if (actualItem.comment !== changeItem.comment) {
      const currentItems = changedItems?.filter(
        (item) =>
          !(
            item.shipTo === changeItem.shipTo &&
            item.tank === changeItem.tank &&
            item.materialNo === changeItem.materialNo
          )
      );
      setChangedItems([...currentItems, changeItem]);
    } else {
      const currentItems = changedItems?.filter(
        (item) =>
          !(
            item.shipTo === changeItem.shipTo &&
            item.tank === changeItem.tank &&
            item.materialNo === changeItem.materialNo
          )
      );
      setChangedItems(currentItems);
    }
  };

  const handleRowClick = (e) => {
    const changeItem = e?.dataItem;
    const updatedItems = trendAnalysisData?.map((item, index) => {
      return index === changeItem.id
        ? {
            ...e?.dataItem,
            selected: true,
          }
        : {
            ...item,
            selected: false,
          };
    });
    setTrendAnalysisData(updatedItems);
  };

  const handleRowRender = (event, propValues) => {
    const rowData = propValues.dataItem;
    const backgroundColor = rowData?.selected ? "#eee" : "#fff";
    event.props.style.backgroundColor = backgroundColor;
    return event;
  };

  const createPayload = () => {
    return changedItems?.map((item) => ({
      CountryCode: currentCountryCode,
      Comment: item.comment,
      SiteId: item.shipTo,
      MaterialNo: item.materialNo,
      RequestDate: filters.selectedDate?.[0]?.date,
      UserEmail: store.getState().user.UserEmail,
    }));
  };

  const checkError = (response) => {
    let responseErrors =
      response instanceof Array
        ? response?.filter((data) => data?.StatusCode !== NUMBERS.TWO_HUNDRED)
        : [];
    responseErrors?.forEach((data) => {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: data?.Value,
        })
      );
    });
    if (responseErrors?.length === 0) {
      dispatch(
        triggerNotification({
          type: {
            style: "success",
            icon: true,
          },
          message: "Comments saved successfully.",
        })
      );
      setChangedItems([]);
      fetchTrendAnalysisReport();
    }
  };

  const saveChanges = async () => {
    setSubmitting(true);
    const payload = createPayload();
    const response = await reportsService.saveOOTAReportComment(payload);
    if (
      response?.status >= NUMBERS.TWO_HUNDRED &&
      response?.status < NUMBERS.THREE_HUNDRED
    ) {
      checkError(response?.data);
    } else {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Unable to submit the comments.",
        })
      );
    }
    setSubmitting(false);
  };

  const cancelChanges = async () => {
    setTrendAnalysisData(originalData);
    setChangedItems([]);
  };

  const showHideLoaders = (show = false) => {
    setExporting(show);
  };

  const exportData = async () => {
    showHideLoaders(true);
    await cancelChanges();
    exportGridRef?.current?.exportDataInExcel &&
      (await exportGridRef.current.exportDataInExcel());

    setTimeout(showHideLoaders, 1000);
  };

  const headerButtons = () => {
    const saveColor = changedItems?.length > 0 && "#0092a5";
    const cancelColor = changedItems?.length > 0 && "#fbce07";
    const borderWidth = changedItems?.length > 0 && "0.15vw";
    return (
      <div className="buttonsContainer">
        <div className="buttonsLeftContainer">
          <Button
            className="get-data-btn"
            onClick={fetchData}
            disabled={fetchingData}
          >
            {fetchingData ? "Fetching..." : "Get data"}
          </Button>
          <Button
            className="refresh-btn"
            onClick={() => {
              fetchTrendAnalysisReport(true);
            }}
            disabled={!appliedFilters}
          >
            Refresh
          </Button>
          {selectedTab === TrendViewMode.MATERIAL && (
            <Button
              className="exportButton"
              onClick={exportData}
              disabled={exporting || !trendAnalysisData?.length}
            >
              {exporting ? "Exporting..." : "Export"}
            </Button>
          )}
        </div>
        {selectedTab === TrendViewMode.MATERIAL && (
          <div className="submitButtonContainer">
            <button
              title="Save"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base toolbar-button"
              onClick={saveChanges}
              style={{ borderColor: saveColor, borderWidth: borderWidth }}
              disabled={!changedItems?.length > 0}
            >
              Save
            </button>
            <button
              title="Cancel"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base toolbar-button"
              onClick={cancelChanges}
              style={{ borderColor: cancelColor, borderWidth: borderWidth }}
              disabled={!changedItems?.length > 0}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    );
  };

  const getCumColumnWidth = () => {
    let width =
      selectedTab === TrendViewMode.MATERIAL
        ? DECIMAL.THREE_POINT_FOUR
        : DECIMAL.THREE_POINT_TWO;
    width = isBFXCluster() ? width * DECIMAL.POINT_EIGHT_FIVE : width;
    return `${width}vw`;
  };

  const getCumFlagWidth = () => {
    let width =
      selectedTab === TrendViewMode.MATERIAL
        ? DECIMAL.SIX_POINT_EIGHT
        : DECIMAL.SIX_POINT_FOUR;
    width = isBFXCluster() ? width * DECIMAL.POINT_EIGHT_FIVE : width;
    return `${width}vw`;
  };

  const headerColumns = () => (
    <div className="rightContainer">
      <div className="countContainer">
        <div
          className="counter"
          style={{
            width: getCumColumnWidth(),
          }}
        >
          {countValues.gainCount}
        </div>
        <div
          className="counter"
          style={{
            width: getCumColumnWidth(),
          }}
        >
          {countValues.lossCount}
        </div>
        <div
          className="counter"
          style={{
            width: getCumColumnWidth(),
          }}
        >
          {countValues.fiveCumVariance}
        </div>
        <div
          className="counter"
          style={{
            width: getCumColumnWidth(),
          }}
        >
          {countValues.fiveCumThruPut}
        </div>
        {isBFXCluster() && (
          <span className="countContainer">
            <div
              className="counter"
              style={{
                width: getCumColumnWidth(),
              }}
            >
              {countValues.thirtyCumVariance}
            </div>
            <div
              className="counter"
              style={{
                width: getCumColumnWidth(),
              }}
            >
              {countValues.thirtyCumThruPut}
            </div>
          </span>
        )}
      </div>
      <div className="labelContainer">
        <span
          className="labelFlag"
          style={{
            width: getCumColumnWidth(),
          }}
        >
          1-day Gain flag?
        </span>
        <span
          className="labelFlag"
          style={{
            width: getCumColumnWidth(),
          }}
        >
          1-day Loss flag?
        </span>
        <span className="cumulativeContainer">
          <span
            className="cumulativeFlag"
            style={{
              width: getCumFlagWidth(),
            }}
          >
            5-Day Cumulative?
          </span>
          <span className="labelContainer">
            <span
              className="cumulativeVar"
              style={{
                width: getCumColumnWidth(),
              }}
            >
              +/- 250 Cum. Var
            </span>
            <span
              className="cumulativeVar"
              style={{
                width: getCumColumnWidth(),
              }}
            >
              {isBFXCluster() ? "2% of Throughput" : "3% of Throughput"}
            </span>
          </span>
        </span>
        {isBFXCluster() && (
          <span className="cumulativeContainer">
            <span
              className="cumulativeFlag"
              style={{
                width: getCumFlagWidth(),
              }}
            >
              30-Day Cumulative?
            </span>
            <span className="labelContainer">
              <span
                className="cumulativeVar"
                style={{
                  width: getCumColumnWidth(),
                }}
              >
                +/- 750 Cum. Var
              </span>
              <span
                className="cumulativeVar"
                style={{
                  width: getCumColumnWidth(),
                }}
              >
                2% of Throughput
              </span>
            </span>
          </span>
        )}
      </div>
    </div>
  );

  const onDateSelection = (event) => {
    if (event.operation === "delete") {
      setDateSelectedValues([]);
    } else {
      setDateSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.dates, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onRegionSelection = (event) => {
    if (event.operation === "delete") {
      setRegionSelectedValues([]);
    } else {
      setRegionSelectedValues((prev) => {
        const selectedValues = getAllSelectedOptions(event, prev);
        return getMultiSelectTreeValue(filters?.regions, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        });
      });
    }
  };

  const onShipToSelection = (event) => {
    setCurrentField(DropDownSelection.ShipTo);
    if (event.operation === "delete") {
      setShipToSelectedValues([]);
    } else {
      setShipToSelectedValues((prev) => {
        const selectedValues = getAllSelectedOptions(event, prev);
        return getMultiSelectTreeValue(filters?.shipTos, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        });
      });
    }
  };

  const onOBNSelection = (event) => {
    setCurrentField(DropDownSelection.OBN);
    if (event.operation === "delete") {
      setObnSelectedValues([]);
    } else {
      setObnSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.allOBNs, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onTankSelection = (event) => {
    if (event.operation === "delete") {
      setTankSelectedValues([]);
    } else {
      setTankSelectedValues((prev) => {
        const selectedValues = getAllSelectedOptions(event, prev);
        return getMultiSelectTreeValue(filters?.tanks, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        });
      });
    }
  };

  const onMaterialSelection = (event) => {
    if (event.operation === "delete") {
      setMaterialSelectedValues([]);
    } else {
      setMaterialSelectedValues((prev) => {
        const selectedValues = getAllSelectedOptions(event, prev);
        return getMultiSelectTreeValue(filters?.materials, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        });
      });
    }
  };

  const onCommentSelection = (event) => {
    if (event.operation === "delete") {
      setCommentSelectedValues([]);
    } else {
      setCommentSelectedValues((prev) => {
        const selectedValues = getAllSelectedOptions(event, prev);
        return getMultiSelectTreeValue(filters?.comments, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        });
      });
    }
  };

  const onDay250Selection = (event) => {
    if (event.operation === "delete") {
      setDay250SelectedValues([]);
    } else {
      setDay250SelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.oneDayGain, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onOneDayMinsSelection = (event) => {
    if (event.operation === "delete") {
      setOneDayMinsSelectedValues([]);
    } else {
      setOneDayMinsSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.oneDayLoss, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onFiveDCumVarSelection = (event) => {
    if (event.operation === "delete") {
      setFiveDCumVarSelectedValues([]);
    } else {
      setFiveDCumVarSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.isFiveDaysCumulative, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onFiveDCumThSelection = (event) => {
    if (event.operation === "delete") {
      setFiveDCumThSelectedValues([]);
    } else {
      setFiveDCumThSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.isFiveDaysCumulativePercent, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onThirtyDayCumThSelection = (event) => {
    if (event.operation === "delete") {
      setThirtyDaysCumulSelectedValues([]);
    } else {
      setThirtyDaysCumulSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.isThirtyDaysCumulative, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onThirtyDayCumPercentageSelection = (event) => {
    if (event.operation === "delete") {
      setThirtyDaysCumulPercentageSelectedValues([]);
    } else {
      setThirtyDaysCumulPercentageSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.isThirtyDaysCumulativePercent, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const fetchData = () => {
    let canShowError = hasEmptyValues();

    canShowError =
      canShowError ||
      (selectedTab === 1 && filters?.selectedTanks?.length === 0);

    setShowError(canShowError);
    if (!canShowError) {
      fetchTrendAnalysisReport();
      setResetIndex(true);
    }
  };

  const onDateChange = (e) => {
    const date = new Date(e?.value);
    setFilters((prev) => {
      return {
        ...prev,
        selectedDateVal: date,
      };
    });
  };

  const headerFilters = () => {
    return (
      <div className="header-container">
        <div className="filters-container">
          {historicalViewEnabled ? (
            <span className="filter-date">
              <label htmlFor="region">Date</label>
              <DatePicker
                format={"dd-MMM-yyyy"}
                value={filters?.selectedDateVal}
                onChange={onDateChange}
                max={new Date(reportConfig?.restoreEndDate)}
                min={new Date(reportConfig?.restoreStartDate)}
              />
            </span>
          ) : (
            <span className="filter">
              <label htmlFor="Date">Date</label>
              <MultiSelectionDropDown
                data={filters.dates}
                values={dateSelectedValues}
                onChange={onDateSelection}
                filterable={false}
                showError={showError}
                maxSelection={1}
                customStyle={{ width: "7vw", minHeight: "3vh" }}
              />
            </span>
          )}
          {filters.regions?.length > 1 && (
            <span className="filter">
              <label htmlFor="region">Region</label>
              <MultiSelectionDropDown
                data={filters.regions}
                values={regionSelectedValues}
                onChange={onRegionSelection}
                filterable={false}
                showError={showError}
                customStyle={{
                  width: isBFXCluster() ? "5.5vw" : "9vw",
                  minHeight: "3vh",
                }}
              />
            </span>
          )}

          <span className="filter">
            <label htmlFor="shipTo">OBN</label>
            <MultiSelectionDropDown
              data={filters.allOBNs}
              values={obnSelectedValues}
              onChange={onOBNSelection}
              filterable={true}
              showError={showError}
              customStyle={{ width: "5vw", minHeight: "3vh" }}
              maxSelection={1}
            />
          </span>

          <span className="filter">
            <label htmlFor="shipTo">Ship To</label>
            <MultiSelectionDropDown
              data={filters.shipTos}
              values={shipToSelectedValues}
              onChange={onShipToSelection}
              filterable={true}
              showError={showError}
              customStyle={{ width: "6.5vw", minHeight: "3vh" }}
            />
          </span>
          <span className="filter">
            <label htmlFor="material">Material No</label>
            <MultiSelectionDropDown
              data={filters.materials}
              values={materialSelectedValues}
              onChange={onMaterialSelection}
              filterable={true}
              showError={showError}
              customStyle={{ width: "6.5vw", minHeight: "3vh" }}
            />
          </span>

          <span className="filter">
            <label htmlFor="comments">Comments</label>
            <MultiSelectionDropDown
              data={filters.comments}
              values={commentSelectedValues}
              onChange={onCommentSelection}
              filterable={true}
              showError={showError}
              customStyle={{
                width:
                  filters.regions?.length > 1 && isBFXCluster()
                    ? "10vw"
                    : "14vw",
                minHeight: "3vh",
              }}
            />
          </span>
          {selectedTab === 1 && (
            <span className="filter">
              <label htmlFor="tank">Tank Group</label>
              <MultiSelectionDropDown
                data={filters.tanks}
                values={tankSelectedValues}
                onChange={onTankSelection}
                filterable={true}
                showError={showError}
                customStyle={{ width: "7.0vw", minHeight: "3vh" }}
              />
            </span>
          )}
        </div>
        {selectedTab === TrendViewMode.MATERIAL && (
          <div className="filters-container">
            <span className="filter">
              <label htmlFor="+250">
                {isDachCluster() ? "1 Day +250" : "1 Day +150"}
              </label>
              <MultiSelectionDropDown
                data={filters.oneDayGain}
                values={day250SelectedValues}
                onChange={onDay250Selection}
                filterable={false}
                showError={showError}
                maxSelection={1}
                customStyle={{ width: "4.5vw", minHeight: "3vh" }}
              />
            </span>
            <span className="filter">
              <label htmlFor="-250">
                {isDachCluster() ? "1 Day -250" : "1 Day -150"}
              </label>
              <MultiSelectionDropDown
                data={filters.oneDayLoss}
                values={oneDayMinsSelectedValues}
                onChange={onOneDayMinsSelection}
                filterable={false}
                showError={showError}
                maxSelection={1}
                customStyle={{ width: "4.5vw", minHeight: "3vh" }}
              />
            </span>
            <span className="filter-column">
              <label htmlFor="fiveDCumVar">5D Cumul +/-250</label>
              <MultiSelectionDropDown
                data={filters.isFiveDaysCumulative}
                values={fiveDCumVarSelectedValues}
                onChange={onFiveDCumVarSelection}
                filterable={false}
                showError={showError}
                maxSelection={1}
                customStyle={{ width: "5vw", minHeight: "3vh" }}
              />
            </span>
            <span className="filter-column">
              <label htmlFor="fiveDCumThu">5D Cumul 3% Th</label>
              <MultiSelectionDropDown
                data={filters.isFiveDaysCumulativePercent}
                values={fiveDCumThSelectedValues}
                onChange={onFiveDCumThSelection}
                filterable={false}
                showError={showError}
                maxSelection={1}
                customStyle={{ width: "5vw", minHeight: "3vh" }}
              />
            </span>
            {isBFXCluster() && (
              <div className="filters-container">
                <span className="filter-column">
                  <label htmlFor="fiveDCumVar">30D Cumul +/-750</label>
                  <MultiSelectionDropDown
                    data={filters.isThirtyDaysCumulative}
                    values={thirtyDaysCumulSelectedValues}
                    onChange={onThirtyDayCumThSelection}
                    filterable={false}
                    showError={showError}
                    maxSelection={1}
                    customStyle={{ width: "5vw", minHeight: "3vh" }}
                  />
                </span>
                <span className="filter-column">
                  <label htmlFor="fiveDCumThu">30D Cumul 2% Th</label>
                  <MultiSelectionDropDown
                    data={filters.isThirtyDaysCumulativePercent}
                    values={thirtyDaysCumulPercentageSelectedValues}
                    onChange={onThirtyDayCumPercentageSelection}
                    filterable={false}
                    showError={showError}
                    maxSelection={1}
                    customStyle={{ width: "5vw", minHeight: "3vh" }}
                  />
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const showGridToolBar = () => {
    return (
      <GridToolbar>
        <div className="gridToolbar">
          {headerFilters()}
          <div className="rowContainer">
            {headerButtons()}
            {selectedTab === TrendViewMode.MATERIAL && headerColumns()}
          </div>
        </div>
      </GridToolbar>
    );
  };

  const getGridHeight = () => {
    if (selectedTab === TrendViewMode.MATERIAL) {
      return historicalViewEnabled ? "53vh" : "50vh";
    }
    return historicalViewEnabled ? "60vh" : "56vh";
  };

  const showGridDataView = () => (
    <AppDataGrid
      resetIndex={resetIndex}
      setResetIndex={setResetIndex}
      take={DEFAULT_PAGE_SIZE}
      style={{
        margin: "0 0.5vw 0.5vw 0.5vw",
        height: getGridHeight(),
      }}
      pageable={true}
      filterable={true}
      data={trendAnalysisData}
      pageSizes={[5, 10, 20, 50, 100]}
      columnsToShow={colsToShow(
        trendAnalysisData,
        commentsOptions,
        selectedTab,
        filters.selectedDate?.[0]?.text,
        blankCount,
        missingOutOfToleranceCount,
        handleShipToLinkAction,
        historicalViewEnabled,
        missingCommentsCount
      )}
      onItemChange={handleDataChange}
      onRowClick={handleRowClick}
      rowRender={handleRowRender}
      sortable={true}
      ref={exportGridRef}
      exportFileName="OOATT"
      noRecordsMessage="* Please select filters to fetch the data *"
    />
  );

  const showTabContent = () => (
    <div>
      {showGridToolBar()}
      {showGridDataView()}
    </div>
  );

  const showTabComponent = () => {
    const items = [
      {
        label: "Material View",
        key: TrendViewMode.MATERIAL,
      },
      {
        label: "Tank Group View",
        key: TrendViewMode.TANK,
      },
    ];
    return (
      <TabComponent
        items={items}
        activeIndex={selectedTab}
        onChange={(index) => {
          setSelectedTab(index);
        }}
      />
    );
  };

  const showModeComponent = () => {
    return (
      <div className="tab-container">
        {showTabComponent()}
        <div className="switchContainer">
          <Switch
            onLabel={""}
            offLabel={""}
            size="small"
            checked={historicalViewEnabled}
            onChange={() => {
              const enabled = !historicalViewEnabled;
              setHistoricalViewEnabled(enabled);
              props?.historyViewAction(enabled);
            }}
          />
          <span className="switch-label">Enable Historical View</span>
        </div>
      </div>
    );
  };

  return (
    <div className="out-of-trend-analysis-screen-container data-grid">
      <div className="row ">
        {showModeComponent()}
        {masterDataLoading ? (
          <div className="loader-container">
            <Loader
              size="medium"
              themeColor="warning"
              type="converging-spinner"
            />
            <br />
            <h4>Fetching Details, Please Wait...</h4>
          </div>
        ) : (
          <div
            className={`data-grid-container ${
              selectedTab === TrendViewMode.TANK ? "grid-tank-view" : ""
            }`}
          >
            {showTabContent()}
            {showSiteInfoModal && (
              <SiteInfoScreen
                globalSiteId={selectedSite?.siteId}
                countryCode={selectedSite?.country || currentCountryCode}
                close={closeModal}
              />
            )}

            {exporting && (
              <LoadingPanel message="Exporting the data.. please wait.." />
            )}
            {fetchingData && (
              <LoadingPanel message="Fetching the data.. please wait.." />
            )}
            {submitting && (
              <LoadingPanel message="Submitting comments.. please wait.." />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export { OutOfToleranceTrendAnalysis };
