import { useEffect, useState } from "react";
import { error, getFormattedDate, getFormattedDateAndTime } from "../../../../../services/utils";
import { store } from "../../../../../state-management/store";
import { reportsService } from "../../../../../services/reports";
import { TextInlineEditCell } from "../../../../components/text-edit-cell/TextInlineEditCell";
import { detailColsToShow } from "./account-for-deliveries-utils";
import { InlineEditGrid } from "../../../../components/data-grid/inline-data-grid";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import { triggerNotification } from "../../../../../state-management/actions";
import { useDispatch } from "react-redux";

const AFDDetailsController = (props) => {
    const dataItem = props?.dataItem;
    const [tankDetails, setTankDetails] = useState([]);
    const [deliveries, setDeliveries] = useState([]);
    const [updating, setUpdating] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      const updatedDeliveries = dataItem?.deliveries?.map((item) => {
        const startTemperature =
          item?.startTemperature === 0 || item?.startTemperature
            ? `${item?.startTemperature}°C`
            : "";
        const endTemperature =
          item?.endTemperature === 0 || item?.endTemperature
            ? `${item?.endTemperature}°C`
            : "";
        return {
          ...item,
          startTemperature: startTemperature,
          endTemperature: endTemperature,
          inEdit: false,
          title: "Edit",
          subTitle: " ",
          saveEnabled:
            dataItem?.outBoundDeliveryId?.toString()?.length > 0 &&
            dataItem?.confirmedDelivery?.toString()?.length > 0,
        };
      });

      setDeliveries(updatedDeliveries);
      setTankDetails(updatedDeliveries);
    }, [dataItem]);

    const handleDeliveryEdit = (_, childItem) => {
      const pumpList = tankDetails?.map((delivery) => {
        if (delivery.rowKey === childItem.rowKey) {
          return {
            ...delivery,
            inEdit: true,
            title: "Save",
            subTitle: "Cancel",
          };
        } else {
          return {
            ...delivery,
            inEdit: false,
            title: "Edit",
            subTitle: "",
          };
        }
      });
      setTankDetails(pumpList);
    };

    const handleDeliveryCancel = (_, childItem) => {
      const tankDeliveries = tankDetails?.map((delivery) => {
        if (delivery.rowKey === childItem.rowKey) {
          const actualDelivery = deliveries?.find(
            (item) => item.rowKey === delivery.rowKey
          );
          return actualDelivery;
        }
        return delivery;
      });
      setTankDetails(tankDeliveries);
    };

    const getDateTime = (documentDate, dateVal) => {
      const date = new Date(dateVal);
      const defaultValue = new Date();
      defaultValue.setHours(0, 0, 0, 0);
      const deliveryStartDate = documentDate
        ? new Date(documentDate)
        : defaultValue;
      deliveryStartDate.setHours(date ? date?.getHours() : 0);
      deliveryStartDate.setMinutes(date ? date?.getMinutes() : 0);
      const dateTime = getFormattedDateAndTime(deliveryStartDate);
      return dateTime;
    };

    const handleDeliverySave = async (rowItem, childItem) => {
      //Start Time
      const startDate = childItem?.deliveryStartDate;
      const deliveryStartTime =
        startDate instanceof Date || startDate?.length > 0
          ? getDateTime(rowItem?.documentDate, childItem?.deliveryStartDate)
          : null;

      //End Time..
      const endDate = childItem?.deliveryStartDate;
      const deliveryEndTime =
        endDate instanceof Date || endDate?.length > 0
          ? getDateTime(rowItem?.documentDate, childItem?.deliveryEndDate)
          : null;

      const payload = {
        countryCode: rowItem?.countryCode,
        rowKey: childItem.rowKey,
        shipTo: rowItem?.shipTo,
        materialNumber: rowItem.materialNumber,
        tankId: childItem?.tank,
        outBoundDeliveryId: childItem?.outBoundDeliveryId,
        documentDate: rowItem?.documentDate,
        reportDate: getFormattedDate(new Date()),
        calculatedDelivery: childItem?.calculatedDelivery,
        confirmedDelivery: childItem?.confirmedDelivery,
        difference: childItem?.difference,
        deliveryStartTime: deliveryStartTime,
        deliveryEndTime: deliveryEndTime,
        startTemperature:
          childItem?.startTemperature?.length > 0
            ? parseFloat(childItem?.startTemperature)
            : null,
        endTemperature:
          childItem?.endTemperature?.length > 0
            ? parseFloat(childItem?.endTemperature)
            : null,
        userName: store.getState()?.user?.UserEmail,
      };
      setUpdating(true);
      const response = await reportsService.addAFDDelivery(payload);
      if (response?.data?.isSuccess) {
        props?.fetchAccountForDeliveriesReport();
        dispatch(
          triggerNotification({
            type: {
              style: "success",
              icon: true,
            },
            message:
              response?.message ||
              response?.data?.message ||
              "Delivery updated successfully.",
          })
        );
      } else {
        const tankDeliveries = tankDetails?.map((delivery) => {
          if (delivery.rowKey === childItem.rowKey) {
            const actualDelivery = deliveries?.find(
              (item) => item.rowKey === delivery.rowKey
            );
            return actualDelivery;
          }
          return delivery;
        });
        setTankDetails(tankDeliveries);
        dispatch(
          triggerNotification({
            type: {
              style: error,
              icon: true,
            },
            message:
              response?.message ||
              response?.data?.message ||
              "Delivery update failed",
          })
        );
      }
      setUpdating(false);
    };
    const textValidator = (value, message) => {
      if (value === null || value?.length === 0) {
        return message;
      }
      return "";
    };
    const handleItemChange = (e) => {
      const childItem = e.dataItem;
      const pumpList = tankDetails?.map((delivery) => {
        if (delivery.rowKey === childItem.rowKey) {
          const confirmedDeliveryValid = textValidator(
            e.field === "confirmedDelivery"
              ? e.value
              : delivery?.confirmedDelivery,
            "Confirmed Delivery is required"
          );
          delivery["confirmedDeliveryValid"] = confirmedDeliveryValid;

          const outBoundDeliveryIdValid = textValidator(
            e.field === "outBoundDeliveryId"
              ? e.value
              : delivery?.outBoundDeliveryId,
            "Outbound Delivery is required"
          );

          delivery["outBoundDeliveryIdValid"] = outBoundDeliveryIdValid;

          const saveEnabled = !(
            delivery?.confirmedDeliveryValid?.length > 0 ||
            delivery?.outBoundDeliveryIdValid?.length > 0
          );

          return {
            ...delivery,
            saveEnabled: saveEnabled,
            [e.field]: e.value,
          };
        }
        return delivery;
      });
      setTankDetails(pumpList);
    };

    return (
      <div className="container-fluid">
        <div className="openingHoursContainer">
          <InlineEditGrid
            style={{ maxHeight: "50vh", borderColor: "#b8e2ea" }}
            take={10000000}
            pageable={false}
            filterable={true}
            expandable={false}
            sortable={true}
            data={tankDetails}
            columnsToShow={detailColsToShow({
              rowItem: dataItem,
              onEdit: handleDeliveryEdit,
              onSave: handleDeliverySave,
              onCancel: handleDeliveryCancel,
            })}
            onItemChange={handleItemChange}
            cells={{
              edit: {
                text: TextInlineEditCell,
              },
            }}
          />
        </div>
        {updating && (
          <LoadingPanel message="Updating Deliveries.. please wait.." />
        )}
      </div>
    );
  };

  export default AFDDetailsController;