import React, { useEffect, useState } from "react";
import "./report-menu.scss";
import { Input } from "@progress/kendo-react-inputs";
import { useSelector, useStore } from "react-redux";
import { setSelectedReport } from "../../../../state-management/actions";
import { Menu } from "@sede-x/shell-ds-react-framework";
import { getMenus } from "./common-utils";

export const ReportMenu = (props) => {
  const store = useStore();
  const { userScreenList } = store.getState();
  const [inputValue, setInputValue] = useState("");
  const [moduleId, setModuleId] = useState("");
  const [submoduleId, setSubmoduleId] = useState("");
  const [selectedItem, setSelectedItem] = useState({});
  const [reportsList, setReportsList] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const savedReportsData = localStorage.getItem("reportsData");
  const parsedData = savedReportsData && JSON.parse(savedReportsData);
  const defaultCountry = useSelector((state) => state?.selectedCountry);
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);

  store.subscribe(() => {
    const { selectedReport, selectedCountry } = store.getState();
    const currentModuleId = selectedReport?.moduleId;
    const currentSubModuleId = selectedReport?.submoduleId;
    setModuleId(currentModuleId);
    setSubmoduleId(currentSubModuleId);
    setSelectedItem(selectedReport);
    setCurrentCountryCode(selectedCountry);
  });

  const getMenuItems = (reports, level = 0) => {
    const menuList = getMenus(currentCountryCode, reports);

    const menus = menuList?.map((menu) => {
      let menuItem = {
        ...menu,
        label: menu?.title,
        key: menu?.id,
        level: level,
      };
      if (menu?.children) {
        menuItem = {
          ...menuItem,
          children: getMenuItems(menu?.children, level + 1),
        };
      }
      return menuItem;
    });
    return menus;
  };

  useEffect(() => {
    if (parsedData) {
      const currentModuleId = parsedData?.moduleId;
      const currentSubModuleId = parsedData?.submoduleId;
      setExpanded([currentModuleId, currentSubModuleId]);
      setSelectedItem(parsedData);
      store.dispatch(setSelectedReport(parsedData));
    }
    setReportsList(getMenuItems(userScreenList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const menus = getMenuItems(userScreenList);
    setReportsList(menus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userScreenList]);

  const onSelect = (e) => {
    const { id, reportId, title, pageName, tableName, columnName } =
      e.item.props;
    const keyPath = e?.keyPath;
    if (inputValue) {
      setInputValue("");
    }
    if (keyPath?.length >= 1) {
      setModuleId(keyPath?.[keyPath?.length - 1]);
    }
    if (keyPath?.length >= 2) {
      setSubmoduleId(keyPath?.[keyPath?.length - 2]);
    }
    const index = expanded.indexOf(id);
    if (index === -1) {
      const selectedReport = {
        moduleId: moduleId,
        submoduleId: submoduleId,
        pageId: id,
        pageName,
        reportId,
        title,
        tableName,
        columnName,
      };
      localStorage.setItem("reportsData", JSON.stringify(selectedReport));
      setSelectedItem(selectedReport);
      store.dispatch(setSelectedReport(selectedReport));
    }
    setExpanded(keyPath);
  };

  const handleSearch = (value) => {
    setInputValue(value);
    let filteredItems = [];
    if (value) {
      reportsList.forEach((list) => {
        const filterList = (data, parentId) => {
          data.forEach((child) => {
            if (child.title.toLowerCase().includes(value.toLowerCase())) {
              filteredItems.push(parentId); // module id
              filteredItems.push(child.parentId); // submodule id
            }
            if (child.children) {
              filterList(child.children, child.parentId);
            }
          });
        };
        if (list.children) {
          filterList(list.children, list.id);
        }
      });
      filteredItems = Array.from(new Set(filteredItems));
      setExpanded(filteredItems);
    } else {
      setExpanded([selectedItem.moduleId, selectedItem.submoduleId]);
    }
  };

  const searchClass = props?.menuCollapse
    ? "search-container search-collapse-width"
    : "search-container search-width";

  return (
    <div className="accordian-container">
      <div className={searchClass}>
        <img
          alt="Search"
          className="search-icon"
          src={require("../../../../assets/search.svg").default}
        />
        <Input
          onChange={(e) => handleSearch(e.value)}
          value={inputValue}
          placeholder="Search Title"
          className="input-field"
        />
      </div>
      <div className="menu-container">
        <Menu
          items={reportsList}
          onSelect={onSelect}
          openKeys={expanded}
          selectedKeys={[selectedItem.pageId]}
          onOpenChange={(e) => {
            setExpanded(e);
          }}
        />
      </div>
    </div>
  );
};
