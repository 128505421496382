import React, { useEffect, useState } from "react";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { userService } from "../../../../../services/user";
import { DropdownFilterCell } from "../../../../components/dropdown-filter/dropdown-filter";
import { Loader } from "@progress/kendo-react-indicators";
import { DEFAULT_PAGE_SIZE } from "../common-utils";
import './user-management.scss';

const UserAuditListScreen = () => {
  const [auditList, setAuditList] = useState([]);
  const [loading, setLoading] = useState(true);

  async function fetchUserAuditList() {
    const response = await userService.fetchAuditList();
    const data = response?.map((item) => {
      return {
        ...item,
        AccessControl: item?.AccessControl?.join(","),
      };
    });
    setAuditList(data ? data : []);
    setLoading(false);
  }

  useEffect(() => {
    fetchUserAuditList();
  }, []);

  const userRoles = Array.from(
    new Set(
      auditList.map((data) =>
        data.RoleName ? data.RoleName.toUpperCase() : ""
      )
    )
  );

  const userRoleFilterCell = (props) => {
    return (
      <DropdownFilterCell
        {...props}
        data={userRoles}
        defaultItem={"Select Role"}
      />
    );
  };

  const colsToShow = [
    {
      title: "First Name",
      field: "UserFirstName",
      filterable: true,
      sortable: true,
      filter: "text",
    },
    {
      title: "Last Name",
      field: "UserLastName",
      filterable: true,
      sortable: true,
      filter: "text",
    },
    {
      title: "Unique User ID",
      field: "UniqueUserId",
      sortable: true,
    },
    {
      title: "User Role",
      field: "RoleName",
      filterable: true,
      sortable: true,
      filterCell: userRoleFilterCell,
    },
    {
      title: "Status",
      field: "IsActive",
      sortable: true,
    },
    {
      title: "Access",
      field: "AccessControl",
      sortable: true,
    },
  ];

  return (
    <>
      <div className="container data-grid">
        <div className="row ">
          {loading ? (
            <div className="loader-container">
              <Loader
                size="medium"
                themeColor="warning"
                type="converging-spinner"
              />
              <br />
              <h4>Fetching Details, Please Wait...</h4>
            </div>
          ) : (
            <div className="data-grid-container">
              <AppDataGrid
                exportable={true}
                style={{ height: "71.5vh" }}
                take={DEFAULT_PAGE_SIZE}
                pageable={true}
                filterable={true}
                sortable={true}
                data={auditList}
                columnsToShow={colsToShow}
                exportFileName="User Audit List"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export { UserAuditListScreen };
