import { getMonths } from "../../reportUtils";
import { CustomHeaderCell } from "../account-for-deliveries/account-for-deliveries-utils";
import { DECIMAL } from "../common-utils";
import { LeakTabOptions } from "./tank-leaks-screen";
import { Button } from "@progress/kendo-react-buttons";

export const getMonthIndex = (name) => {
  const monthsData = getMonths();
  const month = monthsData?.find((monthItem) => monthItem.text === name);
  return month ? month.id : 0;
};

const ShipToLinkCell = (props) => {
  const { dataItem, field, selectedTab, onShipToAction } = props;

  const onClick = (event) => {
    onShipToAction({
      dataItem: dataItem,
      field: field,
      syntheticEvent: event,
    });
  };
  return (
    <td style={{ paddingTop: "0.8vh" }}>
      {selectedTab === LeakTabOptions.MINS_REPORT ? (
        <div className="commentsContainer">{dataItem?.[field]}</div>
      ) : (
        <Button themeColor={"info"} fillMode="link" onClick={onClick}>
          {dataItem?.[field]}
        </Button>
      )}
    </td>
  );
};

export const getTankGroups = (_masterData, filters, productSelectedValues) => {
  const siteData = filters?.allSiteIds?.find(
    (site) => site?.globalSiteId === filters?.selectedSiteId
  );
  const productTanks = [];
  productSelectedValues?.forEach((product) => {
    const tanks = siteData?.tanks?.filter(
      (tank) => (tank?.materialNumber === product?.id || product?.id === "All") && tank?.tankGroupId
    );
    if (tanks) {
      productTanks?.push(...tanks);
    }
  });

  let tankGroupData = [];

  if (productTanks?.length > 0) {
    const tankGroup = Object.groupBy(productTanks, (item) => item?.tankGroupId);

    tankGroupData = Object.keys(tankGroup)?.map((key) => {
      const groupItem = {
        text: key,
        id: key,
        group: true,
      };
      return groupItem;
    });
  }

  return tankGroupData;
};


export const addAllOptions = (items, always = false) => {
  if (items?.length > 0 || always) {
    return [
      {
        id: "All",
        text: "All",
      },
      ...items,
    ];
  }
  return items;
};

export const colsToShow = (
  _trendAnalysisData,
  selectedTab,
  onShipToAction
) => {
  let columns = [
    {
      title: "Request Date",
      field: "requestDate",
      filterable: true,
      sortable: true,
      width:
        selectedTab === LeakTabOptions.DAILY_REPORT
          ? window.innerWidth * DECIMAL.POINT_ZERO_SEVEN
          : window.innerWidth * DECIMAL.POINT_ONE,
    },
    {
      title: "Ship To",
      field: "shipTo",
      filterable: true,
      sortable: true,
      cell: (props) => {
        return (
          <ShipToLinkCell
            {...props}
            onShipToAction={onShipToAction}
            selectedTab={selectedTab}
          />
        );
      },
    },
    {
      title: "Product",
      field: "product",
      filterable: true,
      sortable: true,
    },
    {
      title: "Tank Group",
      field: "tankGroup",
      filterable: true,
      sortable: true,
    },
    {
      title: "Variance",
      field: "variance",
      filterable: true,
      sortable: true,
    },
  ];

  if (
    selectedTab === LeakTabOptions.DAILY_REPORT ||
    selectedTab === LeakTabOptions.MINS_REPORT
  ) {
    columns = [
      ...columns,

      {
        title: "STD DEV of all Variance",
        field: "stdDevOfAllVariance",
        filterable: true,
        sortable: true,
        headerCell: CustomHeaderCell,
      },
      {
        title: "Half of STD DEV of all Variance",
        field: "halfOfSTDDevOfAllVariance",
        filterable: true,
        sortable: true,
        headerCell: CustomHeaderCell,
      },
      {
        title: "STD DEV ignoring high Variance",
        field: "stdDevIgnoringHighVariance",
        filterable: true,
        sortable: true,
        headerCell: CustomHeaderCell,
      },
      {
        title: "Half of STD DEV ignoring high Variance",
        field: "halfOfSTDDevIgnoringHighVariance",
        filterable: true,
        sortable: true,
        headerCell: CustomHeaderCell,
      },
      {
        title: "Mean",
        field: "mean",
        filterable: true,
        sortable: true,
      },
    ];
  }

  columns = [
    ...columns,
    {
      title: "Threshold",
      field: "threshold",
      filterable: true,
      sortable: true,
    },
  ];

  if (selectedTab === LeakTabOptions.MINS_REPORT) {
    columns = [
      ...columns,

      {
        title: "Hours type",
        field: "hourType",
        filterable: true,
        sortable: true,
      },
    ];
  }

  columns = [
    ...columns,
    {
      title: "Status",
      field: "status",
      filterable: true,
      sortable: true,
    },
  ];

  return columns;
};