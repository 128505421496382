const initialState = {
    user: null,
    language: "english",
    refresh_token: null,
    access_token: null,
    id_token: null,
    notification: [],
  };

  const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SAVE_LOGGED_IN_USER":
          if (!action.payload) {
            return state;
          }
          return { ...state, user: action.payload };
    
        case "CHANGE_LANGUAGE":
          const language = action?.payload["newLanguage"] || "english";
          return { ...state, language: language };
    
        case "SET_REFRESH_TOKEN":
          if (!action.payload) {
            return state;
          }
          return { ...state, refresh_token: action.payload };
    
        case "SET_ACCESS_TOKEN":
          if (!action.payload) {
            return state;
          }
          return { ...state, access_token: action.payload };
    
        case "SET_ID_TOKEN":
          if (!action.payload) {
            return state;
          }
          return { ...state, id_token: action.payload };
    
        default:
          return state;
      }
  };
  
  export default loginReducer;
  