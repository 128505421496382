import "./message-tracker-delivery.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { triggerNotification } from "../../../../../state-management/actions";
import { reportsService } from "../../../../../services/reports";
import { Button, DropDownList } from "@progress/kendo-react-all";
import { colsToShow, getCountries } from "./message-tracker-delivery-utils";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import {
  getFormattedDate,
  getFormattedFullDate,
  serverError,
  getDaysBeforeDate
} from "../../../../../services/utils";
import { store } from "../../../../../state-management/store";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { SiteInfoScreen } from "../out-of-tolerance-trend-analysis/out-of-tolerance-site-info";
import { DEFAULT_PAGE_SIZE, EndDateInput, StartDateInput, isDachCluster } from "../common-utils";
import { DateRangePickerComponent } from "../../../../components/date-range-picker/date-range-picker";

const CustomDetailsContainer = (props) => {
  const dataItem = props?.dataItem;
  return (
    <div className="message-container">
      <pre>
        <code>{dataItem?.message}</code>
      </pre>
    </div>
  );
};

const MessageTrackerDeliveryScreen = () => {
  const dispatch = useDispatch();
  const [resetIndex, setResetIndex] = useState(false);
  const [selectedSite, setSelectedSite] = useState();
  const [filters, setFilters] = useState({
    selectedDates: {
      start: isDachCluster() ? getDaysBeforeDate(2) : getDaysBeforeDate(1),
      end: isDachCluster() ? getDaysBeforeDate(2) : getDaysBeforeDate(1),
    },
    countries: getCountries(),
    selectedCountry: getCountries()?.[0],
  });
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [deliveriesData, setDeliveriesData] = useState([]);
  const [deliveriesDataLoading, setDeliveriesDataLoading] = useState(false);
  const [showSiteInfoModal, setShowSiteInfoModal] = useState(false);
  const defaultCluster = useSelector((state) => state?.selectedCluster);
  const [currentCluster, setCurrentCluster] = useState(defaultCluster);

  store.subscribe(() => {
    const { selectedCluster } = store.getState();
    setCurrentCluster(selectedCluster);
  });

  useEffect(() => {
    fetchDeliveriesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedDates: {
        start: isDachCluster() ? getDaysBeforeDate(2) : getDaysBeforeDate(1),
        end: isDachCluster() ? getDaysBeforeDate(2) : getDaysBeforeDate(1),
      },
    }));
  }, [currentCluster]);

  const fetchDeliveriesData = async (refresh = false) => {
    const filter = refresh ? appliedFilters : filters;
    const fromDate = filter.selectedDates.start;
    const toDate = filter.selectedDates.end;
    const startDate = fromDate && getFormattedDate(fromDate);
    const endDate = toDate && getFormattedDate(toDate);
    const country = filter?.selectedCountry?.text;

    const valuesSelected = startDate && endDate && country;

    if (valuesSelected) {
      const payload = {
        fromDate: startDate,
        toDate: endDate,
        countryCode: country,
      };
      setDeliveriesDataLoading(true);
      try {
        let response = await reportsService.fetchMessageTrackerDeliveries(
          payload
        );
        response = response?.map((item) => {
          const timeGenerated =
            item?.timeGenerated &&
            getFormattedFullDate(new Date(item?.timeGenerated));
          return {
            ...item,
            timeGenerated: timeGenerated,
          };
        });

        setDeliveriesData(response);
        if (!refresh) {
          setAppliedFilters(filter);
        }
      } catch (error1) {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: serverError,
          })
        );
      }
      setDeliveriesDataLoading(false);
    }
  };

  const closeModal = () => {
    setShowSiteInfoModal(false);
  };

  const onCountrySelection = (value) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedCountry: value,
      };
    });
  };

  const onDateSelection = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedDates: event.value,
      };
    });
  };

  const startDateInput = propValues => (
    <StartDateInput {...propValues}/>
  );

  const endDateInput = propValues => (
    <EndDateInput {...propValues}/>
  );

  const showHeaders = () => {
    return (
      <div className="header-container">
        <div className="filters-container">
          <span className="filter-date">
            <DateRangePickerComponent
              placeholder="Select date range..."
              defaultValue={filters.selectedDates}
              value={filters.selectedDates}
              startDateInput={startDateInput}
              endDateInput={endDateInput}
              onChange={onDateSelection}
              max={isDachCluster() ? getDaysBeforeDate(2) : getDaysBeforeDate(1)}
            />
          </span>
          <span className="filter">
            <label htmlFor="Date">Country </label>
            <DropDownList
              data={filters.countries}
              textField="text"
              dataItemKey="id"
              onChange={(event) => {
                onCountrySelection(event.target.value);
              }}
              value={filters.selectedCountry}
              style={{
                width: "5vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
            />
          </span>
          <span className="filter-button">
            <Button
              className="get-data-btn"
              onClick={() => {
                fetchDeliveriesData();
                setResetIndex(!resetIndex);
              }}
            >
              Get data
            </Button>
          </span>
          <span className="filter-button">
            <Button
              className="refresh-btn"
              onClick={() => {
                fetchDeliveriesData(true);
              }}
              disabled={!appliedFilters}
            >
              Refresh
            </Button>
          </span>
        </div>
      </div>
    );
  };


  const handleShipToLinkAction = (siteId, country) => {
    setSelectedSite({ siteId: siteId, country: country });
    setShowSiteInfoModal(true);
  };

  return (
    <div className="message-tracker-screen-container data-grid">
      <div className="row ">
        <div className="data-grid-container">
          {showHeaders()}

          {showSiteInfoModal && (
            <SiteInfoScreen
              globalSiteId={selectedSite?.siteId}
              countryCode={selectedSite?.country}
              close={closeModal}
            />
          )}
          <AppDataGrid
            resetIndex={resetIndex}
            setResetIndex={setResetIndex}
            take={DEFAULT_PAGE_SIZE}
            style={{ height: "68vh" }}
            pageable={true}
            filterable={true}
            data={deliveriesData}
            columnsToShow={colsToShow(handleShipToLinkAction)}
            sortable={true}
            expandable={true}
            detailsContainer={CustomDetailsContainer}
            noRecordsMessage="* Please select filters to fetch the data *"
          />
          {deliveriesDataLoading && (
            <LoadingPanel message="Fetching the data.. please wait.." />
          )}
        </div>
      </div>
    </div>
  );
};

export { MessageTrackerDeliveryScreen };
