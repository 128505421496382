const { REACT_APP_ENVIRONMENT } = process.env;

/**
 * This service generates logs that are useful at the time of debugging.
 * These logs are scattered throught the application's source code, and you can check the browser console to view them in real time.
 * Logging will be visible if the application runs in development environment.
 */
class LoggerService {
  successStyle = "font-weight:bolder;color:green;font-size:2rem;";
  dangerStyle = "font-weight:bolder;color:red;font-size:2rem;";
  descriptionStyle = "font-weight:bolder;color:grey;font-size:1rem;";
  logStyle = "font-weight:lighter;color:#0092a5;font-size:1rem;";
  /**
   *This function logs to the application's enviroment onto the console.
   */
  environment() {
    console.clear();

    if (
      REACT_APP_ENVIRONMENT === "DEV" ||
      REACT_APP_ENVIRONMENT === "TEST" ||
      REACT_APP_ENVIRONMENT === "PROD"
    ) {
      console.log(
        `${this.successStyle}App running in ${REACT_APP_ENVIRONMENT} environment. ${this.descriptionStyle}Detailed logging will be functional in this environment for better debugging.`
      );
    } else {
      console.log(
        "%cUnknown Environment! Please check your env variables.\nErrors are bound to happen in this scenario.",
        this.dangerStyle
      );
    }
  }

  /**
   *This function clears the console.
   */
  clearConsole() {
    console.clear();
  }

  /**
   *This function logs to the console if the application runs in development environment.
  @param {string} message - The message that needs to be logged to the console.
  @param {any} payload - Any payload that needs to be logged to the console.
   */
  dev(message, payload) {
    if (REACT_APP_ENVIRONMENT === "TEST") {
      console.log(`%c${message}`, this.logStyle, payload || "");
    }
  }
}

const loggerService = new LoggerService();
loggerService.dev("LoggerService Created");

loggerService.dev("Exporting LoggerService");
export { loggerService };
