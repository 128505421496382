import { Button } from "@progress/kendo-react-all";
import { Popover } from "@progress/kendo-react-tooltip";
import "@progress/kendo-theme-default/dist/all.css";
import PropTypes from 'prop-types';

const defaultOffSet = {
  left: 100,
  top: 200,
};

const ConfirmPopup = ({
  offset = { defaultOffSet },
  position = 'left',
  isOpen,
  title,
  message,
  saveAction,
  cancelAction,
}) => {
  return (
    <Popover offset={offset} show={isOpen} position={position}>
      <div style={{ display: "flex", flexDirection: "column" }}>
      <span
          style={{
            fontSize: "0.8vw",
            paddingBottom: "2vh",
            fontWeight: 'bold',
            color: '#dd1d21'
          }}
        >
          {title}
        </span>
        <span
          style={{
            fontSize: "0.8vw",
            paddingBottom: "2vh",
          }}
        >
          {message}
        </span>
        <div style={{ display: "flex", paddingBottom: "20px", justifyContent: 'center' }}>
          <Button
            style={{
              fontSize: "0.8vw",
              marginRight: "0.5vw",
              padding: "0 1vw",
              color: "#dd1d21",
              fontWeight: 'bold',
            }}
            onClick={saveAction}
          >
            YES
          </Button>
          <Button
            style={{
              fontSize: "0.8vw",
              marginRight: "0.5vw",
              padding: "0.5vh 1vw",
              fontWeight: 'bold',
            }}
            onClick={cancelAction}
          >
            No
          </Button>
        </div>
      </div>
    </Popover>
  );
};

ConfirmPopup.propTypes = {
  offset: PropTypes.object,
  position: PropTypes.string,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  saveAction: PropTypes.func,
  cancelAction: PropTypes.func,
};

export default ConfirmPopup;
