import "./out-of-tolerance-summary.scss";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { triggerNotification } from "../../../../../state-management/actions";
import { reportsService } from "../../../../../services/reports";
import { Button } from "@progress/kendo-react-all";
import { store } from "../../../../../state-management/store";
import {
  countColsToShow,
  getFooterItem,
} from "./out-of-tolerance-summary-utils";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import {
  getFormattedDate,
  serverError,
  unableToFetch,
} from "../../../../../services/utils";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { getLastDates } from "../../reportUtils";
import { DatePicker, DropDownList } from "@progress/kendo-react-all";
import { Switch } from "@progress/kendo-react-inputs";
import {
  BoxItem,
  getOOTASourceName,
  getSelectedCluster,
  isDachCluster,
  NUMBERS,
} from "../common-utils";

const OutOfToleranceSummaryScreen = () => {
  const dispatch = useDispatch();
  const exportGridRef = useRef(null);
  const defaultCountry = useSelector((state) => state?.selectedCountry);
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);
  const [resetIndex, setResetIndex] = useState(false);
  const [reportConfig, setReportConfig] = useState();
  const [filters, setFilters] = useState({
    selectedDate: getLastDates(
      NUMBERS.ONE,
      isDachCluster() ? NUMBERS.TWO : NUMBERS.ONE
    )?.[0],
    dates: getLastDates(
      NUMBERS.FOUR,
      isDachCluster() ? NUMBERS.TWO : NUMBERS.ONE
    ),
    selectedDateVal: null,
  });
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [ootSummaryData, setOOTSummaryData] = useState([]);
  const [ootInvestigationData, setOOTInvestigationData] = useState([]);
  const [investigatedPercent, setInvestigatedPercent] = useState(null);
  const [error, setError] = useState("");
  const [filterDataLoading, setFilterDataLoading] = useState(false);
  const [OttDataLoading, setOTTDataLoading] = useState(false);
  const [historicalViewEnabled, setHistoricalViewEnabled] = useState(false);

  const exportData = async () => {
    exportGridRef?.current?.exportDataInExcel &&
      (await exportGridRef.current.exportDataInExcel());
  };

  store.subscribe(() => {
    const { selectedCountry } = store.getState();
    setCurrentCountryCode(selectedCountry);
  });
  const fetchReportData = async () => {
    try {
      const response = await reportsService.getRestoreData();
      setFilterDataLoading(false);
      if (response) {
        const ootReportConfig = response?.find(
          (item) => item?.reportCode === "oot-daily"
        );
        setReportConfig(ootReportConfig);
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: unableToFetch,
          })
        );
      }
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: serverError,
        })
      );
      setFilterDataLoading(false);
    }
  };

  useEffect(() => {
    fetchReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const endDate = new Date(reportConfig?.restoreEndDate);

    if (endDate) {
      setFilters((prev) => {
        return {
          ...prev,
          selectedDateVal: endDate,
        };
      });
    }
  }, [reportConfig]);

  useEffect(() => {
    const selectedDate = getLastDates(NUMBERS.ONE, isDachCluster() ? NUMBERS.TWO : NUMBERS.ONE)?.[0];
    setFilters((prev) => {
      return {
        ...prev,
        selectedDate: selectedDate,
        dates: getLastDates(NUMBERS.FOUR, isDachCluster() ? NUMBERS.TWO : NUMBERS.ONE),
      };
    });
    fetchOOTSummaryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryCode]);

  const getSummaryData = (responseData) => {
    const response = responseData?.map((item) => {
      let values = {};
      item?.regions?.forEach((region) => {
        values = {
          ...values,
          [region.regionName]: region?.count,
        };
      });
      return {
        ...item,
        ...values,
      };
    });

    return response;
  };

  const moveItemToTop = (response, key) => {
    const index = response.findIndex((item) => item?.title.includes(key));
    if (index === -1) {
      return response;
    }
    const updatedItems = [...response];
    const [removedUser] = updatedItems.splice(index, 1);
    updatedItems.unshift(removedUser);

    return updatedItems;
  };

  const addTotalMissingSiteItem = (headerResponse) => {
    const activeSiteItem = headerResponse?.find((item) =>
      item?.title?.includes("Active")
    );
    const investigatedSiteItem = headerResponse?.find((item) =>
      item?.title?.includes("Investigated")
    );

    const regions = activeSiteItem?.regions?.map((region) => {
      const investigatedRegion = investigatedSiteItem?.regions?.find(
        (item) => item?.regionName === region?.regionName
      );

      let missingCount = region?.count - investigatedRegion?.count;
      missingCount = missingCount > 0 ? missingCount : 0;
      return {
        regionName: region?.regionName,
        count: missingCount,
      };
    });

    let missingTotal = activeSiteItem?.total - investigatedSiteItem?.total;
    missingTotal = missingTotal > 0 ? missingTotal : 0;

    const missingSite = {
      title: "Missing Sites",
      regions: regions,
      isHeaderRow: true,
      total: missingTotal,
    };

    let response = [...headerResponse];
    if (activeSiteItem && investigatedSiteItem) {
      response = [...headerResponse, missingSite];
    }
    response = moveItemToTop(response, "Missing");
    response = moveItemToTop(response, "Investigated");
    response = moveItemToTop(response, "Active");
    return response;
  };

  const fetchOOTSummaryData = async (refresh = false) => {
    const filter = refresh ? appliedFilters : filters;
    const targetDate = getFormattedDate(filter.selectedDate?.actualDate);

    const date = filters?.selectedDateVal;
    const dateSelected = historicalViewEnabled ? date : targetDate;

    const valuesSelected = dateSelected;

    if (valuesSelected) {
      let payload = {
        targetDate: targetDate,
        countryCode: currentCountryCode,
        cluster: getSelectedCluster(),
        sourceName: getOOTASourceName(currentCountryCode),
      };
      if (historicalViewEnabled) {
        payload = {
          targetDate: getFormattedDate(date),
          countryCode: currentCountryCode,
          cluster: getSelectedCluster(),
          sourceName: getOOTASourceName(currentCountryCode),
        };
      }
      setOTTDataLoading(true);
      try {
        const headerResponse = await reportsService.fetchOOTSummaryHeaderData(
          payload
        );
        const summaryResponse = await reportsService.fetchOOTSummaryData(
          payload,
          historicalViewEnabled
        );

        let response = [];

        if (summaryResponse instanceof Array) {
          if (headerResponse instanceof Array) {
            response = [...headerResponse, ...summaryResponse];
          } else {
            response = summaryResponse;
          }
          response = addTotalMissingSiteItem(response);
        }

        const headerData = response?.filter((item) => item?.isHeaderRow);
        const summaryData = getSummaryData(headerData);
        setOOTSummaryData(summaryData);

        const footerData = response?.filter((item) => !item?.isHeaderRow);
        let investigationData = getSummaryData(footerData);

        if (investigationData?.length > 0) {
          const lockedItem = getFooterItem(investigationData);
          let totalInvestigatedItem = 0;
          investigationData?.forEach((item) => {
            if (item?.title?.replace(/\s+/g, "")?.length > 0) {
              totalInvestigatedItem += item?.total;
            }
          });

          const percent = parseFloat(
            ((totalInvestigatedItem / lockedItem?.total) * 100)?.toFixed(2)
          );

          setInvestigatedPercent(percent);

          investigationData.sort((a, b) =>
            a?.title?.toLowerCase()?.localeCompare(b?.title?.toLowerCase())
          );
          investigationData = [...investigationData, lockedItem];
        }
        setOOTInvestigationData(investigationData);

        if (!refresh) {
          setAppliedFilters(filter);
        }
      } catch (error1) {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: serverError,
          })
        );
        setError(serverError);
        setError("");
      }
      setOTTDataLoading(false);
    }
  };

  const onDateSelection = (value) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedDate: value,
      };
    });
  };

  const onDateChange = (e) => {
    const date = new Date(e?.value);
    setFilters((prev) => {
      return {
        ...prev,
        selectedDateVal: date,
      };
    });
  };

  const showHeaders = () => {
    return (
      <div className="header-container">
        <div className="filters-container">
          {historicalViewEnabled ? (
            <span className="filter-date">
              <label htmlFor="region">Date</label>
              <DatePicker
                format={"dd-MMM-yyyy"}
                value={filters?.selectedDateVal}
                onChange={onDateChange}
                max={new Date(reportConfig?.restoreEndDate)}
                min={new Date(reportConfig?.restoreStartDate)}
              />
            </span>
          ) : (
            <span className="filter">
              <label htmlFor="Date">Date </label>
              <DropDownList
                data={filters.dates}
                textField="text"
                dataItemKey="id"
                onChange={(event) => {
                  onDateSelection(event.target.value);
                }}
                value={filters.selectedDate}
                style={{
                  width: "5vw",
                  marginRight: "1vw",
                  fontSize: "10px",
                }}
              />
            </span>
          )}
          <span className="filter-button">
            <Button
              className="get-data-btn"
              onClick={() => {
                fetchOOTSummaryData();
                setResetIndex(!resetIndex);
              }}
              disabled={filterDataLoading}
            >
              {filterDataLoading ? "Fetching..." : "Get data"}
            </Button>
          </span>
          <span className="filter-button">
            <Button
              className="refresh-btn"
              onClick={() => {
                fetchOOTSummaryData(true);
              }}
              disabled={!appliedFilters}
            >
              Refresh
            </Button>
          </span>
        </div>
        {investigatedPercent !== null && (
          <BoxItem
            item={{
              title: "% of Items Investigated",
              count: `${investigatedPercent}%`,
            }}
          />
        )}

        <span className="toggleContainer">
          <div className="switchContainer">
            <Switch
              onLabel={""}
              offLabel={""}
              size="small"
              checked={historicalViewEnabled}
              onChange={() => {
                setHistoricalViewEnabled(!historicalViewEnabled);
              }}
            />
            <span className="switch-label">Enable Historical View</span>
          </div>
        </span>
      </div>
    );
  };

  const cellRender = (td, props) => {
    const extraProps = {};
    const styles = props.style;
    if (props.dataItem.locked) {
      extraProps.style = {
        ...styles,
        bottom: 0,
      };
      extraProps.className = props.className + " k-grid-row-sticky";
    }
    return React.cloneElement(
      td,
      {
        ...td.props,
        ...extraProps,
      },
      td.props.children
    );
  };

  return (
    <div className="oot-summary-screen-container data-grid">
      <div className="row ">
        {/* <h4 className="title">Out Of Tolerance Summary Analysis</h4> */}
        {error && <span>{error}</span>}
        <div className="data-grid-container">
          {showHeaders()}

          <div className="grid-container">
            {ootSummaryData?.length > 0 && (
              <div className="site-summary-container">
                <div className="site-summary-header">
                  <div className="site-summary-grid-title">Site Summary</div>
                </div>
                <div className="separator"></div>
                <AppDataGrid
                  resetIndex={resetIndex}
                  setResetIndex={setResetIndex}
                  take={1000000000}
                  style={{ maxHeight: "67vh", marginBottom: "1vw" }}
                  pageable={false}
                  filterable={false}
                  data={ootSummaryData}
                  columnsToShow={countColsToShow(ootSummaryData, true)}
                  sortable={false}
                  noRecordsMessage="* Please select filters to fetch the data *"
                />
              </div>
            )}
            {ootInvestigationData?.length > 0 && (
              <div className="investigation-grid">
                <div className="investigation-summary-header">
                  <div className="grid-title">Investigation Summary</div>
                  <Button className="export-btn" onClick={exportData}>
                    Export
                  </Button>
                </div>
                <div className="separator"></div>
                <AppDataGrid
                  hasLockedRow={true}
                  ref={exportGridRef}
                  resetIndex={resetIndex}
                  setResetIndex={setResetIndex}
                  take={1000000000}
                  style={{ maxHeight: "67vh" }}
                  pageable={false}
                  filterable={false}
                  data={ootInvestigationData}
                  columnsToShow={countColsToShow(ootSummaryData, false)}
                  sortable={false}
                  exportFileName={`OOT Summary ${getFormattedDate(new Date())}`}
                  cellRender={(td, props) => {
                    return cellRender(td, props);
                  }}
                  noRecordsMessage="* Please select filters to fetch the data *"
                />
              </div>
            )}
          </div>
          {filterDataLoading && (
            <LoadingPanel message="Fetching the configurations.. please wait.." />
          )}
          {OttDataLoading && (
            <LoadingPanel message="Fetching the data.. please wait.." />
          )}
        </div>
      </div>
    </div>
  );
};

export { OutOfToleranceSummaryScreen };
