import {
  convertUTCDateToLocalDate,
  isDateOlderThanToday,
} from "../../../../../services/utils";
import calculateSize from "calculate-size";
import { Hint } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-all";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { CustomHeaderCell } from "../account-for-deliveries/account-for-deliveries-utils";
import { DECIMAL, isBFXCluster, isDachCluster, NUMBERS, ShipToLinkCell } from "../common-utils";

export const EODViewOptions = {
  TANK: 0,
  AUDIT: 1,
};

const itemEditable = (dataItem, field) => {
  if (field === "eodSales") {
    return isDateOlderThanToday(new Date(dataItem?.date), NUMBERS.NINETY);
  } else if (field === "eodStock" || field === "eodDeliveries") {
    return isDateOlderThanToday(new Date(dataItem?.date), NUMBERS.SEVEN);
  }
  return true;
};
const getLatestEditItem = (dataItem) => {
  let recentItem = null;
  if (
    dataItem?.EODSaleDetails?.EditedOn &&
    dataItem?.EODStockDetails?.EditedOn
  ) {
    //compare date..
    if (
      new Date(dataItem?.EODSaleDetails?.EditedOn) >
      new Date(dataItem?.EODStockDetails?.EditedOn)
    ) {
      recentItem = dataItem?.EODSaleDetails;
    } else {
      recentItem = dataItem?.EODStockDetails;
    }
  } else if (dataItem?.EODSaleDetails?.EditedOn) {
    recentItem = dataItem?.EODSaleDetails;
  } else if (dataItem?.EODStockDetails?.EditedOn) {
    recentItem = dataItem?.EODStockDetails;
  }

  return recentItem;
};

const TextInputCell = (props) => {
  const { dataItem, field, selectedItem, onChange, inputRef, remarksCell } =
    props;
  const editable = itemEditable(dataItem, field);

  if (editable) {
    const sameItem = selectedItem
      ? isSame(dataItem, selectedItem, field)
      : false;
    const value = dataItem?.[field];
    let valueEdited = false;
    if (field === "eodStock") {
      valueEdited = dataItem?.EODStockDetails?.EditedBy?.length > 0;
    } else if (field === "eodSales") {
      valueEdited = dataItem?.EODSaleDetails?.EditedBy?.length > 0;
    }
    const userIcon = require("../../../../../assets/user-green.png");
    let recentItem = null;
    if (field === "remarks") {
      valueEdited =
        dataItem?.EODSaleDetails?.EditedBy?.length > 0 ||
        dataItem?.EODStockDetails?.EditedBy?.length > 0;

      if (valueEdited) {
        recentItem = getLatestEditItem(dataItem);
      }
    }

    let oldValue = "";
    if (
      field === "eodStock" &&
      dataItem?.EODStockDetails?.EditedBy?.length > 0
    ) {
      oldValue = dataItem?.EODStockDetails?.OldValue;
    } else if (
      field === "eodSales" &&
      dataItem?.EODSaleDetails?.EditedBy?.length > 0
    ) {
      oldValue = dataItem?.EODSaleDetails?.OldValue;
    }

    const userIconClassName = "userIcon";
    const editedBy = recentItem?.EditedBy;
    const editedOn =
      recentItem?.EditedOn &&
      convertUTCDateToLocalDate(new Date(recentItem?.EditedOn));
    const title = `Edited by "${editedBy}" \n on ${editedOn}`;
    let errorClassName = "";
    if (
      field === "remarks" &&
      dataItem?.remarksError &&
      !(dataItem?.remarks?.length > 0)
    ) {
      errorClassName = "errorClassName";
    }

    const handleChange = (event) => {
      const position = event.target.element?.selectionStart;
      const inputValue = event.target.value;
      const newValue = remarksCell
        ? inputValue
        : inputValue.replace(/[^-?\d*]/g, "");
      const newDataItem = { ...dataItem, [field]: newValue };

      onChange({
        dataItem: newDataItem,
        field: field,
        position: position,
      });
    };

    return (
      <td className="editableCell">
        <div className="editContainer">
          <Input
            ref={sameItem ? inputRef : null}
            value={value}
            maxLength={100}
            className={errorClassName}
            onChange={handleChange}
          />
          {valueEdited && field !== "remarks" && (
            <Tooltip openDelay={100} position="left" anchorElement="target">
              <div
                className="editIndicator"
                title={`Previous Value: ${oldValue}`}
              ></div>
            </Tooltip>
          )}
          {(field === "eodStock" || field === "eodSales") && !valueEdited && (
            <div className="nonEditIndicator"></div>
          )}
          {valueEdited && field === "remarks" && (
            <Tooltip openDelay={100} position="left" anchorElement="target">
              <span className="userIconContainer">
                <img
                  className={userIconClassName}
                  src={userIcon}
                  alt="User"
                  title={title}
                />
              </span>
            </Tooltip>
          )}
          {!valueEdited && field === "remarks" && (
            <Tooltip openDelay={100} position="left" anchorElement="target">
              <span className="userIconContainer"></span>
            </Tooltip>
          )}
        </div>
        {remarksCell && (
          <Hint direction={"end"}>
            {value?.length || 0} / {100}
          </Hint>
        )}
      </td>
    );
  }

  return <td className="nonEditableCell">{dataItem?.[field]}</td>;
};

export const isSame = (dataItem, selectedItem, field) => {
  const valuesSame =
    dataItem.product === selectedItem.product &&
    dataItem.tank === selectedItem.tank &&
    field === selectedItem?.editField;
  return (
    dataItem.date === selectedItem.date &&
    dataItem.globalSiteId === selectedItem.globalSiteId &&
    valuesSame
  );
};

export const calculateWidth = (field, data) => {
  let maxWidth = 0;
  data?.forEach((item) => {
    const fontSize = `${window.innerWidth * DECIMAL.POINT_ZERO_ONE_ZERO_FIVE}px`;
    // eslint-disable-next-line
    const size = calculateSize(item[field], {
      font: "Helvetica Neue",
      fontSize: fontSize,
    }); // pass the font properties based on the application
    if (size.width > maxWidth) {
      maxWidth = size.width;
    }
  });
  const minWidth = window.innerWidth * DECIMAL.POINT_ZERO_FIVE;
  return maxWidth < minWidth ? minWidth : maxWidth;
};

export const colsToShow = (
  eodData,
  ref,
  onChange,
  selectedItem,
  selectedMode,
  handleShipToLinkAction,
) => {
  let columns = [];
  const bfxCluster = isBFXCluster();
  const dachCluster = isDachCluster();
  if (selectedMode === EODViewOptions.TANK) {
    columns = [
      ...columns,
      {
        title: "Date",
        field: "date",
        filterable: true,
        editable: false,
        headerCell: CustomHeaderCell,
        width: calculateWidth("date", eodData),
      },
      {
        title: "Ship To",
        field: "globalSiteId",
        filterable: true,
        editable: false,
        headerCell: CustomHeaderCell,
        cell: (props) => {
          return (
            <ShipToLinkCell {...props} linkAction={handleShipToLinkAction} />
          );
        },
        width: bfxCluster
          ? window.innerWidth * DECIMAL.POINT_ZERO_FIVE
          : window.innerWidth * DECIMAL.POINT_ZERO_EIGHT,
      },
      {
        title: "Product",
        field: "product",
        filterable: true,
        editable: false,
        headerCell: CustomHeaderCell,
        width: calculateWidth("product", eodData),
      },
      {
        title: "Tank",
        field: "tank",
        headerCell: CustomHeaderCell,
        filterable: true,
        editable: false,
      },
    ];

    if (bfxCluster) {
      columns = [
        ...columns,
        {
          title: "EOD Stock",
          field: "eodStock",
          headerCell: CustomHeaderCell,
          filterable: true,
          width: window.innerWidth * DECIMAL.POINT_ZERO_FIVE,
          cell: (props) => {
            return (
              <TextInputCell
                {...props}
                inputRef={ref}
                onChange={onChange}
                selectedItem={selectedItem}
              />
            );
          },
        },
        {
          title: "Stock at 11.45PM",
          field: "stockAt1145PM",
          filterable: true,
          editable: false,
          headerCell: CustomHeaderCell,
        },
      ];
    }

    if (dachCluster) {
      columns = [
        ...columns,
        {
          title: "Stock at 11.45PM",
          field: "stockAt1145PM",
          filterable: true,
          editable: false,
          headerCell: CustomHeaderCell,
          cell: (props) => {
            return (
              <TextInputCell
                {...props}
                inputRef={ref}
                onChange={onChange}
                selectedItem={selectedItem}
              />
            );
          },
        },
      ];
    }

    if (bfxCluster) {
      columns = [
        ...columns,
        {
          title: "Stock Diff",
          field: "stockDiff",
          filterable: true,
          editable: false,
          headerCell: CustomHeaderCell,
        },
        {
          title: "EOD Sales",
          field: "eodSales",
          filterable: true,
          editable: true,
          headerCell: CustomHeaderCell,
          width: window.innerWidth * DECIMAL.POINT_ZERO_FIVE,
          cell: (props) => {
            return (
              <TextInputCell
                {...props}
                inputRef={ref}
                onChange={onChange}
                selectedItem={selectedItem}
              />
            );
          },
        },
        {
          title: "Cumm Sales by 15Min",
          field: "cummSalesBy15min",
          filterable: true,
          editable: false,
          headerCell: CustomHeaderCell,
          width: window.innerWidth * DECIMAL.POINT_ZERO_FIVE,
        },
      ];
    }

    if (dachCluster) {
      columns = [
        ...columns,
        {
          title: "Cumm Sales by 15Min",
          field: "cummSalesBy15min",
          filterable: true,
          editable: false,
          headerCell: CustomHeaderCell,
          width: window.innerWidth * DECIMAL.POINT_ZERO_EIGHT,
          cell: (props) => {
            return (
              <TextInputCell
                {...props}
                inputRef={ref}
                onChange={onChange}
                selectedItem={selectedItem}
              />
            );
          },
        },
      ];
    }

    if (bfxCluster) {
      columns = [
        ...columns,
        {
          title: "Sales Diff",
          field: "salesDiff",
          filterable: true,
          editable: false,
          headerCell: CustomHeaderCell,
        },
        {
          title: "EOD Deliveries",
          field: "eodDeliveries",
          filterable: true,
          headerCell: CustomHeaderCell,
        },
      ];
    }

    columns = [
      ...columns,
      {
        title: "Deliveries",
        field: "cummDeliveriesBy15min",
        filterable: true,
        editable: false,
        headerCell: CustomHeaderCell,
        width: bfxCluster
          ? window.innerWidth * DECIMAL.POINT_ZERO_FIVE
          : window.innerWidth * DECIMAL.POINT_ZERO_NINE,
      },
    ];

    if (bfxCluster) {
      columns = [
        ...columns,
        {
          title: "Deliveries Diff",
          field: "deliveryDiff",
          filterable: true,
          editable: false,
          headerCell: CustomHeaderCell,
        },
      ];
    }

    columns = [
      ...columns,
      {
        title: "Variance",
        field: "eodVariance",
        filterable: true,
        editable: false,
        headerCell: CustomHeaderCell,
      },
    ];

    if (bfxCluster) {
      columns = [
        ...columns,
        {
          title: "Has Test Sales",
          field: "hasTestSales",
          filterable: true,
          editable: false,
          headerCell: CustomHeaderCell,
        },
        {
          title: "Test Sales Volume",
          field: "totalTestVolume",
          filterable: true,
          editable: false,
          headerCell: CustomHeaderCell,
        },
      ];
    }

    columns = [
      ...columns,
      {
        title: "Remarks",
        field: "remarks",
        filterable: true,
        editable: true,
        headerCell: CustomHeaderCell,
        width: bfxCluster
          ? window.innerWidth * DECIMAL.POINT_ONE_TWO
          : window.innerWidth * DECIMAL.POINT_ONE_FIVE,
        cell: (props) => {
          return (
            <TextInputCell
              {...props}
              inputRef={ref}
              onChange={onChange}
              selectedItem={selectedItem}
              remarksCell={true}
            />
          );
        },
      },
    ];
  } else {
    columns = [
      {
        title: "Country",
        field: "country",
        headerCell: CustomHeaderCell,
        filterable: true,
        editable: false,
        width: calculateWidth("country", eodData),
      },
      {
        title: "Ship To",
        field: "globalSiteId",
        filterable: true,
        editable: false,
        headerCell: CustomHeaderCell,
        cell: (props) => {
          return (
            <ShipToLinkCell {...props} linkAction={handleShipToLinkAction} />
          );
        },
        width: calculateWidth("globalSiteId", eodData),
      },
      {
        title: "Requested Date",
        field: "requestDate",
        filterable: true,
        editable: false,
        headerCell: CustomHeaderCell,
        width: window.innerWidth * DECIMAL.POINT_ZERO_SIX,
      },
      {
        title: "Tank",
        field: "tank",
        headerCell: CustomHeaderCell,
        filterable: true,
        editable: false,
        width: calculateWidth("tank", eodData),
      },
      {
        title: "Entity",
        field: "type",
        headerCell: CustomHeaderCell,
        filterable: true,
        editable: false,
        width: calculateWidth("type", eodData),
      },
      {
        title: "Current Value",
        field: "newValue",
        headerCell: CustomHeaderCell,
        filterable: true,
        editable: false,
        width: calculateWidth("newValue", eodData),
      },
      {
        title: "Previous Value",
        field: "oldValue",
        headerCell: CustomHeaderCell,
        filterable: true,
        editable: false,
        width: window.innerWidth * DECIMAL.POINT_ZERO_SIX,
      },
      {
        title: "Modified By",
        field: "modifiedUser",
        filterable: true,
        editable: false,
        headerCell: CustomHeaderCell,
        width: window.innerWidth * DECIMAL.POINT_ONE_FOUR,
      },
      {
        title: "Modified On",
        field: "modifiedDate",
        filterable: true,
        editable: false,
        headerCell: CustomHeaderCell,
        width: calculateWidth("modifiedDate", eodData),
      },
      {
        title: "Remarks",
        field: "remarks",
        filterable: true,
        editable: false,
        headerCell: CustomHeaderCell,
      },
    ];
  }
  return columns;
};
