
import { useState } from "react";
import { Button } from "@progress/kendo-react-all";
import "./pre-posting-container.scss";
import { useDispatch, useSelector } from "react-redux";
import { prePostingService } from '../../../../../services/pre-posting';
import { EditableDataGrid } from "../../../../components/editable-data-grid/editable-data-grid";
import { store } from '../../../../../state-management/store';
import { triggerNotification } from '../../../../../state-management/actions'
import { useEffect } from "react";
import { PrePosting } from "../common-utils";

const PrePostingContainer = () => {
    const [prePostingData, setPrePostingData] = useState([]);
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [loading, setLoading] = useState(false);
    const country = useSelector(state => state?.user?.Country);
    const dispatch = useDispatch();

    useEffect(() => {
        getPrepostingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    const fieldsToShow = [
        {
            id: 1,
            title: PrePosting.Pre_Posting_Activities,
            field: PrePosting.Pre_Posting_Activities,
            editable: false
        },
        {
            id: 2,
            title: PrePosting.Supporting_Evidences,
            field: PrePosting.Supporting_Evidences,
            editable: false,
            isLink: true
        },
        { id: 3, title: "Highlighs/Disclosures ( 500 Chars )", field: PrePosting.Highlighs_Disclosures, editable: true }
    ];

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    async function getPrepostingData() {
        const date = new Date();
        const currentMonth = months[date.getMonth() === 0 ? 0 : date.getMonth() - 1];
        const currentYear = date.getFullYear();
        setMonth(currentMonth);
        setYear(currentYear);
        const response = await prePostingService.getPrepostingData(
            country,
            (months.indexOf(currentMonth) + 1).toString().padStart(2, "0") || 0,
            currentYear
        );
        if (response && response?.length > 0) {

            const trimmedResponse = response?.slice(1);

            const sanitized = trimmedResponse?.map((e, index) => {
                return {
                    key: index,
                    [PrePosting.Pre_Posting_Activities]: e?.Col1,
                    [PrePosting.Supporting_Evidences]: e?.Col2,
                    [PrePosting.Highlighs_Disclosures]: e?.Col3
                };
            });
            setPrePostingData(sanitized);
        } else {
            setPrePostingData([]);
            store.dispatch(
                triggerNotification({
                    type: {
                        style: "error",
                        icon: false,
                    },
                    message: "Could'nt get records for the selected date"
                })
            )
        }
    }

    const handleOnSave = async (e) => {
        const content = [
            {
                Col1: PrePosting.Pre_Posting_Activities,
                Col2: PrePosting.Supporting_Evidences,
                Col3: PrePosting.Highlighs_Disclosures
            }
        ];
        for (let i = 0; i < e.length; i++) {
            const element = e[i];
            content.push({
                Col1: element[PrePosting.Pre_Posting_Activities],
                Col2: element[PrePosting.Supporting_Evidences],
                Col3: element[PrePosting.Highlighs_Disclosures]
            });
        }
        const payload = {
            countryCode: country,
            month: (months.indexOf(month) === 0 ? 1 : months.indexOf(month) + 1).toString().padStart(2, '0') || 0,
            year: year,
            content: content
        };
        const response = await prePostingService.savePrePostDate(payload);
        if (response && response?.Status === 'Success') {
            store.dispatch(
                triggerNotification({
                    type: {
                        style: "success",
                        icon: false,
                    },
                    message: 'Record Updated Successfully'
                })
            )
        } else {
            store.dispatch(
                triggerNotification({
                    type: {
                        style: "error",
                        icon: false,
                    },
                    message: 'Unable to update the records.'
                })
            )
        }
    };

    const sendEmail = async () => {
        setLoading(true);
        const response = await prePostingService.sendEmail();
        if (response?.Status === 'Success') {
            dispatch(
                triggerNotification({
                    type: {
                        style: "success",
                        icon: true,
                    },
                    message: 'Mail sent successfully!'
                })
            );
        }
        setLoading(false);
    }

    return (
        <>
            <div className="pre-posting-activities-container hidden-xs hidden-sm">
                <>
                    <div className="heading-container">
                        <h3>
                            {
                                month ? `Showing data for ${month} ${year}` : "Pre Posting Activities"
                            }
                        </h3>
                    </div>
                    {/* <div className="date-container">
                        <DatePicker
                            onChange={handleMonthChange}
                            format={"MM-yyyy"}
                            calendar={CustomCalander}
                            className="date-picker"
                            name="fromDate"
                        />
                        <Button onClick={getPrepostingData} className="yellow-btn">
                            Get Data
                        </Button>
                    </div> */}
                    <div className="data-grid">
                        <EditableDataGrid
                            onDataUpdate={handleOnSave}
                            fieldsToShow={fieldsToShow}
                            gridData={prePostingData}
                        />
                        <div className="email-btn-row">
                            <Button disabled={loading} className="button email-btn" onClick={sendEmail}>
                                Trigger Email (Monthly MEC Report)
                            </Button>
                        </div>
                    </div>
                </>
            </div>
            <div className="pre-posting-activities-container hidden-md hidden-lg hidden-xl">
                <div className="invalid-resolution-message-container">
                    <h4>Cannot display the page at this resolution</h4>
                    <p>Either zoom out to 100% or switch to a bigger screen</p>
                </div>
            </div>
        </>
    );
};

export { PrePostingContainer };