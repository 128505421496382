import { Loader } from "@progress/kendo-react-all";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { countryConfig } from "../../../../../services/country-config";
import { triggerNotification } from "../../../../../state-management/actions";
import { EditableDataGrid } from "../../../../components/editable-data-grid/editable-data-grid";
import { error, readOnlyUser } from "../../../../../services/utils";

const CountryConfigScreen = () => {
  const [countryConfigData, setCountryConfigData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    setReadOnly(readOnlyUser())
  }, []);

  const getCountryConfig = async () => {
    const configData = await countryConfig.getCountryConfig();
    const data = JSON.parse(configData[0]["LeakDetection"]);
    setCountryConfigData([
      {
        Records: "Weekly Reconciliation Variance",
        Variance: data.WeeklyReconciliationVariance,
        unit: "Liter(s)/Day",
        key: "WeeklyReconciliationVariance",
      },
      {
        Records: "Monthly Reconciliation Variance",
        Variance: data.MonthlyReconciliationVariance,
        unit: "Liter(s)/Day",
        key: "MonthlyReconciliationVariance",
      },
      {
        Records: "High Water Level Threshold",
        Variance: data.HighWaterLevelThreshold,
        unit: "mm",
        key: "HighWaterLevelThreshold",
      },
      {
        Records: "Theft Loss Threshold (One time)",
        Variance: data.TheftLossThreshold,
        unit: "Liter(s)",
        key: "TheftLossThreshold",
      },
      {
        Records: "Theft Loss Period",
        Variance: data.TheftLossPeriod,
        unit: "Minutes",
        key: "TheftLossPeriod",
      },
      {
        Records: "Night Loss Threshold",
        Variance: data.NightLossThreshold,
        unit: "Liter(s)",
        key: "NightLossThreshold",
      },
      {
        Records: "Night Loss Period",
        Variance: data.NightLossPeriod,
        unit: "Minutes",
        key: "NightLossPeriod",
      },
      {
        Records: "Night Loss Occurrence Count",
        Variance: data.NightLossOccurrenceCount,
        unit: "(consecutive)",
        key: "NightLossOccurrenceCount",
      },
      {
        Records: "Tank Leak Rate Threshold",
        Variance: data.TankLeakRateThreshold,
        unit: "Liter(s)/Hr",
        key: "TankLeakRateThreshold",
      },
      {
        Records: "Tank Leak Occurrence Count",
        Variance: data.TankLeakOccurrenceCount,
        unit: "(consecutive)",
        key: "TankLeakOccurrenceCount",
      },
    ]);
    setLoading(false);
  };
  useEffect(() => {
    getCountryConfig();
  }, []);

  const customCell = (e) => {
    return (
      <td>
        <span>{e.props.children}</span>
      </td>
    );
  };

  const fieldsToShow = [
    { field: "Records", title: "Records", filterable: false },
    {
      field: "Variance",
      title: "Variance",
      filterable: false,
      editable: true,
      editor: "numeric",
    },
    { field: "unit", title: "Unit", filterable: false },
  ];

  const handelUpdate = async (e) => {
    const fields = e.map((item) => ({
      [item.key]: item.Variance.toString(),
    }));
    const payload = {
      CountryCode: "DE",
      Fields: fields,
    };
    const res = await countryConfig.updateCountryConfig(payload);
    if (!res["Status"] || res["Status"] !== "Success") {
      dispatch(triggerNotification({
        type: {
          style: error,
          icon: true,
        }, message: 'Unable to update the records.'
      }));
      return false;
    } else {
      dispatch(triggerNotification({
        type: {
          style: "success",
          icon: true,
        }, message: 'ecords updated successfully!'
      }));
      return true;
    }
  };

  return (
    <div className="container data-grid">
      <div className="row ">
        {/* <h4 className="title">Country Configuration</h4> */}
        <div className="data-grid-container">
          {loading ? (
            <div className="loader-container">
              <Loader
                size="medium"
                themeColor="warning"
                type="converging-spinner"
              />
              <br />
              <h4>Fetching Details, Please Wait...</h4>
            </div>
          ) : (
            <EditableDataGrid
              style={{ width: "100%", margin: "1% 0" }}
              onDataUpdate={handelUpdate}
              fieldsToShow={fieldsToShow}
              gridData={countryConfigData}
              customCell={customCell}
              nonEditable={readOnly}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { CountryConfigScreen };
